import './Register.css';
import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import ReCAPTCHA from "react-google-recaptcha";


import Button from '@mui/material/Button';
import {NavLink, Navigate, useNavigate, Link } from 'react-router-dom';


//images
import tfLogoSvg from '../../assets/images/tf-logo.svg';
import bgMosaico from '../../assets/images/bg-mosaico.png';
import { Checkbox, FormControlLabel } from '@mui/material';
import Global from '../Global';


const Register =()=>{
    const [mail, setMail]= useState('');
    const [pass, setPass]= useState('');
    const [checkRef, setCheckRef] = React.useState(false);
    const url=Global.url;
    const history= useNavigate();
    const setSocket = (res) =>{
        this.socket.emit("set-unchecked-news",res.data.data);
        this.socket.on("get-unchecked-news", data => {
            this.setState({
                forms: data,
                status: 'success'
            })
        });
    }
    const saveForm =  async () =>{
        debugger
        var userData = {
            mail : mail,
            pass : pass
        }
        /*if(this.userMailRef.current.value===''){
            this.userMailRef.current.setCustomValidity("Complete este campo");
        }else if(this.userPassRef.current.value===''){
            this.userMailRef.current.setCustomValidity("Complete este campo");
        }*/
        await axios.post(url+'create-user', userData)
            .then( res =>{
                debugger;
                if(res.data.status!=='Ok'){
                    Swal.fire({
                        title: 'Sorry',
                        text:'This mail is already registered, check your mail',
                        confirmButtonText: 'Ok',
                    }).then(()=>{
                        history('/register')
                    })
                }else{

                    Swal.fire({
                        title: 'Welcome',
                        text:'We have send you a mail with the activation link, please check your mail.',
                        confirmButtonText: 'Ok',
                    }).then(()=>{
                        history('/login')
                    })
                }
            }, error =>{
                debugger;
                Swal.fire({
                    title: 'Sorry',
                    text:'Something went wrong, contact us and we will help you.',
                    confirmButtonText: 'Ok',
                }).then(()=>{
                   
                })
            })
        
    }

    const onChange=(value) =>{
       
    }
    return(
        <React.Fragment>
        <div className='register-container'>
            <div className='bg-container-mosaico'>
                <img src={bgMosaico}></img>
            </div>
            <div className="content-login">
                <div className="containerTop">
                    <div className="form-content-container row">
                        <div className='login-header-container'>

                            <div className='logo-container'>
                                <img className="trade-roofing-logo" src={tfLogoSvg}></img>
                            </div>
                            <div className='logo-text'>Service manager</div>
                        </div>
                        <form className="form">
                            <div className="form-group">
                                <TextField 
                                    required
                                    className='login-input'
                                    id="outlined-basic-email" 
                                    label="Email" 
                                    variant="outlined"
                                    fullWidth
                                    value={mail}
                                    onChange={(e)=>{e.preventDefault(); setMail(e.target.value)}}
                                />
                            </div>
                            <div className="form-group">
                                <TextField 
                                    required
                                    id="outlined-basic-pass"
                                    className='login-input'
                                    label="Password" 
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    value={pass}
                                    onChange={(e)=>{e.preventDefault(); setPass(e.target.value)}}
                                />
                            </div>
                            <div className="form-group">
                                <FormControlLabel control={<Checkbox value={checkRef} />} label="I have read and agree to " />
                                <a href="https://traderoofing.com/terms-conditions/" target="_blank">Terms and Conditions</a>
                            </div>
                            <div className="form-group">
                                <ReCAPTCHA
                                    sitekey="6LebDH0iAAAAALJeGD2aSx1YWFKL3Zh7Yo-gM8bG"
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group log-back">
                                <span>Already have an account?</span>
                                <NavLink to='/login'>Log in</NavLink>
                            </div>
                            
                            <div className="form-group button-bottom">
                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    onClick={(e)=>{e.preventDefault(); saveForm()}}
                                >
                                    Register
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    )

}
export default Register;