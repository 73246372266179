import './CalendarBook.css'
import { Autocomplete, Button, CircularProgress, FormHelperText, IconButton, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, {useEffect, useState} from "react";
import Navbar from "../navbar/Navbar";
import BackIcon from '../../assets/svg/backIcon'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";


import tfLogoSvg from '../../assets/images/tf-logo.svg';
import bgMosaico from '../../assets/images/bg-mosaico.png';
import ReCAPTCHA from 'react-google-recaptcha';
import NavbarLogged from '../navbarLogged/NavbarLogged';
import axios from 'axios';
import Global from '../Global';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const CalendarBook = ({userL, dateL, close, socketWs})=>{
    const [registerStep, setRegisterStep] = React.useState(0);
    const [user, setUser] = useState(null);
    const [projects, setProjects] = useState(null);
    const [date, setDate] = useState(dateL);
    const [pickedProject, setPickedProject] = useState(null);
    const [captcha, setCaptcha] = useState(false);
    const [registerNew, setRegisterNew] = useState(false);
    const [serviceNote, setServiceNote] = useState('');


    const [contact, setContact] = useState('');

    const [contactPhone, setContactPhone] = useState('');

    const [projectName, setProjectName] = useState('');
    const [projectError, setProjectError] = useState(false);

    const [projectAddress, setProjectAddress] = useState('');
    const [addressError, setAddressError] = useState(false);

    const [projectTypeError, setProjectTypeError] = useState(false);
    const [roofType, setRoofType] = React.useState('');
    const [roofTypes, setRoofTypes] = useState([]);

    const [projectAccessError, setProjectAccessError] = useState(false);
    const [roofAccess, setRoofAccess] = React.useState('');
    const [roofAccesss, setRoofAccesss] = useState([]);

    const [projectServiceError, setProjectServiceError] = useState(false);
    const [roofService, setRoofService] = React.useState('');
    const [roofServices, setRoofServices] = useState([]);

    const [note, setNote] = useState('');

    const history= useNavigate();
    var step= registerStep;
    const url= Global.url;
    useEffect(()=>{
        setRoofTypes(Global.roof_types);
        setRoofType(Global.roof_types[0]);
        setRoofAccesss(Global.roof_access);
        setRoofAccess(Global.roof_access[0]);
        setRoofServices(Global.services);
        setRoofService(Global.services[0]);
        getUser(userL._id);
        getProjects(userL._id);
    },[]);
   
    const getUser = async (id) => {
        if(id){
            await axios.get(url+'get-user/'+id)
            .then(res => {
                setUser(res.data.data);
            })
        }else{
            setUser(null);
        }
    }
    const getProjects= async(id)=>{
        await axios.get(url+'get-projects/'+id)
        .then(
            res=>{
                setProjects(res.data.data[0].projectstList);
            }
        )
        .catch(
            err=>{
            }
        )
    }
    const saveForm =  async () =>{
        if(date===null){
            return
        }
        const formData={};
        formData.userId=user._id;
        formData.projectId=pickedProject._id;
        formData.serviceType=roofService.label?roofService.label:roofService;
        formData.startingDate=date;
        formData.status='Pending';
        formData.note=serviceNote;

        await axios.post(url+'create-service', formData)
            .then( res =>{
                const response=res.data.data;
                if(res){
                    
                    close();
                    Swal.fire({
                        title: 'Stay updated!',
                        text:'We will contact you in less than 24 hours.',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        showCloseButton: true}
                    ).then(()=>{
                        socketWs.emit("new-service",(response));
                    })
                }else{
                    Swal.fire(
                        {
                            title: 'Ups',
                            text:'Something went wrong!',
                            confirmButtonText: 'Close',
                            allowOutsideClick: false,
                            showCloseButton: true
                        }
                    )
                   
                }
            }, error =>{
                Swal.fire(
                    {
                        title: 'Ups',
                        text:'Something went wrong!',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false,
                        showCloseButton: true
                    }
                )
            })
         
    }
    const onChange=(value) =>{
        setCaptcha(value!==''?true:false);
    }

    const pickProject = (i)=>{
       setPickedProject(projects[i]);
    }

    const saveProject = ()=>{
        if(projectName===''){
            setProjectError(true);
        }
        if(projectAddress===''){
            setAddressError(true);
        }
        if(roofType===''){
            setProjectTypeError(true);
        }
        if(roofAccess===''){
            setProjectAccessError(true);
        }
        if(projectError || projectTypeError || addressError || projectAccessError){
            return
        }else{
            //arrancar w8 screen
            var formData={};
            formData.name=projectName;
            formData.address=projectAddress;
            formData.access=roofAccess.label?roofAccess.label:roofAccess;
            formData.type=roofType.label?roofType.label:roofType;
            formData.contact=contact;
            formData.contact_number=contactPhone;
            formData.note=note;
            formData.user=user._id
            axios.post(url+'create-project',formData)
            .then(
                res=>{
                    debugger;
                    setRegisterStep(step+1);
                    setPickedProject(res.data.data)
                }
            )
            .catch(
                err=>{
                    console.log(err)
                }
            )
        }
        //create form data
        //run service
        //redirect
    }
    if(projects===null && user===null){
        return(
            <React.Fragment>
                <div className="free-booking-container logged">
                    <div className='bg-container-mosaico'>
                        <img src={bgMosaico}></img>
                    </div>
                        <CircularProgress/>
                </div>
               
            </React.Fragment>
        )
    }
    return(
        <React.Fragment>
            <div className="free-booking-container logged">
                <div className='bg-container-mosaico'>
                    <img src={bgMosaico}></img>
                </div>
                <div className="booking-component">
                <div className="modal-register logged">
                {
                    step===0 &&
                    <div className="step step-1">
                        <div className="stepBtn">
                            <div className="back-header">
                            </div>
                        </div>
                        <div className="step-header"> Service form</div>
                        <div className="step-form">
                            {
                                registerNew?(
                                <form className="form" onSubmit={saveForm}>
                                    <div className="form-content">
                                        <div className="form-group">
                                            <TextField
                                                className="login-input" 
                                                id="outlined-basic" 
                                                label="Property name" 
                                                variant="outlined"
                                                value={projectName}
                                                required
                                                error={projectError}
                                                helperText={projectError?('This field is required.'):('')}
                                                onChange={(e)=>{setProjectName(e.target.value);if(projectError)setProjectError(false)}}
                                            />
                                        </div>
                                    
                                        <div className="form-group">
                                            <TextField
                                                className="login-input" 
                                                id="outlined-basic" 
                                                label="Property address" 
                                                variant="outlined"
                                                required
                                                value={projectAddress}
                                                error={addressError}
                                                helperText={addressError?('This field is required.'):('')}
                                                onChange={(e)=>{setProjectAddress(e.target.value);if(addressError)setAddressError(false)}}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                className="login-input" 
                                                id="outlined-basic" 
                                                label="Onsite contact name" 
                                                variant="outlined"
                                                value={contact}
                                                onChange={(e)=>{setContact(e.target.value);}}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                className="login-input" 
                                                id="outlined-basic" 
                                                label="Onside contact phone" 
                                                variant="outlined"
                                                value={contactPhone}
                                                onChange={(e)=>{setContactPhone(e.target.value);}}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <Autocomplete
                                                className="autocomplete-full" 
                                                options={roofTypes}
                                                value={roofType}
                                            
                                                onChange={(event, newValue) => {
                                                    
                                                    setRoofType(newValue===null?'':newValue.label);
                                                    if(projectTypeError)setProjectTypeError(false);
                                                }}
                                                renderInput={(params)=> <TextField 
                                                    {...params} 
                                                    label="Roof type" 
                                                    className="login-input" 
                                                    id="outlined-basic" 
                                                    variant="outlined" 
                                                    required 
                                                    error={projectTypeError}
                                                    helperText={projectTypeError?('This field is required.'):('')}
                                                    >
                                                    </TextField>
                                                }
                                            />
                                            
                                        </div>
                                        <div className="form-group">
                                            <Autocomplete
                                                className="autocomplete-full login-input" 
                                                options={roofAccesss}
                                                value={roofAccess}
                                            
                                                onChange={(event, newValue) => {
                                                    setRoofAccess(newValue===null?'':newValue.label);
                                                    if(projectAccessError)setProjectAccessError(false);
                                                }}
                                                renderInput={(params)=> <TextField 
                                                    {...params} 
                                                    label="Roof access" 
                                                    className="login-input" 
                                                    id="outlined-basic" 
                                                    variant="outlined" 
                                                    required 
                                                    error={projectAccessError}
                                                    helperText={projectAccessError?('This field is required.'):('')}
                                                    >
                                                    </TextField>
                                                }
                                            />
                                            
                                            
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                className="login-input multiline" 
                                                multiline
                                                maxRows={2}
                                                id="outlined-basic" 
                                                label="Note" 
                                                variant="outlined"
                                                onChange={(e)=>{setNote(e.target.value)}}
                                                value={note}
                                            />
                                        </div>
                                    </div>

                            

                                </form>  
                                ):(
                                    <React.Fragment>
                                        <div className='table-r-label'>Select one of your properties:</div>
                                        {
                                            projects!==null?(
                                                <div className='projects-slider'>
                                                    {
                                                        projects.map((item,i)=>{
                                                            return(
                                                                <div className='project-card' key={'project-detail-'+i} onClick={(e)=>{e.preventDefault(); pickProject(i);  setRegisterStep(step+1)}}>
                                                                    <div className='project-label'>{item.name}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            ):(
                                                <div className='projects-slider'>
                                                    <div>There are not properties registered</div>
                                                </div>
                                            )
                                        }
                                    </React.Fragment>
                                )

                            }
                            <div className='create-propertie'>
                                <div className='table-r-label'>{!registerNew?'Or register a new one:':''}</div>
                                
                                <div className='btn-container-logged'>
                                    
                                    <Button 
                                        className="loginBtn" 
                                        variant="contained"
                                        onClick={()=>{

                                        setRegisterNew(!registerNew)
                                        }}
                                    >
                                        {!registerNew?'Register':'Back'}
                                    </Button>
                                    {
                                        registerNew&&
                                        <Button 
                                            className="loginBtn" 
                                            variant="contained"
                                            onClick={()=>{

                                              saveProject();
                                            }}
                                        >
                                            Next
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='step-navigator'>
                            <div className="step-marker">
                                <div className="step-mark active"></div>
                                <div className="step-mark"></div>
                            </div>
                        </div>
                    </div>
                }
                {
                    step===1 && 
                    <div className="step step-3">
                    <div className="stepBtn">
                        <div className="back-header">
                            <div 
                                className="back-btn" 
                                onClick={()=>{
                                    setRegisterStep(step-1);
                                    setCaptcha(false);
                                    setPickedProject(null);
                                    setDate(null)
                                }}>
                                <IconButton><BackIcon></BackIcon></IconButton>
                            </div>
                        </div>
                    </div>
                    <div className="step-header"> Service form</div>
                    <div className="step-form step-2">
                        <form className="form" onSubmit={saveForm}>
                           
                            <div className="form-content">

                                <div className="form-group">
                                    <TextField
                                        disabled
                                        id="outlined-basic"
                                        className='login-input'
                                        label="Property name" 
                                        variant="outlined"
                                        value={pickedProject.name}
                                        fullWidth
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        disabled
                                        id="outlined-basic"
                                        className='login-input'
                                        label="Roof type" 
                                        variant="outlined"
                                        value={pickedProject.type}
                                        fullWidth
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        disabled
                                        id="outlined-basic"
                                        className='login-input'
                                        label="Roof access" 
                                        variant="outlined"
                                        value={pickedProject.access}
                                        fullWidth
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        disabled
                                        id="outlined-basic"
                                        className='login-input'
                                        label="Property address" 
                                        variant="outlined"
                                        value={pickedProject.address}
                                        fullWidth
                                    />
                                </div>
                                <div className="form-group">
                                    <Autocomplete
                                        className="autocomplete-full login-input" 
                                        options={roofServices}
                                        value={roofService}
                                    
                                        onChange={(event, newValue) => {
                                            
                                            debugger;
                                            setRoofService(newValue===null?'':newValue.label);
                                            if(projectServiceError)setProjectServiceError(false);
                                        }}
                                        renderInput={(params)=> <TextField 
                                            {...params} 
                                            label="Service" 
                                            className="login-input" 
                                            id="outlined-basic" 
                                            variant="outlined" 
                                            required 
                                            error={projectServiceError}
                                            helperText={projectServiceError?('This field is required.'):('')}
                                            >
                                            </TextField>
                                        }
                                    />
                                    
                                    
                                </div>
                                <div className="form-group">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Service date"
                                            disabled
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField required className='login-input' fullWidth {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="form-group area">
                                    <FormHelperText className='label-form'>Note:</FormHelperText>
                                    <textarea className="text-area-tf" placeholder='Note' maxLength={250} value={serviceNote} onChange={(e)=>{e.preventDefault(); setServiceNote(e.target.value)}} />
                                </div>
                                <div className="form-group">
                                    <ReCAPTCHA
                                        sitekey="6LebDH0iAAAAALJeGD2aSx1YWFKL3Zh7Yo-gM8bG"
                                        onChange={onChange}
                                    />
                                </div>
                                
                            </div>
                            

                            <div className='step-navigator'>
                                <div className="step-marker">
                                    <div className="step-mark complete"></div>
                                    <div className="step-mark active"></div>
                                </div>
                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    disabled={!captcha}
                                    onClick={()=>{

                                        saveForm()
                                    }}
                                >
                                    Send
                                </Button>
                            </div>
                           
                        </form>
                    </div>  
                    </div>
                }
            </div>
                </div>

            </div>
        </React.Fragment>
    )
}
export default CalendarBook;