import './Recover.css';
import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import Button from '@mui/material/Button';


//images
import tfLogoSvg from '../../assets/images/tf-logo.svg';
import bgMosaico from '../../assets/images/bg-mosaico.png';
import Global from '../Global';
import { useNavigate, useParams } from 'react-router-dom';

const Recover =()=>{
    const userMailRef= React.createRef();
    const params= useParams();
    const history= useNavigate();
    const [captcha, setCaptcha] = useState(false);
    const url = Global.url; 
    const saveForm =  async () =>{
        var userData = {
            pass : userMailRef.current.value,
            id: params.id
        }
        if(userMailRef.current.value===''){
            userMailRef.current.setCustomValidity("Fill this field");
        }else{
            await axios.put(url+'update-pass',{id:userData.id, pass:userData.pass})
            .then( res =>{
                Swal.fire({
                    title: 'Welcome',
                    icon:'success',
                    text:'Your account is active, log in to use our services',
                    confirmButtonText: 'Ok',
                }).then(()=>{
                    history('/login');
                })
            }, error =>{
                    Swal.fire(
                    {
                        title: '<div class="big-square"></div>'+
                                '<div class="little-square"></div>'+
                                '<div class="bg-title-pop"><div class="list-label pop absolute-center"><p>Excuse us, something went wrong.</p></div></div>',
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                showCloseButton: true
                    }
                )
            })
            
        }
    }
    const onChange=(value) =>{
        setCaptcha(value!==''?true:false);
    }
    return(
        <React.Fragment>
        <div className='recovery-container'>
            <div className='bg-container-mosaico'>
                <img src={bgMosaico}></img>
            </div>
            <div className="content-login">
                <div className="containerTop">
                    <div className="form-content-container row">
                        <div className='login-header-container'>

                            <div className='logo-container'>
                                <img className="trade-roofing-logo" src={tfLogoSvg}></img>
                            </div>
                            <div className='logo-text'>Service manager</div>
                        </div>
                        <form className="form">
                            <div className="form-group">
                                <TextField 
                                    required
                                    className='login-input'
                                    id="outlined-basic-email" 
                                    label="New Password" 
                                    variant="outlined"
                                    fullWidth
                                    inputRef={userMailRef}
                                />
                            </div>
                            <div className="form-group">
                                <ReCAPTCHA
                                    sitekey="6LclJQkhAAAAAAXUx3mlCKGVrPjKYWFM6M4M-9KL"
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group column">
                                
                            </div>
                            <div className="form-group button-bottom">
                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    disabled={!captcha}
                                    onClick={()=>{
                                        saveForm()
                                    }}
                                >
                                    Send
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    )

}
export default Recover;