import './App.css';
import RouterNav from './Router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="App">
        <section className="components">
          <RouterNav/>
        </section>
      </div>
    </LocalizationProvider>
  );
}

export default App;
