import './Recovery.css';
import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import Button from '@mui/material/Button';


//images
import tfLogoSvg from '../../assets/images/tf-logo.svg';
import bgMosaico from '../../assets/images/bg-mosaico.png';
import Global from '../Global';

const Recovery =()=>{
    const userMailRef= React.createRef();
    const url = Global.url; 
    const [captcha, setCaptcha] = useState(false);
    const saveForm =  async () =>{
        var userData = {
            mail : userMailRef.current.value
        }
        if(userMailRef.current.value===''){
            userMailRef.current.setCustomValidity("Fill this field");
        }
        await axios.post(url+'recovery', userData)
            .then( res =>{
                if(res){
                    Swal.fire({
                        title: '<div class="big-square"></div>'+
                        '<div class="little-square"></div>'+
                        '<div class="bg-title-pop"><div class="list-label pop absolute-center"><p>We sent and email, please check your email and follow the steps to reset your password.</p></div></div>',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        showCloseButton: true}
                    ).then(()=>{
                        localStorage.setItem('userSesion', res.data.token);
                        this.setState({
                            redirect:true
                        });

                    })
                }else{
                    Swal.fire(
                        {
                            title: '<div class="big-square"></div>'+
                                    '<div class="little-square"></div>'+
                                    '<div class="bg-title-pop"><div class="list-label pop absolute-center"><p>This mail is not registered!</p></div></div>',
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    showCloseButton: true
                        }
                    )
                    this.setState({
                        status:'failed'
                    })
                }
            }, error =>{
                 Swal.fire(
                    {
                        title: '<div class="big-square"></div>'+
                                '<div class="little-square"></div>'+
                                '<div class="bg-title-pop"><div class="list-label pop absolute-center"><p>Excuse us, something went wrong.</p></div></div>',
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                showCloseButton: true
                    }
                )
            })
        
    }

    const onChange=(value) =>{
        setCaptcha(value!==''?true:false);
    }
    return(
        <React.Fragment>
        <div className='recovery-container'>
            <div className='bg-container-mosaico'>
                <img src={bgMosaico}></img>
            </div>
            <div className="content-login">
                <div className="containerTop">
                    <div className="form-content-container row">
                        <div className='login-header-container'>

                            <div className='logo-container'>
                                <img className="trade-roofing-logo" src={tfLogoSvg}></img>
                            </div>
                            <div className='logo-text'>Service manager</div>
                        </div>
                        <form className="form" onSubmit={saveForm}>
                            <div className="form-group">
                                <TextField 
                                    required
                                    className='login-input'
                                    id="outlined-basic-email" 
                                    label="Email" 
                                    variant="outlined"
                                    fullWidth
                                    inputRef={userMailRef}
                                />
                            </div>
                            <div className="form-group">
                                <ReCAPTCHA
                                    sitekey="6LclJQkhAAAAAAXUx3mlCKGVrPjKYWFM6M4M-9KL"
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group column">
                                
                            </div>
                            <div className="form-group button-bottom">
                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    disabled={!captcha}
                                    onClick={()=>{
                                        saveForm()
                                    }}
                                >
                                    Send
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    )

}
export default Recovery;