var Global = {
    /*url:"http://localhost:3902/tRoofingApp/",
    ws:"ws://localhost:3902",*/
    url:"https://servicemanager.traderoofing.com/tRoofingApp/",
    ws:"wss://servicemanager.traderoofing.com",
    roof_types:[{label:'Shingles'} , {label:'TPO'} , {label:'PVC'} , {label:'Asphalt granulated'} , {label:'EPDM'} , {label:'Metal'}],
    roof_access:[{label:'Ladder'} , {label:'Indoor access'} , {label:'Lock box'} , {label:'On site contact'}],
    request:[{label:'COI'} , {label:'W9'} ],
    services:[{label:'Maintenance'},{label:'Cleaning'},{label:'Repairs'},{label:'Leak investigation'},{label:'Inspection'}]
}

export default Global;