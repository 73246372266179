import './Landing.css';
import React, {useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import bgLanding from "../../assets/images/bg-landing.jpeg"
import { Button } from '@mui/material';



const Landing = ()=>{
    const navigate= useNavigate();
    return(
        <React.Fragment>
            <div className="container-navbar">
                <Navbar></Navbar>
            </div>
            <div className="container-landing">
                <div className="landing-bg">
                    <img className="bg-landing-img" src={bgLanding}></img>
                    <div className='bg-cover'></div>
                </div>
                <div className="landing-content">
                    <div className="normal-text">Free</div>
                    <div className="normal-text">inspections</div>
                </div>
                <div
                    className='login-cards-container'
                >
                    <Button 
                        className="loginCardBtn landing" 
                        variant="contained"
                        onClick={()=>{
                            navigate('free-booking')
                        }}
                    >
                        Schedule new service
                    </Button>
                    <Button 
                        className="loginCardBtn landing" 
                        variant="contained"
                        onClick={()=>{
                            navigate('login')
                        }}
                    >
                        Access service manager
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Landing;