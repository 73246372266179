import React, {Component, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';

import Login from './components/login/Login';
import Footer from './components/footer/Footer';
import FooterLoged from './components/footerLoged/FooterLoged';
import Manager from './components/manager/Manager';
import {io} from 'socket.io-client';


import Global from './components/Global';
import axios from 'axios';
import Navbar from './components/navbar/Navbar';
import NavbarLogged from './components/navbarLogged/NavbarLogged'
import Register from './components/register/Register';
import Recovery from './components/recovery/Recovery';
import Landing from './components/landing/Landing';
import NewPass from './components/newPass/NewPass';
import FreeBook from './components/freeBook/FreeBook';
import LoggedBook from './components/loggedBook/LoggedBook';
import Activate from './components/activate/Activate';
import Recover from './components/recover/Recover';
//importar componentes

const BgCircle =() =>{
    var width=window.innerWidth;
    return(
        <div style={{width:2154, height:2154}} className="bigCircle"></div>
    )
}

const RouterNav =() => {
    const url=Global.url;
    const ws=Global.ws;
    const [path, setPath] = useState('');
    const [user, setUser] = useState(null);

    useEffect(()=>{
        veryfyUserSesion();
    },[])
    const connectSocket = () =>{
        this.socket = (io(ws));
    }

    const veryfyUserSesion = async () =>{
        const sesionToken = localStorage.getItem('userSesion');
        if(sesionToken!==null){
            axios.get(url+'auth-user', {
                headers:{
                    'x-acces-token':sesionToken
                }
            })
            .then(
                res => {
                    if(res.data.status === 'Ok'){
                        getUser(res.data.data.id);
                        //his.connectSocket();
                    }
                },
                error =>{
                    getUser(null);
                }
            )
        }else{
            setUser(null);
        }
    }
    const getUser = async (id) => {
        if(id){
            axios.get(url+'get-user/'+id)
            .then(res => {
                setUser(res.data.data);
            })
        }else{
            setUser(null);
        }
    }
   

    return (
        <React.Fragment>
            <BrowserRouter className="app-container">
              
                <div className="container-navigation">
                    {
                        //<BgCircle/>   
                    }
                    <Routes >
                        <Route exact path=""  element={<Landing/>}>
                        </Route>
                        <Route exact path="login"  element={<Login/>}>
                        </Route>
                        <Route path="activate/:id"  element={<Activate/>}>
                        </Route>
                        <Route path="recover/:id"  element={<Recover/>}>
                        </Route>
                        <Route exact path="register"  element={<Register/>}>
                        </Route>
                        <Route exact path="recovery"  element={<Recovery/>}>
                        </Route>
                        <Route exact path="new-pass"  element={<NewPass/>}>
                        </Route>
                        <Route exact path="free-booking"  element={<FreeBook/>}>
                        </Route>
                        <Route exact path="logged-booking"  element={<LoggedBook  getUser={(data)=>{getUser(data)}}/>}>
                        </Route>
                        <Route exact path="manager"  element={<Manager  getUser={(data)=>{getUser(data)}}/>}></Route>
                    </Routes>
                </div>
                {
                    user===null || user.manager || user.superManager?(
                        <Footer></Footer>
                    ):(
                        <div className={user.name?'footer-loged':'footer-logged disabled'}>

                            <FooterLoged user={user}></FooterLoged>
                        </div>
                    )
                }
            </BrowserRouter>
        </React.Fragment>
        
    )
        
}

export default RouterNav;