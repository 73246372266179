import './Complete.css';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Global from '../Global';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


import tfLogoSvg from '../../assets/images/tf-logo.svg';
import { useNavigate } from 'react-router-dom';



const Complete=({user,update})=>{
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [user2, setUser2] = useState(user);

    const history= useNavigate();
    useEffect(()=>{
      var user=user2;
      debugger
    },[])
    const url= Global.url;
   
    const saveForm =  async () =>{
        var formData= {};
        formData.name=name;
        formData.phoneNumber=phoneNumber;
        formData.address=address;
        formData.city=city;
        formData.state=state;
        formData._id=user2._id;
        await axios.put(url+'create-user-second', formData)
            .then( res =>{
                debugger;
                if(res){
                    update();
                    history('/manager');

                }else{
                    Swal.fire(
                        {
                            title: 'Error',
                            text:'Wrong email or password.',
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false,
                            showCloseButton: true
                        }
                    )
                }
            }, error =>{
                 Swal.fire(
                    {
                        title: 'Error',
                        text:'Wrong email or password.',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        showCloseButton: true
                    }
                )
            })
        
    }
    return(
        <React.Fragment>
            <div className="content-login complete">
                <div className="containerTop">
                    <div className="form-content-container row">
                        <div className='complete-header-container'>
                            <div className='form-main-text'>Please complete the form below and we will be ready to start</div>
                        </div>
                        <form className="form complete" 
                            onSubmit={(e)=>{
                                e.preventDefault();
                                saveForm()
                            }
                                    
                                }>
                            <div className="form-group">
                                <TextField 
                                    required
                                    className='login-input'
                                    id="outlined-basic-email" 
                                    label="Company Name" 
                                    variant="outlined"
                                    fullWidth
                                    value={name}
                                    onChange={(e)=>{e.preventDefault(); setName(e.target.value)}}
                                />
                            </div>
                            <div className="form-group">
                                <TextField 
                                    required
                                    id="outlined-basic-pass"
                                    className='login-input'
                                    label="Company Phone" 
                                    variant="outlined"
                                    fullWidth
                                    value={phoneNumber}
                                    onChange={(e)=>{e.preventDefault(); setPhoneNumber(e.target.value)}}
                                />
                            </div>
                            <div className="form-group">
                                <TextField 
                                    required
                                    id="outlined-basic-pass"
                                    className='login-input'
                                    label="Company Address" 
                                    variant="outlined"
                                    fullWidth
                                    value={address}
                                    onChange={(e)=>{e.preventDefault(); setAddress(e.target.value)}}
                                />
                            </div>
                            <div className="form-group">
                                <TextField 
                                    required
                                    id="outlined-basic-pass"
                                    className='login-input'
                                    label="City" 
                                    variant="outlined"
                                    fullWidth
                                    value={city}
                                    onChange={(e)=>{e.preventDefault(); setCity(e.target.value)}}
                                />
                            </div>
                            <div className="form-group">
                                <TextField 
                                    required
                                    id="outlined-basic-pass"
                                    className='login-input'
                                    label="State" 
                                    variant="outlined"
                                    fullWidth
                                    value={state}
                                    onChange={(e)=>{e.preventDefault(); setState(e.target.value)}}
                                />
                            </div>
                            <div className="form-group button-complete">
                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                >
                                    <input type="submit" value="Send">
                                    </input>
                                </Button>
                            </div>
                            <div className='little-letters'>This information will be used for invoice and COI requests*</div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
    
}
export default Complete;