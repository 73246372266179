
import React, {useState}from 'react';

const BackIcon = ({active}) => {

    return(
        <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 3.14286L4.5 11L12 18.8571L10.5 22L0 11L10.5 0L12 3.14286Z" fill="#BAA261"/>
        </svg>
        
        
    )
};

export default BackIcon;