import './Navbar.css';
import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import tfLogoSvg from '../../assets/images/tf-logo.svg';

const  Navbar= () => {

  

    return(
        <React.Fragment>
            <div className="navbar-container-logout" 
                style={{opacity:1}} 
            >
                <div className="navbarContent">
                    <div className='back-trade'>
                        <a
                            href='https://traderoofing.com/'
                        >
                            {'< '}Go back to main page www.traderoofing.com
                        </a>
                    </div>
                    <div className='login-header-container'>

                        <div className='logo-container'>
                            <img className="trade-roofing-logo" src={tfLogoSvg}></img>
                        </div>
                        <div className='logo-text'>Service manager</div>
                    </div>
                    <div className='navbar-links-container'>
                       
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
    
}
export default Navbar;