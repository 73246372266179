import './NewPass.css';
import React, {Component} from 'react';
import axios from 'axios';
import Global from '../Global';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import ReCAPTCHA from "react-google-recaptcha";


import {io} from 'socket.io-client';
import Button from '@mui/material/Button';
import {NavLink, Navigate } from 'react-router-dom';


//images
import tfLogo from '../../assets/images/tf-logo.png';
import tfLogoSvg from '../../assets/images/tf-logo.svg';
import bgMosaico from '../../assets/images/bg-mosaico.png';


const BgCircle =() =>{
    var width=window.innerWidth;
    return(
        <div style={{width:2154, height:2154}} className="bigCircle">
           
        </div>
    )
}
const NewPass =()=>{
    const userPassRef= React.createRef();
    const userPassRef2= React.createRef();
   
    const setSocket = (res) =>{
        this.socket.emit("set-unchecked-news",res.data.data);
        this.socket.on("get-unchecked-news", data => {
            this.setState({
                forms: data,
                status: 'success'
            })
        });
    }
   const  isLogged = () =>{

    }
    const veryfyUserSesion = () =>{
        const sesionToken = localStorage.getItem('userSesion');
        if(sesionToken){
            axios.get(this.url+'auth-manager-user', {
                headers:{
                    'x-acces-token':sesionToken
                }
            })
            .then(res => {
                if(res.data.status === 'Ok'){
                    this.setState({
                        redirect:true
                    });
                }
            },
            error =>{
               console.log(error)
            })
        }
    }
    const saveForm =  async (e) =>{
        e.preventDefault();
        var userData = {
            mail : this.userMailRef.current.value,
            pass : this.userPassRef.current.value
        }
        if(this.userMailRef.current.value===''){
            this.userMailRef.current.setCustomValidity("Complete este campo");
        }else if(this.userPassRef.current.value===''){
            this.userMailRef.current.setCustomValidity("Complete este campo");
        }
        this.setState({
           user : userData
        })
        await axios.post(this.url+'login-admin', userData)
            .then( res =>{
                if(res){
                    
                    //Crear Sesion User
                    Swal.fire({
                        title: '<div class="big-square"></div>'+
                        '<div class="little-square"></div>'+
                        '<div class="bg-title-pop"><div class="list-label pop absolute-center"><p>Bienvenido</p></div></div>',
                        confirmButtonText: 'Seguir',
                        allowOutsideClick: false,
                        showCloseButton: true}
                    ).then(()=>{
                        localStorage.setItem('userSesion', res.data.token);
                        this.setState({
                            redirect:true
                        });

                    })
                }else{
                    Swal.fire(
                        {
                            title: '<div class="big-square"></div>'+
                                    '<div class="little-square"></div>'+
                                    '<div class="bg-title-pop"><div class="list-label pop absolute-center"><p>Usuario o contraseña incorrectos</p></div></div>',
                                    confirmButtonText: 'Seguir',
                                    allowOutsideClick: false,
                                    showCloseButton: true
                        }
                    )
                    this.setState({
                        status:'failed'
                    })
                }
            }, error =>{
                 Swal.fire(
                    {
                        title: '<div class="big-square"></div>'+
                                '<div class="little-square"></div>'+
                                '<div class="bg-title-pop"><div class="list-label pop absolute-center"><p>Usuario o contraseña incorrectos</p></div></div>',
                                confirmButtonText: 'Seguir',
                                allowOutsideClick: false,
                                showCloseButton: true
                    }
                )
            })
        
    }

    const onChange=(value) =>{
        console.log("Captcha value:", value);
    }
    return(
        <React.Fragment>
        <div className='new-pass-container'>
            <div className='bg-container-mosaico'>
                <img src={bgMosaico}></img>
            </div>
            <div className="content-login">
                <div className="containerTop">
                    <div className="form-content-container row">
                        <div className='login-header-container'>

                            <div className='logo-container'>
                                <img className="trade-roofing-logo" src={tfLogoSvg}></img>
                            </div>
                            <div className='logo-text'>Service manager</div>
                        </div>
                        <form className="form" 
                            onSubmit={()=>{saveForm()}
                                }>
                           <div className="form-group">
                                <TextField 
                                    required
                                    id="outlined-basic-pass"
                                    className='login-input'
                                    label="New Password" 
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    inputRef={userPassRef}
                                />
                            </div>
                            <div className="form-group">
                                <TextField 
                                    required
                                    id="outlined-basic-pass"
                                    className='login-input'
                                    label="Re enter password" 
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    inputRef={userPassRef2}
                                />
                            </div>
                            <div className="form-group">
                            <ReCAPTCHA
                                sitekey="6LclJQkhAAAAAAXUx3mlCKGVrPjKYWFM6M4M-9KL"
                                onChange={onChange}
                            />
                            </div>
                            <div className="form-group column">
                                
                            </div>
                            <div className="form-group button-bottom">
                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                >
                                    <input type="submit" value="Send">
                                        
                                    </input>
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    )

}
export default NewPass;