import './FooterLoged.css';
import React, {Component, useState} from 'react';
import {NavLink} from 'react-router-dom';
import { Autocomplete, Box, Button, Modal, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import tfLogoSvg from '../../assets/images/tf-logo.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedinIcon from '@mui/icons-material/LinkedIn';
import BackIcon from '../../assets/svg/backIcon';
import { useEffect } from 'react';
import Global from '../Global';
import Swal from 'sweetalert2';
import axios from 'axios';
import {io} from 'socket.io-client';

const socket=io(Global.ws);

const FooterLoged =({user}) =>{
    const[requestModal, setRequestModal] = useState(false);

    const [ownerName, setOwnerName] = useState('');
    const [ownerError, setOwnerError] = useState(false);

    const [projectAddress, setProjectAddress] = useState('');
    const [addressError, setAddressError] = useState(false);

    const [requestTypeError, setRequestTypeError] = useState(false);
    const [requestType, setRequestType] = React.useState('');
    const [requestTypes, setRequestTypes] = useState([]);

    const [note, setNote] = useState('');
    const url= Global.url;
    
    useEffect(()=>{
        setRequestType(Global.request[0]);
        setRequestTypes(Global.request);

    },[])

    const saveForm =  async () =>{
        if(addressError || ownerError || requestTypeError){
            return
        }
        debugger;
        const formData={};
        formData.userId=user._id;
        formData.requestType=requestType.label;
        formData.ownerName=ownerName;
        formData.address=projectAddress;
        formData.note=note;

        await axios.post(url+'create-request', formData)
            .then( res =>{
                const response=res.data.data;
                if(res){
                    
                    setRequestModal(false);
                    Swal.fire({
                        title: 'Stay updated!',
                        text:'Check your mail in less than 24 hours.',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        showCloseButton: true}
                    ).then(()=>{
                        socket.emit("new-request", response);
                    })
                }else{
                    setRequestModal(false);
                    Swal.fire(
                        {
                            title: 'Ups',
                            text:'Something went wrong!',
                            confirmButtonText: 'Close',
                            allowOutsideClick: false,
                            showCloseButton: true
                        }
                    )
                   
                }
            }, error =>{
                setRequestModal(false);
                Swal.fire(
                    {
                        title: 'Ups',
                        text:'Something went wrong!',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false,
                        showCloseButton: true
                    }
                )
            })
         
    }
    const requestW9 =  async () =>{
        const formData={};
        formData.userId=user._id;
        formData.requestType='W9';

        await axios.post(url+'create-request', formData)
            .then( res =>{
                const response=res.data.data;
                if(res){
                    Swal.fire({
                        title: 'Stay updated!',
                        text:'Check your mail in less than 24 hours.',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        showCloseButton: true}
                    ).then(()=>{
                        socket.emit("new-request", response);
                    })
                }else{
                    Swal.fire(
                        {
                            title: 'Ups',
                            text:'Something went wrong!',
                            confirmButtonText: 'Close',
                            allowOutsideClick: false,
                            showCloseButton: true
                        }
                    )
                   
                }
            }, error =>{
                Swal.fire(
                    {
                        title: 'Ups',
                        text:'Something went wrong!',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false,
                        showCloseButton: true
                    }
                )
            })
         
    }
    return(
        <React.Fragment>
            <div className="containerFooter">
                <div className="topFooter">
                    <div className="socialFooter">
                        <div className='social-logo-container'>
                            <div className="esaMulticolorLogo">
                                <img src={tfLogoSvg} alt="Logo trade roofing" />
                            </div>
                            <div className="socialIcons">
                                <IconButton
                                    href='https://www.facebook.com/traderoofing/'
                                ><FacebookIcon sx={{ color: '#aa9461' }} /></IconButton>
                                <IconButton
                                    href='https://www.linkedin.com/company/trade-roofing/'
                                ><LinkedinIcon  sx={{ color: '#aa9461' }} /></IconButton>
                            </div>
                        </div>
                        <div className="footer-label-bold">
                            © 2023 Trade Roofing. All rights reserved.
                        </div>
                    </div>
                    <div className="legalFooter">
                        <div className='footer-label-regular'>Do not hesitate to call us:</div>
                        <div className="footer-label-bold">
                            <a href='tel:2063398545'> {'(206) 339-8545'}</a>
                        </div>
                       
                    </div>
                    <div className="usFooter">
                        <div className='footer-label-regular'>Send us an email:</div>
                        <div className="footer-label-bold">
                            contact@traderoofing.com
                        </div>
                       
                    </div>
                    <div className="contactFooter">
                        <div className='footer-label-regular'>Our certificates:</div>
                        <div className='request-buttons'>
                            <Button  variant="text" className="fontBold buttonText" onClick={(e)=>{e.preventDefault(); requestW9('W9');}}>
                                Request W9
                            </Button>
                            <Button  variant="text" className="fontBold buttonText" onClick={(e)=>{e.preventDefault(); setRequestModal(true); setRequestType(Global.request[0])}}>
                               Request COI
                            </Button>
                        </div>
                    </div>
                    <Modal
                        open={requestModal}
                        onClose={(e)=>{e.preventDefault(); setRequestModal(false)}}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                        >
                        <Box sx={{width:400, height:450, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                            <div className="back-btn-modal">
                                <div className="back-btn-tf" onClick={(e)=>{
                                    e.preventDefault();
                                    setRequestModal(false);
                                }}>
                                    <BackIcon/>
                                </div>
                            </div>
                            <form className="form">
                                    <div className="form-content">
                                        <div className="form-group">
                                            <TextField
                                                className="login-input" 
                                                id="outlined-basic" 
                                                label="Owner/Company name" 
                                                variant="outlined"
                                                value={ownerName}
                                                required
                                                error={ownerError}
                                                helperText={ownerError?('This field is required.'):('')}
                                                onChange={(e)=>{setOwnerName(e.target.value);if(ownerError)setOwnerError(false)}}
                                            />
                                        </div>
                                    
                                        <div className="form-group">
                                            <TextField
                                                className="login-input" 
                                                id="outlined-basic" 
                                                label="Property address" 
                                                variant="outlined"
                                                required
                                                value={projectAddress}
                                                error={addressError}
                                                helperText={addressError?('This field is required.'):('')}
                                                onChange={(e)=>{setProjectAddress(e.target.value);if(addressError)setAddressError(false)}}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <Autocomplete
                                                className="autocomplete-full login-input" 
                                                options={requestTypes}
                                                value={requestType}
                                            
                                                onChange={(event, newValue) => {
                                                    setRequestType(newValue===null?'':newValue.label);
                                                    if(requestTypeError)setRequestTypeError(false);
                                                }}
                                                renderInput={(params)=> <TextField 
                                                    {...params} 
                                                    label="Roof access" 
                                                    className="login-input" 
                                                    id="outlined-basic" 
                                                    variant="outlined" 
                                                    required 
                                                    error={requestTypeError}
                                                    helperText={requestTypeError?('This field is required.'):('')}
                                                    >
                                                    </TextField>
                                                }
                                            />
                                            
                                            
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                className="login-input multiline" 
                                                multiline
                                                maxRows={2}
                                                id="outlined-basic" 
                                                label="Note" 
                                                variant="outlined"
                                                onChange={(e)=>{setNote(e.target.value)}}
                                                value={note}
                                            />
                                        </div>
                                    </div>

                            

                                </form>  



                            <Button
                                className="cancelBtn" 
                                variant="contained"
                                onClick={()=>{
                                   saveForm();
                                }}
                            >
                                Send request
                            </Button>
                        </Box>

                    </Modal>
                </div>
            </div>
        </React.Fragment>
    )
    
}
export default FooterLoged;