import './Footer.css';
import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import tfLogoSvg from '../../assets/images/tf-logo.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedinIcon from '@mui/icons-material/LinkedIn';


const Footer =() =>{

   
    return(
        <React.Fragment>
            <div className="containerFooter">
                <div className="topFooter">
                    <div className="socialFooter">
                        <div className='social-logo-container'>
                            <div className="esaMulticolorLogo">
                                <img src={tfLogoSvg} alt="Logo trade roofing" />
                            </div>
                            <div className="socialIcons">
                                <IconButton
                                    href='https://www.facebook.com/traderoofing/'
                                ><FacebookIcon sx={{ color: '#aa9461' }} /></IconButton>
                                <IconButton
                                    href='https://www.linkedin.com/company/trade-roofing/'
                                ><LinkedinIcon  sx={{ color: '#aa9461' }} /></IconButton>
                            </div>
                        </div>
                        <div className="footer-label-bold">
                            © 2023 Trade Roofing. All rights reserved.
                        </div>
                    </div>
                    <div className="legalFooter">
                        <div className='footer-label-regular'>Do not hesitate to call us:</div>
                        <div className="footer-label-bold">
                            <a href='tel:2063398545'> {'(206) 339-8545'}</a>
                        </div>
                       
                    </div>
                    <div className="usFooter">
                        <div className='footer-label-regular'>Send us an email:</div>
                        <div className="footer-label-bold">
                            contact@traderoofing.com
                        </div>
                       
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
    
}
export default Footer;