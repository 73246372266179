import { FormControl, FormHelperText, IconButton, MenuItem, Select, TextField, Box, Button, Slider, ThemeProvider, createTheme, Autocomplete, CircularProgress, Checkbox, Toolbar, Modal, Typography, FormLabel, RadioGroup, FormControlLabel, Radio, Icon } from "@mui/material";
import { format, formatDistance } from 'date-fns';
import React, {useState, useEffect} from "react";
import './MenuSwitcher.css';
import Search from '@mui/icons-material/Search';
import Global from '../Global';
import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {io} from 'socket.io-client';
import Swal from "sweetalert2";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import BackIcon from '../../assets/svg/backIcon';
import GoIcon from '../../assets/svg/goIcon';
import dollarSvg from '../../assets/svg/dollar.svg';
import phoneSvg from '../../assets/svg/phone-icon.svg';
import calculatorSvg from '../../assets/svg/calculator.svg';
import engineSvg from '../../assets/svg/engine.svg';
import YouTube from 'react-youtube';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Carousel from 'react-material-ui-carousel';
import { useNavigate } from "react-router-dom";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import CalendarBook from "../calendarBook/CalendarBook";
import Complete from "../complete/Complete";
import { saveAs } from 'file-saver';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const localizer = momentLocalizer(moment);
const theme= createTheme({
    components:{
        MuiSlider:{
            width:500
        }
    }
})
const opts = {
    height: '460',
    width: '840',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
const sliderMarks = [
    {
        value: 0,
        label: 'Pending',
    },
    {
        value: 25,
        label: 'Scheduled',
    },
    {
        value: 50,
        label: 'Proposal',
    },
    {
        value: 75,
        label: 'Working on it',
    },
    {
        value: 100,
        label: 'Finished',
    }
];
const socket=io(Global.ws);

const MenuSwitcher = ({option, user, switchOption, menuController,verify}) =>{
    const [pendingForms, setPendingForms] = useState(null);

    const [processForms, setProcessForms]= useState(null);
    const [finishedForms, setFinishedForms] = useState(null);

    const [processFilteredForms, setProcessFilteredForms]= useState(null);
    const [finishedFilteredForms, setFinishedFilteredForms] = useState(null);

    const [canceledForms, setCanceledForms] = useState(null);

    const [loading, setLoading] = useState(null);
    const [sortServices, setSortServices]=useState(0);
    const [sortFinished, setSortFinished]=useState(0);
    const [sortServicesManager, setSortServicesManager]=useState(0);
    const [sortHistoryManager, setSortHistoryManager]=useState(0);

    const [createEditProject, setCreateEditProject]= useState(false);
    const [detailView, setDetailView] = useState(false);
    const [detailViewCalendar, setDetailViewCalendar] = useState(false);

    const [detailsData, setDetailsData] = useState(null);

    const [finishedDetailView, setFinishedDetailView] = useState(false);
    const [editDetailService, setEditDetailService]= useState(false);

    const [selectedProject, setSelectedProject] = useState(null);

    const [lastActiveService, setLastActiveService] = useState(null);
    const [lastFinishedService, setLastFinishedService] = useState(null);

    const [calendarEvents, setCalendarEvents] = useState(null);

    const [openModal, setOpenModal] = useState(false);
    const [calendarPickDate, setCalendarPickDate] = useState(null);

    const [requestUserList, setRequestUserList]= useState(null);
    const [requestUserFilteredList, setRequestUserFilteredList]= useState(null);
    const [sortUSerRequest, setSortUserRequest]=useState(0);


    //MAnager Variables
    const [servicesList, setServicesList]= useState(null);
    const [detailServiceManagerView, setDetailServiceManagerView] = useState(false);
    const [servicesFilteredList, setServicesFilteredList] = useState(null);
    const [serviceDetailManager, setServiceDetailManager] = useState(null);

    const [usersList, setUsersList] = useState(null);
    const [usersFilteredList, setUsersFilteredList] = useState(null);

    const [managersList, setManagersList] = useState(null);
    const [managersFilteredList, setManagersFilteredList] = useState(null);
    


    const [detailUsersView, setDetailsUsersView] = useState(false);
    const [detailUserData, setDetailUserData] = useState(false);

    const [detailManagersView, setDetailsManagersView] = useState(false);
    const [detailManagerData, setDetailsManagerData] = useState(false);

    const [selectedUserViewProject, setSelectedUserViewProject] = useState(false);
    const [editDetailServiceUserView, setEditDetailServiceUserView] = useState(false);
    const [selectedUserProject, setSelectedUserProject] = useState(null);
    const [serviceDetailUserProject, setServiceDetailUserProject] = useState(null);

    const [requestList, setRequestList]= useState(null);
    const [requestFilteredList, setRequestFilteredList]= useState(null);
    const [sortRequest, setSortRequest]=useState(0);

    const [finishedList, setFinishedList] = useState(null);
    const [detailFinishedManagerView, setDetailFinishedManagerView] = useState(false);
    const [finishedFilteredList, setFinishedFilteredList] = useState(null);
    const [finishedDetailManager, setFinishedDetailManager] = useState(null);


    const [zoomModal, setZoomModal] = useState(false);
    const [createAdmin, setCreateAdmin] = useState(false);
    const [assignModal, setAssignModal] = useState(false);
    const [coiMOdal, setCoiModal] = useState(false);
    const [coiData, setCoiData] = useState(null);
    const [zoomImage, setZoomImage] = useState(null);

    const[dashBoard, setDashBoard] = useState(null);

//Create Manager
    const [managerName, setManagerName] = useState('');
    const [managerError, setManagerError] = useState(false);

    const [managerMail, setManagerMail] = useState('');
    const [managerMailError, setManagerMailError] = useState(false);

    const [managerPass, setManagerPass] = useState('');
    const [managerPassError, setManagerPassError] = useState(false);

    const [managerType, setManagerType] = useState("0");

    let url= Global.url;
    let url_images= Global.url_images;
    let ws= Global.ws;
    const[isConnected, setIsConnected] = useState(false);
    /*const socket=(io(ws));
    socket.on("update-news", ()=>{
        getPendingForms();
        getProcessForms();
        getFinishedForms();
        getCanceledForms();
    })*/



    //Create propertyManager


    const [newPropertyModal, setNewPropertyModal]= useState(false);
    const [newServiceModal, setNewServiceModal]= useState(false);

    const [contactM, setContactM] = useState('');
    const [contactPhoneM, setContactPhoneM] = useState('');

    const [projectNameM, setProjectNameM] = useState('');
    const [projectErrorM, setProjectErrorM] = useState(false);

    const [projectAddressM, setProjectAddressM] = useState('');
    const [addressErrorM, setAddressErrorM] = useState(false);

    const [projectTypeErrorM, setProjectTypeErrorM] = useState(false);
    const [roofTypeM, setRoofTypeM] = React.useState('');
    const [roofTypesM, setRoofTypesM] = useState([]);

    const [projectAccessErrorM, setProjectAccessErrorM] = useState(false);
    const [roofAccessM, setRoofAccessM] = React.useState('');
    const [roofAccesssM, setRoofAccesssM] = useState([]);

    const [projectServiceErrorM, setProjectServiceErrorM] = useState(false);
    const [roofServiceM, setRoofServiceM] = React.useState('');
    const [roofServicesM, setRoofServicesM] = useState([]);

    const [noteM, setNoteM] = useState('');
    const [serviceNoteM, setServiceNoteM] = useState('');
    const [sDateM, setSDateM] = useState(null);


    /** SLiders*/

    const [newSlider, setNewSlider]= useState(false);
    const [sliderBg, setSliderBg]= useState('');
    const [sliderTitle, setSliderTitle]= useState('');
    const [sliderP, setSliderP]= useState('');
    const [sliderUrl, setSliderUrl]= useState('');

    const [slidersList, setSlidersList]= useState([]);


    useEffect(() => {
        if(user.manager){
            getManagerDataById();
            socket.on("update-services",()=>{
                getManagerDataById();
            });
            socket.on("update-users",()=>{
                debugger
                getManagerDataById();
            });
            socket.on("update-requests",()=>{
                getManagerDataById();
            });
            socket.on("slider-requests",()=>{
                getManagerDataById();
            });
        }else if(!user.manager&&!user.superManager){
            getProcessForms();
            getUserRequestList();
            socket.on("update-services-"+user._id,()=>{
                debugger;
                getProcessForms();
            });
            socket.on("update-requests-"+user._id,()=>{
                getUserRequestList();
            });
            socket.on("slider-requests",()=>{
                //updateSliders
            });
        }else if(user.superManager){
            getManagerData();
            socket.on("update-services",()=>{
                getManagerData();
            });
            socket.on("update-users",()=>{
                debugger
                getManagerData();
            });
            socket.on("update-requests",()=>{
                getManagerData();
            });
            socket.on("slider-requests",()=>{
                getManagerData();
            });
        }
        return () => {
            socket.off('update-services');
            socket.off('update-users');
            socket.off("slider-requests");
            if(!user.manager&&!user.superManager){
                socket.off("update-services-"+user._id);
                socket.off("update-requests-"+user._id);
                socket.on("slider-requests");
            }
        };
    },[]);

    const updateRequestW9=async (dataI)=>{
        debugger;
        const { value: file } = await Swal.fire({
            title: 'Upload a W9 file',
            text:'To finish the request please upload de W9 file',
            input: 'file',
            className:'file-pop',
            showCloseButton: true,
            inputAttributes: {
              'accept': 'application/pdf',
              'aria-label': 'Upload file'
            }
        })
        if(file===undefined || file===null){
            Swal.fire({
                title: 'Procces canceled',
                text:'You must upload a W9 file to close the request',
                confirmButtonText: 'Ok',
            }).then(()=>{
            })
        }else{
            const data= new FormData();
            data.append('file',file);
            data.append('id',dataI._id);
            axios.put(url+'update-request',data)
            .then(
                res =>{
                    if(user.manager){

                        getManagerDataById();
                    }else{

                        getManagerData()
                    }
                    socket.emit('update-request-manager',{userId:dataI.userData[0]._id})
                }
            )
        }
    }
    const updateRequestCOI=async (dataI)=>{
        setCoiModal(false);
        const { value: file } = await Swal.fire({
            title: 'Upload a COI file',
            text:'To finish the request please upload a COI file',
            input: 'file',
            className:'file-pop',
            showCloseButton: true,
            inputAttributes: {
              'accept': 'application/pdf',
              'aria-label': 'Upload file'
            }
        })
        if(file===undefined || file===null){
            Swal.fire({
                title: 'Procces canceled',
                text:'You must upload a COI file to finish the request',
                confirmButtonText: 'Ok',
            }).then(()=>{
            })
        }else{
            const data= new FormData();
            data.append('file',file);
            data.append('id',dataI._id);
            axios.put(url+'update-request',data)
            .then(
                res =>{
                    if(user.manager){

                        getManagerDataById();
                    }else{

                        getManagerData()
                    }
                    socket.emit('update-request-manager',{userId:dataI.userData[0]._id})
                }
            )
        }
    }
    const downloadInvoice=async (urlInv,urlImg,id)=>{
        Swal.fire({
            title: 'Resume options',
            text:'Please select one of the options',
            confirmButtonText: 'Picture report',
            showDenyButton: true,
            denyButtonText:'Invoice'
        }).then(async (result)=>{
            if(result.isConfirmed){
                await axios.get(url+'download-image/'+urlImg,{responseType:'blob'})
                .then(
                    res=>{
                        let file=URL.createObjectURL(res.data);
                        let a=document.createElement('a');
        
                        a.download='ImageRepor-TradeRoofingApp-'+id;
                        a.href=file;
                        a.click();
                      
                    }
                )
            }else if(result.isDenied){
                await axios.get(url+'download-invoice/'+urlInv,{responseType:'blob'})
                .then(
                    res=>{
                        let file=URL.createObjectURL(res.data);
                        let a=document.createElement('a');
        
                        a.download='Invoice-TradeRoofingApp-'+id;
                        a.href=file;
                        a.click();
                      
                    }
                )
            }
        })
       
     
    }
    const downloadRequest=async (urlReqeust,id)=>{
        await axios.get(url+'download-request/'+urlReqeust,{responseType:'blob'})
        .then(
            res=>{
                let file=URL.createObjectURL(res.data);
                let a=document.createElement('a');

                a.download='ImageRepor-TradeRoofingApp-'+id;
                a.href=file;
                a.click();
              
            }
        )
    }
    const downloadProposal=async (urlProposal,number)=>{
        await axios.get(url+'download-proposal/'+urlProposal,{responseType:'blob'})
        .then(
            res=>{
                let file=URL.createObjectURL(res.data);
                let a=document.createElement('a');

                a.download=urlProposal;
                a.href=file;
                a.click();
              
            }
        )
    }
    const openImageZoom=(url)=>{
        setZoomModal(true);
        setZoomImage(url);
    }
    const updateUser=()=>{
        verify();
    }

    const createSlider=async()=>{
        const formData={};
        formData.url=sliderUrl;
        formData.paragraph=sliderP;
        formData.title=sliderTitle;
        formData.bg=sliderBg;
        await axios.post(url+'create-slider',formData)
        .then(
            res =>{
                if(user.manager){

                    getManagerDataById();
                }else{

                    getManagerData()
                }
                socket.emit('update-slider-manager')
              debugger
            }
        )
        .catch(
            err=>{

            }
        )
    }

    const deleteSlider=async(id)=>{
        await axios.delete(url+'delete-slider/'+id)
        .then(
            res =>{
                if(user.manager){
                    getManagerDataById();
                }else{

                    getManagerData()
                }
                socket.emit('update-slider-manager');
            }
        )
        .catch(
            err=>{

            }
        )
    }

    const getProcessForms = async ()=>{
        //setProcessForms(activeProccess);
        await axios.get(url+'get-active-services-user-id/'+user._id)
        .then(
            async res => {
                debugger;
                if(res.data.data.length===0){
                    debugger;
                    var calendar=[];
                    setProcessForms(null);
                    setLastActiveService(null);
                    await getFinishedForms(calendar);
                }else{
                    debugger;
                    setProcessForms(res.data.data);
                    setLastActiveService(res.data.data[(res.data.data.length-1)]);
                    var calendar=[];
                    var itemData;
                    await res.data.data.map((item,i)=>{
                        itemData={title:item.serviceType+'-'+item.projectData[0].name, start:new Date(item.startingDate), end:new Date(item.startingDate),  status:item.status, data:item};
                        calendar.push(itemData)
                    });
    
                    
    
                    await getFinishedForms(calendar);
                }
            },
            error =>{
            }
        )
        await axios.get(url+'get-sliders')
        .then(
            res=>{
                setSlidersList(res.data.data)
            }
        ).catch(
            err=>{
            }
        )   
    }
    const getFinishedForms = async (calendars)=>{
        await axios.get(url+'get-finished-services-user-id/'+user._id)
        .then(
            async res => {
                if(res.data.data.length===0){
                    var calendar=calendars;
                    setCalendarEvents(calendar);
                }else{

                    setFinishedForms(res.data.data);
                    setLastFinishedService(res.data.data[(res.data.data.length-1)]);
                    var calendar=calendars;
                    var itemData;
                    await res.data.data.map((item,i)=>{
                        itemData={title:item.serviceType+'-'+item.projectData[0].name, start:new Date(item.startingDate), end:new Date(item.startingDate), status:item.status, data:item};
                        calendar.push(itemData)
                    });
                    setCalendarEvents(calendar);
                }
            },
            error =>{
            }
        )
    }
    const getUserRequestList= async ()=>{
        await axios.get(url+'get-user-request/'+user._id)
        .then(
            async res => {
                if(res.data.data.length>0){

                    setRequestUserList(res.data.data);
                }
            },
            error =>{
            }
        )
    }
    
    const InProcessList = ({data}) =>{
        return(
            <React.Fragment>
               <div className="table-header">
                    <div className="table-h-label">Property name</div>
                    <div className="table-h-label">Address</div>
                    <div className="table-h-label">Type of service</div>
                    <div className="table-h-label">Status</div>
                    <div className="table-h-label">Date</div>
                    <div className="table-h-label"></div>
                </div>
                <div className="table-content">
                    {
                        data.map((item,index)=>{
                            return(
                                <div className="table-row" key={index}>
                                    <div className="table-r-label">{item.projectData[0].name}</div>
                                    <div className="table-r-label">{item.projectData[0].address}</div>
                                    <div className="table-r-label">{item.serviceType}</div>
                                    <div className="table-r-label">{item.status}</div>
                                    <div className="table-r-label">{format(new Date(item.creationDate), 'dd/MM/yyyy')}</div>
                                    <div className="table-r-label-f">
                                        <div className="link-green" onClick={()=>{setDetailView(true); setDetailsData(item)}}>
                                           view details
                                        </div>
                                       <GoIcon/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </React.Fragment>
        )
    }
    const FinishedList = ({data}) =>{
        return(
            <React.Fragment>
               <div className="table-header">
                    <div className="table-h-label">Property name</div>
                    <div className="table-h-label">Address</div>
                    <div className="table-h-label">Type of service</div>
                    <div className="table-h-label">Status</div>
                    <div className="table-h-label">Finished Date</div>
                    <div className="table-h-label"></div>
                </div>
                <div className="table-content">
                    {
                        data.map((item,index)=>{
                            return(
                                <div className="table-row" key={index}>
                                    <div className="table-r-label">{item.projectData[0].name}</div>
                                    <div className="table-r-label">{item.projectData[0].address}</div>
                                    <div className="table-r-label">{item.serviceType}</div>
                                    <div className="table-r-label">{item.status}</div>
                                    <div className="table-r-label">{format(new Date(item.finishedDate), 'dd/MM/yyyy')}</div>
                                    <div className="table-r-label-f">
                                        {
                                            item.status==='Finished'&&
                                            <React.Fragment>

                                                <div className="link-green" onClick={async ()=>{
                                                    downloadInvoice(item.invoiceUrl, item.imageReportUrl, item._id);
                                                }}
                                                >
                                                    get resume
                                                </div>
                                                <GoIcon/>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </React.Fragment>
        )
    }
    const FormDetailView = ()=>{
        const [chat, setChat] = useState([]);
        const [date, setDate] = useState(new Date());
        const [projectImages, setProjectImages] = useState(null);
        const [newMessage, setNewMessage] = useState('');
        const [status, setStatus] = useState(0);
        const [downloadGallery, setDownloadGallery] = useState(null);
        const history = useNavigate();
        useEffect(()=>{
            setDate(detailsData.startingDate);
            getChat(detailsData._id);
            if(selectedProject===null){
               setSelectedProject(detailsData.projectData[0]);
            }
            switch (detailsData.status) {
                 case 'Pending':
                    setStatus(0);
                break;
                case 'Canceled':
                    setStatus(0);
                break;
                case 'Scheduled':
                    setStatus(25);
                break;
                case 'Proposal':
                    setStatus(50);
                break;
                case 'Working on it':
                    setStatus(75);
                break;
                case 'Finished':
                    setStatus(100);
                break;
                default:
                    break;
            }
            if(detailsData.companyCams){
                getImages(detailsData.companyCams);
            }
           
        },[]);
        useEffect(()=>{
            socket.on("update-messages-"+detailsData._id,(lastMessage)=>{
                debugger
               if(lastMessage.userId===user._id){

                    setChat([...chat]);
                }else{

                    setChat([...chat, lastMessage]);
                }
            });
            return()=>{
                socket.off("update-messages-"+detailsData._id)
            }
        })
        const getImages=(id)=>{
            axios.get(url+'get-project-images/'+detailsData._id+'/'+id)
            .then(
                res=>{
                    var galleryDownload=[];
                    var itemsPushed=[];
                    var finalItems= [];
                    res.data.data.map((item,i)=>{
                        
                        galleryDownload.push(item.uris[3].url)
                        var ii=i+1;
                        if(ii % 4 === 0){
                            itemsPushed.push(item);
                            finalItems.push(itemsPushed);
                            itemsPushed= [];
                        }else{
                            itemsPushed.push(item);
                            if(res.data.data.length===ii){
                                finalItems.push(itemsPushed);
                            }
                        }
                        
                    });
                    setProjectImages(finalItems);
                    setDownloadGallery(galleryDownload);
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        const updateService=async()=>{
            const formData={};
            const newChat={date:new Date(),message:newMessage,user:user.name, userId:user._id};
            var chatArray=chat;
            chatArray.push(newChat);
            formData.chat=chatArray;
            formData.id=detailsData._id;
            await axios.put(url+'update-service',formData)
            .then(
                res =>{
                    socket.emit('new-message',{id:res.data.data[0]._id,chat:newChat});
                    setNewMessage('');
                }
            )
            .catch(
                err=>{

                }
            )
        }
        const updateServiceCancel=async()=>{
            const formData={};
            formData.deleted=true;
            formData.id=detailsData._id;
            Swal.fire({
                title: 'Cancel service',
                text:'We will contact you after you cancel the service, Are you sure to continue?',
                confirmButtonText: 'Yes',
                showDenyButton: true,
                denyButtonText:'No'
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire({
                        title: 'Cancel service',
                        text:'Your service has been cancelled. We will contact you in the next 24 hours.',
                        confirmButtonText: 'Ok',
                    }).then(async ()=>{
                        setDetailView(false);
                        setSelectedProject(null);
                        setDetailsData(null);
                        await axios.put(url+'update-service-cancel',formData)
                        .then(
                            res =>{
                                if(user.manager){

                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                                socket.emit("cancel-service",(res.data.data));
                                getProcessForms();
                            }
                        )
                        .catch(
                            err=>{

                            }
                        )
                    })
                }else if(result.isDenied){

                }
            })
        }
        const getChat=(id)=>{
            debugger;
            axios.get(url+'get-service-chat/'+id)
            .then(
                res=>{
                        setChat(res.data.data)
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        if(projectImages===null && detailsData.companyCams!==undefined){
            return(
                <CircularProgress></CircularProgress>
            )
        }else{
            return(
                <div className="details-form-container">
                    <div className="back-btn-container">
                        <div className="back-btn-tf" onClick={(e)=>{
                            e.preventDefault();
                            setDetailView(false);
                            setSelectedProject(null);
                            setDetailsData(null);
                        }}>
                            <BackIcon/>
                        </div>
                        <div className="btn-cancel">
                            <Button 
                                className="cancelBtn" 
                                variant="contained"
                                onClick={()=>{
                                    updateServiceCancel()
                                }}
                            >
                                Cancel service
                            </Button>
                        </div>
                    </div>
                    <div className="container-left-details">
                        <div className="container-form-data">
                            
                            <div className="detail-header">
                                <div className="detail-form-title">
                                    <div className="detail-title">
                                        Service information
                                    </div>
                                    <div className="slide-container">
                                        <div className="table-r-label">Status bar:</div>
                                        <ThemeProvider theme={theme}>

                                            <Slider
                                                aria-label="Custom marks"
                                                disabled
                                                value={status}
                                                onChange={(e, newValue)=>{setStatus(newValue)}}
                                                step={3}
                                                valueLabelDisplay="auto"
                                                marks={sliderMarks}
                                                sx={{width:500}}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>
                                <div className="detail-status-bar">
                                    
                                </div>
                            </div>
                    
                            <div className="form">
                                <div className="form-content">
                                    <div className="form-group">
                                        <TextField
                                            className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Type of service" 
                                            variant="outlined"
                                            
                                            value={detailsData.serviceType}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Property name" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disabled
                                                label="Inspection Date"
                                                value={detailsData.startingDate}
                                                renderInput={(params) => <TextField className="login-input" fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Address" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].address}
                                        />
                                    </div>
                                </div>
                                <div className="form-content">
                                
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled
                                            id="outlined-basic" 
                                            label="Customer" 
                                            variant="outlined"
                                            
                                            value={user.name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Representative" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].representative}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Type of roof" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].type}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="City" 
                                            variant="outlined"
                                            
                                            value={user.city}
                                        />
                                    </div>
                                
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Property description/Client request</div>
                                <div className="form-group-text-area">
                                    <textarea
                                        className="text-area-detail"
                                        disabled 
                                        id="outlined-basic" 
                                        label="Nota" 
                                        variant="outlined"
                                        
                                        multiline="true"
                                        value={detailsData.note}
                                    />
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Media</div>
                                {
                                    detailsData.companyCams===undefined?(

                                        <div className="table-r-label">There is currently no images attach to this service</div>
                                    ):(
                                        <React.Fragment>
                                            
                                            <Carousel animation='slide' indicators={true} autoPlay={false} 
                                            >  
                                            {
                                                projectImages&&
                                                    projectImages.map((item, i) => (
                                                        <div className="itemCatContainer" key={i}>
                                                            {
                                                                item.map((item2,ii)=>(
                                                                    <div key={'image-carousel'+ii} onClick={(e)=>{e.preventDefault(); openImageZoom(item2.uris[3].url)}}>
                                                                        <img  className="image-viewer" src={item2.uris[4].url}></img>
                                                                    </div>
                                                                    
                                                                ))
                                                            }
                                                        </div>
                                                    ))}
                                            </Carousel>
                                            <Button
                                                className="cancelBtn" 
                                                variant="contained"
                                                onClick={()=>{
                                                    downloadGallery.map((item, i) => (
                                                        saveAs(item,'traderoofing-webapp'+new Date()+'.jpeg')
                                                    ))
                                                
                                                }}
                                            >
                                                Download all
                                            </Button>
                                        </React.Fragment>
                                    )
                                }
                               
                            </div>
                            {

                                detailsData.proposals&& detailsData.status==='Proposal' &&
                                <div className="description-detail">
                                    <div className="table-h-label">Proposal</div>
                                    <div className="proposal-list">

                                        {
                                            detailsData.proposals.map((item,i)=>(
                                                <div className='proposal-pdf-container' key={'proposal-pdf'+i}
                                                    onClick={()=>{
                                                        downloadProposal(item.fileName)

                                                    }}
                                                >
                                                    <div className="pdf-Icon">
                                                        <PictureAsPdfIcon
                                                            sx={{color:"#1B3541", fontSize:60}}
                                                        ></PictureAsPdfIcon>
                                                    </div>
                                                    <div className="pdf-label">{item.fileName}</div>
                                                </div>
                                            ))

                                            
                                        }
                                    </div>
                                </div>
                            }

                            <div className="description-detail">
                                <div className="table-h-label">Notes</div>
                                {
                                    chat.length===0?(
                                        <div className="description-detail">
                                            
                                        </div>
                                    ):(

                                        chat.map((item,i)=>(
                        
                                            <div className={item.userId===user._id?"note-item":"note-item receive"} key={i}>
                                                <div className="table-h-label">{item.user}</div>
                                                <div className="table-r-label">{item.message}</div>
                                                <div className="table-r-label time">{formatDistance(new Date(),new Date(item.date))}</div>
                                            </div>
                                        ))
                                    )
                                }
                                    
                            </div>
                            
                            <div className="form-group-text-area">
                                <textarea
                                    className="text-area-detail"
                                    id="outlined-basic" 
                                    label="Write here" 
                                    variant="outlined"
                                    placeholder="Write here..."
                                    multiline="true"
                                    value={newMessage}
                                    onChange={(e)=>{
                                        setNewMessage(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="btn-send">
                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    disabled={newMessage===''}
                                    onClick={()=>{
                                        updateService()
                                    }}
                                >
                                    Send message
                                </Button>
                            </div>
                            {
                          
                                detailsData.log&&
                                    <div className="log-container">
                                        <div className="description-detail">
                                            <div className="table-h-label">Service changes log</div>
                                        </div>
                                        <div className="changes-log-list">
                                            {
                                                detailsData.log.map((item,i)=>(

                                                    <div key={'log-content-'+i} className='log-content'>
                                                        <div className="log-date">{format(new Date(item.date), 'dd/MM/yyyy')}</div>
                                                        <div className="log-user">User: {item.user}</div>
                                                        <div className="log-data">Change: {item.data}</div>
                                                        <div className="log-data">Message: {item.message}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }
    const FormDetailViewCalendar = ()=>{
        const [chat, setChat] = useState([]);
        const [date, setDate] = useState(new Date());
        const [projectImages, setProjectImages] = useState(null);
        const [newMessage, setNewMessage] = useState('');
        const [status, setStatus] = useState(0);
        const [downloadGallery, setDownloadGallery] = useState(null);
        const history = useNavigate();
        useEffect(()=>{
            setDate(detailsData.startingDate);
            getChat(detailsData._id);
            if(selectedProject===null){
               setSelectedProject(detailsData.projectData[0]);
            }
           switch (detailsData.status) {
                 case 'Pending':
                    setStatus(0);
                break;
                case 'Canceled':
                    setStatus(0);
                break;
                case 'Scheduled':
                    setStatus(25);
                break;
                case 'Proposal':
                    setStatus(50);
                break;
                case 'Working on it':
                    setStatus(75);
                break;
                case 'Finished':
                    setStatus(100);
                break;
                default:
                    break;
            }
            if(detailsData.companyCams){
                getImages(detailsData.companyCams);
            }
            
        },[]);
        useEffect(()=>{
            socket.on("update-messages-"+detailsData._id,(lastMessage)=>{
                debugger
                if(lastMessage.userId===user._id){

                    setChat([...chat]);
                }else{

                    setChat([...chat, lastMessage]);
                }
            });
            return()=>{
                socket.off("update-messages-"+detailsData._id)
            }
        })
        const getImages=(id)=>{
            axios.get(url+'get-project-images/'+detailsData._id+'/'+id)
            .then(
                res=>{
                    var galleryDownload=[];
                    var itemsPushed=[];
                    var finalItems= [];
                    res.data.data.map((item,i)=>{
                        
                        galleryDownload.push(item.uris[3].url)
                        var ii=i+1;
                        if(ii % 4 === 0){
                            itemsPushed.push(item);
                            finalItems.push(itemsPushed);
                            itemsPushed= [];
                        }else{
                            itemsPushed.push(item);
                            if(res.data.data.length===ii){
                                finalItems.push(itemsPushed);
                            }
                        }
                        
                    });
                    setProjectImages(finalItems);
                    setDownloadGallery(galleryDownload);
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        const updateService=async()=>{
            const formData={};
            const newChat={date:new Date(),message:newMessage,user:user.name, userId:user._id};
            var chatArray=chat;
            chatArray.push(newChat);
            formData.chat=chatArray;
            formData.id=detailsData._id;
            await axios.put(url+'update-service',formData)
            .then(
                res =>{
                    socket.emit('new-message',{id:res.data.data[0]._id,chat:newChat});
                    setNewMessage('');
                }
            )
            .catch(
                err=>{

                }
            )
        }
        const updateServiceCancel=async()=>{
            const formData={};
            formData.deleted=true;
            formData.id=detailsData._id;
            Swal.fire({
                title: 'Cancel service',
                text:'We will contact you after you cancel the service, Are you sure to continue?',
                confirmButtonText: 'Yes',
                showDenyButton: true,
                denyButtonText:'No'
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire({
                        title: 'Cancel service',
                        text:'Your service has been cancelled. We will contact you in the next 24 hours.',
                        confirmButtonText: 'Ok',
                    }).then(async ()=>{
                        setDetailView(false);
                        setSelectedProject(null);
                        setDetailsData(null);
                        await axios.put(url+'update-service-cancel',formData)
                        .then(
                            res =>{
                                if(user.manager){

                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                               getProcessForms();
                            }
                        )
                        .catch(
                            err=>{

                            }
                        )
                    })
                }else if(result.isDenied){

                }
            })
        }
        const getChat=(id)=>{
            debugger;
            axios.get(url+'get-service-chat/'+id)
            .then(
                res=>{
                        setChat(res.data.data)
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        if(detailsData===null){
            return(
                <CircularProgress></CircularProgress>
            )
        }else{
            return(
                <div className="details-form-container">
                    <div className="back-btn-container">
                        <div className="back-btn-tf" onClick={(e)=>{
                            e.preventDefault();
                            setDetailViewCalendar(false);
                            setSelectedProject(null);
                            setDetailsData(null);
                        }}>
                            <BackIcon/>
                        </div>
                        {
                            detailsData.status!=='Canceled' &&

                            <div className="btn-cancel">
                                <Button 
                                    className="cancelBtn" 
                                    variant="contained"
                                    onClick={()=>{
                                        updateServiceCancel()
                                    }}
                                >
                                    Cancel service
                                </Button>
                            </div>
                        }
                    </div>
                    <div className="container-left-details">
                        <div className="container-form-data">
                            
                            <div className="detail-header">
                                <div className="detail-form-title">
                                    <div className="detail-title">
                                        Service information
                                    </div>
                                    <div className="slide-container">
                                        <div className="table-r-label">Status bar:</div>
                                        <ThemeProvider theme={theme}>

                                            <Slider
                                                aria-label="Custom marks"
                                                disabled
                                                value={status}
                                                onChange={(e, newValue)=>{setStatus(newValue)}}
                                                step={3}
                                                valueLabelDisplay="auto"
                                                marks={sliderMarks}
                                                sx={{width:500}}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>
                                <div className="detail-status-bar">
                                    
                                </div>
                            </div>
                    
                            <div className="form">
                                <div className="form-content">
                                    <div className="form-group">
                                        <TextField
                                            className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Type of service" 
                                            variant="outlined"
                                            
                                            value={detailsData.serviceType}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Property name" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disabled
                                                label="Pick a date"
                                                value={detailsData.startingDate}
                                                renderInput={(params) => <TextField className="login-input" fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Address" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].address}
                                        />
                                    </div>
                                </div>
                                <div className="form-content">
                                
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled
                                            id="outlined-basic" 
                                            label="Customer" 
                                            variant="outlined"
                                            
                                            value={user.name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Representative" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].representative}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Type of roof" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].type}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="City" 
                                            variant="outlined"
                                            
                                            value={user.city}
                                        />
                                    </div>
                                
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Property description/Client request</div>
                                <div className="form-group-text-area">
                                    <textarea
                                        className="text-area-detail"
                                        disabled 
                                        id="outlined-basic" 
                                        label="Nota" 
                                        variant="outlined"
                                        
                                        multiline="true"
                                        value={detailsData.note}
                                    />
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Media</div>
                                {
                                    detailsData.companyCams===undefined?(

                                        <div className="table-r-label">There is currently  no images attached to this service</div>
                                    ):(
                                        <React.Fragment>
                                            
                                            <Carousel animation='slide' indicators={true} autoPlay={false} 
                                            >  
                                            {
                                                projectImages&&
                                                    projectImages.map((item, i) => (
                                                        <div className="itemCatContainer" key={i}>
                                                            {
                                                                item.map((item2,ii)=>(
                                                                    <div key={'image-carousel'+ii} onClick={(e)=>{e.preventDefault(); openImageZoom(item2.uris[3].url)}}>
                                                                        <img  className="image-viewer" src={item2.uris[4].url}></img>
                                                                    </div>
                                                                    
                                                                ))
                                                            }
                                                        </div>
                                                    ))}
                                            </Carousel>
                                            <Button
                                                className="cancelBtn" 
                                                variant="contained"
                                                onClick={()=>{
                                                    downloadGallery.map((item, i) => (
                                                        saveAs(item,'traderoofing-webapp'+new Date()+'.jpeg')
                                                    ))
                                                
                                                }}
                                            >
                                                Download all
                                            </Button>
                                        </React.Fragment>
                                    )
                                }
                               
                               
                            </div>
                            {

                                detailsData.proposals&& detailsData.status==='Proposal' &&
                                <div className="description-detail">
                                    <div className="table-h-label">Proposal</div>
                                    <div className="proposal-list">

                                        {
                                            detailsData.proposals.map((item,i)=>(
                                                <div className='proposal-pdf-container' key={'proposal-pdf'+i}
                                                    onClick={()=>{
                                                        downloadProposal(item.fileName)

                                                    }}
                                                >
                                                    <div className="pdf-Icon">
                                                        <PictureAsPdfIcon
                                                            sx={{color:"#1B3541", fontSize:60}}
                                                        ></PictureAsPdfIcon>
                                                    </div>
                                                    <div className="pdf-label">{item.fileName}</div>
                                                </div>
                                            ))

                                            
                                        }
                                    </div>
                                </div>
                            }
                            {
                                detailsData.status!=='Canceled' &&
                                <React.Fragment>

                                    <div className="description-detail">
                                        <div className="table-h-label">Notes</div>
                                        {
                                            chat.length===0?(
                                                <div className="description-detail">
                                                    
                                                </div>
                                            ):(

                                                chat.map((item,i)=>(

                                                    <div className={item.userId===user._id?"note-item":"note-item receive"} key={i}>
                                                        <div className="table-h-label">{item.user}</div>
                                                        <div className="table-r-label">{item.message}</div>
                                                        <div className="table-r-label time">{formatDistance(new Date(),new Date(item.date))}</div>
                                                    </div>
                                                ))
                                            )
                                        }
                                            
                                    </div>
                                
                                    <div className="form-group-text-area">
                                        <textarea
                                            className="text-area-detail"
                                            id="outlined-basic" 
                                            label="Write here" 
                                            variant="outlined"
                                            placeholder="Write here..."
                                            multiline="true"
                                            value={newMessage}
                                            onChange={(e)=>{
                                                setNewMessage(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="btn-send">
                                        <Button 
                                            className="loginBtn" 
                                            variant="contained"
                                            disabled={newMessage===''}
                                            onClick={()=>{
                                                updateService()
                                            }}
                                        >
                                            Send message
                                        </Button>
                                    </div>
                                </React.Fragment>
                            }
                            {
                          
                                detailsData.log&&
                                    <div className="log-container">
                                        <div className="description-detail">
                                            <div className="table-h-label">Service changes log</div>
                                        </div>
                                        <div className="changes-log-list">
                                            {
                                                detailsData.log.map((item,i)=>(

                                                    <div key={'log-content-'+i} className='log-content'>
                                                        <div className="log-date">{format(new Date(item.date), 'dd/MM/yyyy')}</div>
                                                        <div className="log-user">User: {item.user}</div>
                                                        <div className="log-data">Change: {item.data}</div>
                                                        <div className="log-data">Message: {item.message}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }
    const FormDetailLastView = ()=>{
        const [date, setDate] = useState(new Date());
        const [projectImages, setProjectImages] = useState(null);
        const [newMessage, setNewMessage] = useState('');
        const [chat, setChat] = useState([]);
        const [status, setStatus] = useState(0);
        const [downloadGallery, setDownloadGallery] = useState(null);
        useEffect(()=>{
            setDate(detailsData.startingDate);
            getChat(detailsData._id);
            if(selectedProject===null){
               setSelectedProject(detailsData.projectData[0]);
            }
           switch (detailsData.status) {
                 case 'Pending':
                    setStatus(0);
                break;
                case 'Canceled':
                    setStatus(0);
                break;
                case 'Scheduled':
                    setStatus(25);
                break;
                case 'Proposal':
                    setStatus(50);
                break;
                case 'Working on it':
                    setStatus(75);
                break;
                case 'Finished':
                    setStatus(100);
                break;
                default:
                    break;
            }
            if(detailsData.companyCams){
                getImages(detailsData.companyCams);
            }
           
        },[]);
        useEffect(()=>{
            socket.on("update-messages-"+detailsData._id,(lastMessage)=>{
                debugger
                if(lastMessage.userId===user._id){

                    setChat([...chat]);
                }else{

                    setChat([...chat, lastMessage]);
                }
            });
            return()=>{
                socket.off("update-messages-"+detailsData._id)
            }
        })
        const getImages=(id)=>{
            axios.get(url+'get-project-images/'+detailsData._id+'/'+id)
            .then(
                res=>{
                    var galleryDownload=[];
                    var itemsPushed=[];
                    var finalItems= [];
                    res.data.data.map((item,i)=>{
                        
                        galleryDownload.push(item.uris[3].url)
                        var ii=i+1;
                        if(ii % 4 === 0){
                            itemsPushed.push(item);
                            finalItems.push(itemsPushed);
                            itemsPushed= [];
                        }else{
                            itemsPushed.push(item);
                            if(res.data.data.length===ii){
                                finalItems.push(itemsPushed);
                            }
                        }
                        
                    });
                    setProjectImages(finalItems);
                    setDownloadGallery(galleryDownload);
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        const getChat=(id)=>{
            debugger;
            axios.get(url+'get-service-chat/'+id)
            .then(
                res=>{
                        setChat(res.data.data)
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        const updateService=async()=>{
            const formData={};
               const newChat={date:new Date(),message:newMessage,user:user.name, userId:user._id};
            var chatArray=chat;
            chatArray.push(newChat);
            formData.chat=chatArray;
            formData.id=detailsData._id;
            await axios.put(url+'update-service',formData)
            .then(
                res =>{
                    socket.emit('new-message',{id:res.data.data[0]._id,chat:newChat});
                    setNewMessage('');
                }
            )
            .catch(
                err=>{

                }
            )
        }
        const updateServiceCancel=async()=>{
            const formData={};
            formData.deleted=true;
            formData.id=detailsData._id;
            Swal.fire({
                title: 'Cancel service',
                text:'We will contact you after you cancel the service, Are you sure to continue?',
                confirmButtonText: 'Yes',
                showDenyButton: true,
                denyButtonText:'No'
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire({
                        title: 'Cancel service',
                        text:'Your service has been cancelled. We will contact you in the next 24 hours.',
                        confirmButtonText: 'Ok',
                    }).then(()=>{
                        setDetailView(false);
                        setSelectedProject(null);
                        setDetailsData(null);
                        axios.put(url+'update-service-cancel',formData)
                        .then(
                            res =>{
                                if(user.manager){

                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                            }
                        )
                        .catch(
                            err=>{

                            }
                        )
                    })
                }else if(result.isDenied){

                }
            })
        }
        if(projectImages===null && detailsData.companyCams!==undefined){
            return(
                <CircularProgress></CircularProgress>
            )
        }else{
            return(
                <div className="details-form-container detail-service-project">
                    <div className="back-btn-container">
                        <div className="back-btn-tf" onClick={(e)=>{
                            e.preventDefault();
                            setDetailView(false);
                            setSelectedProject(null);
                            setDetailsData(null);
                        }}>
                            <BackIcon/>
                        </div>
                        <div className="btn-cancel">
                            <Button 
                                className="cancelBtn" 
                                variant="contained"
                                onClick={()=>{
                                    updateServiceCancel()
                                }}
                            >
                                Cancel service
                            </Button>
                        </div>
                    </div>
                    <div className="container-left-details">
                        <div className="container-form-data">
                            
                            <div className="detail-header">
                                <div className="detail-form-title">
                                    <div className="detail-title">
                                        Service information
                                    </div>
                                    <div className="slide-container">
                                        <div className="table-r-label">Status bar:</div>
                                        <ThemeProvider theme={theme}>

                                            <Slider
                                                aria-label="Custom marks"
                                                disabled
                                                value={status}
                                                onChange={(e, newValue)=>{setStatus(newValue)}}
                                                step={3}
                                                valueLabelDisplay="auto"
                                                marks={sliderMarks}
                                                sx={{width:500}}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>
                                <div className="detail-status-bar">
                                    
                                </div>
                            </div>
                            <div className="form">
                                <div className="form-content">
                                    <div className="form-group">
                                        <TextField
                                            className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Type of service" 
                                            variant="outlined"
                                            
                                            value={detailsData.serviceType}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Property name" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disabled
                                                label="Pick a date"
                                                value={detailsData.startingDate}
                                                renderInput={(params) => <TextField className="login-input" fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Address" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].address}
                                        />
                                    </div>
                                </div>
                                <div className="form-content">
                                
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled
                                            id="outlined-basic" 
                                            label="Customer" 
                                            variant="outlined"
                                            
                                            value={user.name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Onsite contact" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].contact}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Type of roof" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].type}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="City" 
                                            variant="outlined"
                                            
                                            value={user.city}
                                        />
                                    </div>
                                
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Property description/Client request</div>
                                <div className="form-group-text-area">
                                    <textarea
                                        className="text-area-detail"
                                        disabled 
                                        id="outlined-basic" 
                                        label="Nota" 
                                        variant="outlined"
                                        
                                        multiline="true"
                                        value={detailsData.note}
                                    />
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Media</div>
                                {
                                    detailsData.companyCams===undefined &&projectImages?(

                                        <div className="table-r-label">There is currently  no images attached to this service</div>
                                    ):(
                                        <React.Fragment>
                                            
                                            <Carousel animation='slide' indicators={true} autoPlay={false} 
                                            >  
                                            {
                                                projectImages&&
                                                    projectImages.map((item, i) => (
                                                        <div className="itemCatContainer" key={i}>
                                                            {
                                                                item.map((item2,ii)=>(
                                                                    <div key={'image-carousel'+ii} onClick={(e)=>{e.preventDefault(); openImageZoom(item2.uris[3].url)}}>
                                                                        <img  className="image-viewer" src={item2.uris[4].url}></img>
                                                                    </div>
                                                                    
                                                                ))
                                                            }
                                                        </div>
                                                    ))}
                                            </Carousel>
                                            <Button
                                                className="cancelBtn" 
                                                variant="contained"
                                                onClick={()=>{
                                                    downloadGallery.map((item, i) => (
                                                        saveAs(item,'traderoofing-webapp'+new Date()+'.jpeg')
                                                    ))
                                                
                                                }}
                                            >
                                                Download all
                                            </Button>
                                        </React.Fragment>
                                    )
                                }
                               
                               
                            </div>
                            {

                                detailsData.proposals&& detailsData.status==='Proposal' &&
                                <div className="description-detail">
                                    <div className="table-h-label">Proposal</div>
                                    <div className="proposal-list">

                                        {
                                            detailsData.proposals.map((item,i)=>(
                                                <div className='proposal-pdf-container' key={'proposal-pdf'+i}
                                                    onClick={()=>{
                                                        downloadProposal(item.fileName)

                                                    }}
                                                >
                                                    <div className="pdf-Icon">
                                                        <PictureAsPdfIcon
                                                            sx={{color:"#1B3541", fontSize:60}}
                                                        ></PictureAsPdfIcon>
                                                    </div>
                                                    <div className="pdf-label">{item.fileName}</div>
                                                </div>
                                            ))

                                            
                                        }
                                    </div>
                                </div>
                            }
                            <div className="description-detail">
                                <div className="table-h-label">Notes</div>
                                {
                                    chat.length===0?(
                                        <div className="description-detail">
                                            
                                        </div>
                                    ):(

                                        chat.map((item,i)=>(
                        
                                            <div className={item.userId===user._id?"note-item":"note-item receive"} key={i}>
                                                <div className="table-h-label">{item.user}</div>
                                                <div className="table-r-label">{item.message}</div>
                                                <div className="table-r-label time">{formatDistance(new Date(),new Date(item.date))}</div>
                                            </div>
                                        ))
                                    )
                                }
                                    
                            </div>
                          
                            <div className="form-group-text-area">
                                <textarea
                                    className="text-area-detail"
                                    id="outlined-basic" 
                                    label="Write here" 
                                    variant="outlined"
                                    placeholder="Write here..."
                                    multiline="true"
                                    value={newMessage}
                                    onChange={(e)=>{
                                        setNewMessage(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="btn-send">
                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    disabled={newMessage===''}
                                    onClick={()=>{
                                        updateService()
                                    }}
                                >
                                    Send message
                                </Button>
                            </div>
                            {
                          
                                detailsData.log&&
                                    <div className="log-container">
                                        <div className="description-detail">
                                            <div className="table-h-label">Service changes log</div>
                                        </div>
                                        <div className="changes-log-list">
                                            {
                                                detailsData.log.map((item,i)=>(

                                                    <div key={'log-content-'+i} className='log-content'>
                                                        <div className="log-date">{format(new Date(item.date), 'dd/MM/yyyy')}</div>
                                                        <div className="log-user">User: {item.user}</div>
                                                        <div className="log-data">Change: {item.data}</div>
                                                        <div className="log-data">Message: {item.message}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }
    const FormDetailFinishedView = ()=>{
        const [date, setDate] = useState(new Date());
        const [projectImages, setProjectImages] = useState(null);
        const [newMessage, setNewMessage] = useState('');
        const [chat, setChat] = useState([]);
        const [status, setStatus] = useState(0);
        const [downloadGallery, setDownloadGallery] = useState(null);
        useEffect(()=>{
            setDate(detailsData.startingDate);
            getChat(detailsData._id);
            if(selectedProject===null){
               setSelectedProject(detailsData.projectData[0]);
            }
           switch (detailsData.status) {
                 case 'Pending':
                    setStatus(0);
                break;
                case 'Canceled':
                    setStatus(0);
                break;
                case 'Scheduled':
                    setStatus(25);
                break;
                case 'Proposal':
                    setStatus(50);
                break;
                case 'Working on it':
                    setStatus(75);
                break;
                case 'Finished':
                    setStatus(100);
                break;
                default:
                    break;
            }
            if(detailsData.companyCams){
                getImages(detailsData.companyCams);
            }
           
        },[]);
        useEffect(()=>{
            socket.on("update-messages-"+detailsData._id,(lastMessage)=>{
                debugger
               if(lastMessage.userId===user._id){

                    setChat([...chat]);
                }else{

                    setChat([...chat, lastMessage]);
                }
            });
            return()=>{
                socket.off("update-messages-"+detailsData._id)
            }
        })
        const getImages=(id)=>{
            axios.get(url+'get-project-images/'+detailsData._id+'/'+id)
            .then(
                res=>{
                    var galleryDownload=[];
                    var itemsPushed=[];
                    var finalItems= [];
                    res.data.data.map((item,i)=>{
                        
                        galleryDownload.push(item.uris[3].url)
                        var ii=i+1;
                        if(ii % 4 === 0){
                            itemsPushed.push(item);
                            finalItems.push(itemsPushed);
                            itemsPushed= [];
                        }else{
                            itemsPushed.push(item);
                            if(res.data.data.length===ii){
                                finalItems.push(itemsPushed);
                            }
                        }
                        
                    });
                    setProjectImages(finalItems);
                    setDownloadGallery(galleryDownload);
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        const getChat=(id)=>{
            debugger;
            axios.get(url+'get-service-chat/'+id)
            .then(
                res=>{
                        setChat(res.data.data)
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        if(projectImages===null && detailsData.companyCams!==undefined){
            return(
                <CircularProgress></CircularProgress>
            )
        }else{
            return(
                <div className="details-form-container detail-service-project">
                    <div className="back-btn-container">
                        <div className="back-btn-tf" onClick={(e)=>{
                            e.preventDefault();
                            setFinishedDetailView(false);
                            setSelectedProject(null);
                            setDetailsData(null);
                        }}>
                            <BackIcon/>
                        </div>
                    </div>
                    <div className="container-left-details">
                        <div className="container-form-data">
                            
                            <div className="detail-header">
                                <div className="detail-form-title">
                                    <div className="detail-title">
                                        Service information
                                    </div>
                                    <div className="slide-container">
                                        <div className="table-r-label">Status bar:</div>
                                        <ThemeProvider theme={theme}>

                                            <Slider
                                                aria-label="Custom marks"
                                                disabled
                                                value={status}
                                                onChange={(e, newValue)=>{setStatus(newValue)}}
                                                step={3}
                                                valueLabelDisplay="auto"
                                                marks={sliderMarks}
                                                sx={{width:500}}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>
                                <div className="detail-status-bar">
                                    
                                </div>
                            </div>
                            <div className="form">
                                <div className="form-content">
                                    <div className="form-group">
                                        <TextField
                                            className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Type of service" 
                                            variant="outlined"
                                            
                                            value={detailsData.serviceType}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Property name" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disabled
                                                label="Pick a date"
                                                value={detailsData.startingDate}
                                                renderInput={(params) => <TextField className="login-input" fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Address" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].address}
                                        />
                                    </div>
                                </div>
                                <div className="form-content">
                                
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled
                                            id="outlined-basic" 
                                            label="Customer" 
                                            variant="outlined"
                                            
                                            value={user.name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Onsite contact" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].contact}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Type of roof" 
                                            variant="outlined"
                                            
                                            value={detailsData.projectData[0].type}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="City" 
                                            variant="outlined"
                                            
                                            value={user.city}
                                        />
                                    </div>
                                
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Property description/Client request</div>
                                <div className="form-group-text-area">
                                    <textarea
                                        className="text-area-detail"
                                        disabled 
                                        id="outlined-basic" 
                                        label="Nota" 
                                        variant="outlined"
                                        
                                        multiline="true"
                                        value={detailsData.note}
                                    />
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Media</div>
                                {
                                    detailsData.companyCams===undefined?(

                                        <div className="table-r-label">There is currently  no images attached to this service</div>
                                    ):(
                                        <React.Fragment>
                                            
                                            <Carousel animation='slide' indicators={true} autoPlay={false} 
                                            >  
                                            {
                                                projectImages&&
                                                    projectImages.map((item, i) => (
                                                        <div className="itemCatContainer" key={i}>
                                                            {
                                                                item.map((item2,ii)=>(
                                                                    <div key={'image-carousel'+ii} onClick={(e)=>{e.preventDefault(); openImageZoom(item2.uris[3].url)}}>
                                                                        <img  className="image-viewer" src={item2.uris[4].url}></img>
                                                                    </div>
                                                                    
                                                                ))
                                                            }
                                                        </div>
                                                    ))}
                                            </Carousel>
                                            <Button
                                                className="cancelBtn" 
                                                variant="contained"
                                                onClick={()=>{
                                                    downloadGallery.map((item, i) => (
                                                        saveAs(item,'traderoofing-webapp'+new Date()+'.jpeg')
                                                    ))
                                                
                                                }}
                                            >
                                                Download all
                                            </Button>
                                        </React.Fragment>
                                    )
                                }
                               
                               
                            </div>
                            {
                                chat.length===0?(
                                    <div className="description-detail">
                                        <div className="table-h-label">Notes</div>
                                    </div>
                                ):(

                                    chat.map((item,i)=>(
                    
                                        <div className={item.userId===user._id?"note-item":"note-item receive"} key={i}>
                                            <div className="table-h-label">{item.user}</div>
                                            <div className="table-r-label">{item.message}</div>
                                            <div className="table-r-label time">{formatDistance(new Date(),new Date(item.date))}</div>
                                        </div>
                                    ))
                                )
                            }
                           
                            {
                                detailsData.log&&
                                <div className="log-container">
                                    <div className="description-detail">
                                        <div className="table-h-label">Service changes log</div>
                                    </div>
                                    <div className="changes-log-list">
                                        {
                                            detailsData.log.map((item,i)=>(

                                                <div key={'log-content-'+i} className='log-content'>
                                                    <div className="log-date">{format(new Date(item.date), 'dd/MM/yyyy')}</div>
                                                    <div className="log-user">User: {item.user}</div>
                                                    <div className="log-data">Change: {item.data}</div>
                                                    <div className="log-data">Message: {item.message}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }
    const FormDetailService = ()=>{
        const [date, setDate] = useState(new Date());
        const [projectImages, setProjectImages] = useState(null);
        const [newMessage, setNewMessage] = useState('');
        const [chat, setChat] = useState([]);
        const [status, setStatus] = useState(0);
        const [downloadGallery, setDownloadGallery] = useState(null);
        useEffect(()=>{
            debugger;
            setDate(detailsData.startingDate);
            getChat(detailsData._id);
           switch (detailsData.status) {
                 case 'Pending':
                    setStatus(0);
                break;
                case 'Canceled':
                    setStatus(0);
                break;
                case 'Scheduled':
                    setStatus(25);
                break;
                case 'Proposal':
                    setStatus(50);
                break;
                case 'Working on it':
                    setStatus(75);
                break;
                case 'Finished':
                    setStatus(100);
                break;
                default:
                    break;
            }
            if(detailsData.companyCams){
                getImages(detailsData.companyCams);
            }
          
        },[]);
        useEffect(()=>{
            socket.on("update-messages-"+detailsData._id,(lastMessage)=>{
                debugger
               if(lastMessage.userId===user._id){

                    setChat([...chat]);
                }else{

                    setChat([...chat, lastMessage]);
                }
            });
            return()=>{
                socket.off("update-messages-"+detailsData._id)
            }
        })
        const getImages=(id)=>{
            axios.get(url+'get-project-images/'+detailsData._id+'/'+id)
            .then(
                res=>{
                    var galleryDownload=[];
                    var itemsPushed=[];
                    var finalItems= [];
                    res.data.data.map((item,i)=>{
                        
                        galleryDownload.push(item.uris[3].url)
                        var ii=i+1;
                        if(ii % 4 === 0){
                            itemsPushed.push(item);
                            finalItems.push(itemsPushed);
                            itemsPushed= [];
                        }else{
                            itemsPushed.push(item);
                            if(res.data.data.length===ii){
                                finalItems.push(itemsPushed);
                            }
                        }
                        
                    });
                    setProjectImages(finalItems);
                    setDownloadGallery(galleryDownload);
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        
        const updateService=async()=>{
            const formData={};
               const newChat={date:new Date(),message:newMessage,user:user.name, userId:user._id};
            var chatArray=chat;
            chatArray.push(newChat);
            formData.chat=chatArray;
            formData.id=detailsData._id;
            await axios.put(url+'update-service',formData)
            .then(
                res =>{
                    socket.emit('new-message',{id:res.data.data[0]._id,chat:newChat});
                    setNewMessage('');
                }
            )
            .catch(
                err=>{

                }
            )
        }
        const updateServiceCancel=async()=>{
            const formData={};
            formData.deleted=true;
            formData.id=detailsData._id;
            Swal.fire({
                title: 'Cancel service',
                text:'We will contact you after you cancel the service, Are you sure to continue?',
                confirmButtonText: 'Yes',
                showDenyButton: true,
                denyButtonText:'No'
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire({
                        title: 'Cancel service',
                        text:'Your service has been cancelled. We will contact you in the next 24 hours.',
                        confirmButtonText: 'Ok',
                    }).then(()=>{
                        setEditDetailService(false);
                        setCreateEditProject(false);
                        setSelectedProject(null);
                        setDetailsData(null);
                       axios.put(url+'update-service-cancel',formData)
                        .then(
                            res =>{
                                if(user.manager){

                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                            }
                        )
                        .catch(
                            err=>{

                            }
                        )
                    })
                }else if(result.isDenied){

                }
            })
        }
        const getChat=(id)=>{
            debugger;
            axios.get(url+'get-service-chat/'+id)
            .then(
                res=>{
                        setChat(res.data.data)
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        if(projectImages===null && detailsData.companyCams!==undefined){
            return(
                <CircularProgress></CircularProgress>
            )
        }else{
            return(
                <div className="details-form-container detail-service-project">
                     <div className="back-btn-container">
                        <div className="back-btn-tf" onClick={(e)=>{
                            e.preventDefault();
                            setEditDetailService(false);
                            setCreateEditProject(false);
                            setSelectedProject(null);
                            setDetailsData(null);
                        }}>
                            <BackIcon/>
                        </div>
                        <div className="btn-cancel">
                            <Button 
                                className="cancelBtn" 
                                variant="contained"
                                onClick={()=>{
                                    updateServiceCancel()
                                }}
                            >
                                Cancel service
                            </Button>
                        </div>
                    </div>
                    <div className="container-left-details">
                        <div className="container-form-data">
                            
                            <div className="detail-header">
                                <div className="detail-form-title">
                                    <div className="detail-title">
                                        Service information
                                    </div>
                                    <div className="slide-container">
                                        <div className="table-r-label">Status bar:</div>
                                        <ThemeProvider theme={theme}>

                                            <Slider
                                                aria-label="Custom marks"
                                                disabled
                                                value={status}
                                                onChange={(e, newValue)=>{setStatus(newValue)}}
                                                step={3}
                                                valueLabelDisplay="auto"
                                                marks={sliderMarks}
                                                sx={{width:500}}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>
                                <div className="detail-status-bar">
                                    
                                </div>
                            </div>
                            <div className="form">
                                <div className="form-content">
                                    <div className="form-group">
                                        <TextField
                                            className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Type of service" 
                                            variant="outlined"
                                            
                                            value={detailsData.serviceType}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Property name" 
                                            variant="outlined"
                                            
                                            value={selectedProject.name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disabled
                                                label="Pick a date"
                                                value={detailsData.startingDate}
                                                renderInput={(params) => <TextField className="login-input" fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Address" 
                                            variant="outlined"
                                            
                                            value={selectedProject.address}
                                        />
                                    </div>
                                </div>
                                <div className="form-content">
                                
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled
                                            id="outlined-basic" 
                                            label="Customer" 
                                            variant="outlined"
                                            
                                            value={user.name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Representative" 
                                            variant="outlined"
                                            
                                            value={selectedProject.representative}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Type of roof" 
                                            variant="outlined"
                                            
                                            value={selectedProject.type}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="City" 
                                            variant="outlined"
                                            
                                            value={user.city}
                                        />
                                    </div>
                                
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Property description/Client request</div>
                                <div className="form-group-text-area">
                                    <textarea
                                        className="text-area-detail"
                                        disabled 
                                        id="outlined-basic" 
                                        label="Nota" 
                                        variant="outlined"
                                        
                                        multiline="true"
                                        value={detailsData.note}
                                    />
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Media</div>
                                {
                                    detailsData.companyCams===undefined?(

                                        <div className="table-r-label">There is currently no images attached to this service</div>
                                    ):(
                                        <React.Fragment>
                                            
                                            <Carousel animation='slide' indicators={true} autoPlay={false} 
                                            >  
                                            {
                                                projectImages&&
                                                    projectImages.map((item, i) => (
                                                        <div className="itemCatContainer" key={i}>
                                                            {
                                                                item.map((item2,ii)=>(
                                                                    <div key={'image-carousel'+ii} onClick={(e)=>{e.preventDefault(); openImageZoom(item2.uris[3].url)}}>
                                                                        <img  className="image-viewer" src={item2.uris[4].url}></img>
                                                                    </div>
                                                                    
                                                                ))
                                                            }
                                                        </div>
                                                    ))}
                                            </Carousel>
                                            <Button
                                                className="cancelBtn" 
                                                variant="contained"
                                                onClick={()=>{
                                                    downloadGallery.map((item, i) => (
                                                        saveAs(item,'traderoofing-webapp'+new Date()+'.jpeg')
                                                    ))
                                                
                                                }}
                                            >
                                                Download all
                                            </Button>
                                        </React.Fragment>
                                    )
                                }
                               
                            </div>
                            {

                                detailsData.proposals&& detailsData.status==='Proposal' &&
                                <div className="description-detail">
                                    <div className="table-h-label">Proposal</div>
                                    <div className="proposal-list">

                                        {
                                            detailsData.proposals.map((item,i)=>(
                                                <div className='proposal-pdf-container' key={'proposal-pdf'+i}
                                                    onClick={()=>{
                                                        downloadProposal(item.fileName)

                                                    }}
                                                >
                                                    <div className="pdf-Icon">
                                                        <PictureAsPdfIcon
                                                            sx={{color:"#1B3541", fontSize:60}}
                                                        ></PictureAsPdfIcon>
                                                    </div>
                                                    <div className="pdf-label">{item.fileName}</div>
                                                </div>
                                            ))

                                            
                                        }
                                    </div>
                                </div>
                            }
                           <div className="description-detail">
                                <div className="table-h-label">Notes</div>
                                {
                                    chat.length===0?(
                                        <div className="description-detail">
                                        </div>
                                    ):(

                                        chat.map((item,i)=>(
                        
                                            <div className={item.userId===user._id?"note-item":"note-item receive"} key={i}>
                                                <div className="table-h-label">{item.user}</div>
                                                <div className="table-r-label">{item.message}</div>
                                                <div className="table-r-label time">{formatDistance(new Date(),new Date(item.date))}</div>
                                            </div>
                                        ))
                                    )
                                }
                                    
                            </div>
                        
                           
                            <div className="form-group-text-area">
                                <textarea
                                    className="text-area-detail"
                                    id="outlined-basic" 
                                    label="Write here" 
                                    variant="outlined"
                                    placeholder="Write here..."
                                    multiline="true"
                                    value={newMessage}
                                    onChange={(e)=>{
                                        setNewMessage(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="btn-send">
                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    disabled={newMessage===''}
                                    onClick={()=>{
                                        updateService()
                                    }}
                                >
                                    Send message
                                </Button>
                            </div>
                            {
                          
                                detailsData.log&&
                                    <div className="log-container">
                                        <div className="description-detail">
                                            <div className="table-h-label">Service changes log</div>
                                        </div>
                                        <div className="changes-log-list">
                                            {
                                                detailsData.log.map((item,i)=>(

                                                    <div key={'log-content-'+i} className='log-content'>
                                                        <div className="log-date">{format(new Date(item.date), 'dd/MM/yyyy')}</div>
                                                        <div className="log-user">User: {item.user}</div>
                                                        <div className="log-data">Change: {item.data}</div>
                                                        <div className="log-data">Message: {item.message}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }
    const FormCreateProject = ()=>{
        const [contact, setContact] = useState('');

        const [contactPhone, setContactPhone] = useState('');

        const [projectName, setProjectName] = useState('');
        const [projectError, setProjectError] = useState(false);

        const [projectAddress, setProjectAddress] = useState('');
        const [addressError, setAddressError] = useState(false);

        const [projectTypeError, setProjectTypeError] = useState(false);
        const [roofType, setRoofType] = React.useState('');
        const [roofTypes, setRoofTypes] = useState([]);

        const [projectAccessError, setProjectAccessError] = useState(false);
        const [roofAccess, setRoofAccess] = React.useState('');
        const [roofAccesss, setRoofAccesss] = useState([]);

        const [note, setNote] = useState('');
        useEffect(()=>{
            setRoofTypes(Global.roof_types);
            setRoofType(Global.roof_types[0]);
            setRoofAccesss(Global.roof_access);
            setRoofAccess(Global.roof_access[0]);
        },[])
        const saveProject = ()=>{
            if(projectName===''){
                setProjectError(true);
            }
            if(projectAddress===''){
                setAddressError(true);
            }
            if(roofType===''){
                setProjectTypeError(true);
            }
            if(roofAccess===''){
                setProjectAccessError(true);
            }
            if(projectError || projectTypeError || addressError || projectAccessError){
                return
            }else{
                debugger;
                //arrancar w8 screen
                var formData={};
                formData.name=projectName;
                formData.address=projectAddress;
                formData.access=roofAccess.label;
                formData.type=roofType.label;
                formData.contact=contact;
                formData.contact_number=contactPhone;
                formData.note=note;
                formData.user=user._id
                axios.post(url+'create-project',formData)
                .then(
                    res=>{
                        setCreateEditProject(false);
                    }
                )
                .catch(
                    err=>{
                        console.log(err)
                    }
                )
            }
            //create form data
            //run service
            //redirect
        }
        return(
            <div className="details-form-container">
                <div className="back-btn-container">
                    <div className="back-btn-tf" onClick={(e)=>{
                        e.preventDefault();
                        setCreateEditProject(false);
                    }}>
                        <BackIcon/>
                    </div>
                </div>
                <div className="container-left-details create-project">
                    <div className="container-form-data">
                        <div className="detail-header">
                            <div className="detail-form-title">
                                <div className="detail-title">
                                    Register property
                                </div>
                            </div>
                            <div className="detail-status-bar">
                                
                            </div>
                        </div>
                        <div className="form">
                            <div className="form-content">
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Property name" 
                                        variant="outlined"
                                        value={projectName}
                                        required
                                        error={projectError}
                                        helperText={projectError?('This field is required.'):('')}
                                        onChange={(e)=>{setProjectName(e.target.value);if(projectError)setProjectError(false)}}
                                    />
                                </div>
                              
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Property address" 
                                        variant="outlined"
                                        required
                                        value={projectAddress}
                                        error={addressError}
                                        helperText={addressError?('This field is required.'):('')}
                                        onChange={(e)=>{setProjectAddress(e.target.value);if(addressError)setAddressError(false)}}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Onsite contact name" 
                                        variant="outlined"
                                        value={contact}
                                        onChange={(e)=>{setContact(e.target.value);}}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Onsite contact phone" 
                                        variant="outlined"
                                        value={contactPhone}
                                        onChange={(e)=>{setContactPhone(e.target.value);}}
                                    />
                                </div>
                                
                            </div>
                            <div className="form-content">
                                <div className="form-group">
                                    <Autocomplete
                                        className="autocomplete-full" 
                                        options={roofTypes}
                                        value={roofType}
                                      
                                        onChange={(event, newValue) => {
                                            console.log(newValue)
                                            setRoofType(newValue===null?'':newValue.label);
                                            if(projectTypeError)setProjectTypeError(false);
                                        }}
                                        renderInput={(params)=> <TextField 
                                            {...params} 
                                            label="Roof type" 
                                            className="login-input" 
                                            id="outlined-basic" 
                                            variant="outlined"
                                            error={projectTypeError}
                                            helperText={projectTypeError?('This field is required.'):('')}
                                            >
                                            </TextField>
                                        }
                                    />
                                    
                                </div>
                                <div className="form-group">
                                    <Autocomplete
                                        className="autocomplete-full" 
                                        options={roofAccesss}
                                        value={roofAccess}
                                      
                                        onChange={(event, newValue) => {
                                            console.log(newValue)
                                            setRoofAccess(newValue===null?'':newValue.label);
                                            if(projectAccessError)setProjectAccessError(false);
                                        }}
                                        renderInput={(params)=> <TextField 
                                            {...params} 
                                            label="Roof access" 
                                            className="login-input" 
                                            id="outlined-basic" 
                                            variant="outlined" 
                                            required 
                                            error={projectAccessError}
                                            helperText={projectAccessError?('This field is required.'):('')}
                                            >
                                            </TextField>
                                        }
                                    />
                                    
                                    
                                </div>
                                <div className="form-group multiline-form">
                                    <TextField
                                        className="login-input multiline" 
                                        multiline
                                        maxRows={2}
                                        id="outlined-basic" 
                                        label="Note" 
                                        variant="outlined"
                                        onChange={(e)=>{setNote(e.target.value)}}
                                        value={note}
                                    />
                                </div>
                            
                            </div>
                        </div>
                        <div className="send-form-container">
                            <Button 
                                className="loginBtn" 
                                variant="contained"
                                onClick={()=>{
                                    saveProject()
                                }}
                            >
                                Send
                            </Button>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
    const FormEditProject = ()=>{
        const [date, setDate] = useState(new Date());

        const [contact, setContact] = useState(selectedProject.contact);
        const [contactError, setContactError] = useState(false);

        const [contactPhone, setContactPhone] = useState(selectedProject.contact_number);
        const [contactPhoneError, setContactPhoneError] = useState(false);

        const [projectName, setProjectName] = useState(selectedProject.name);
        const [projectError, setProjectError] = useState(false);

        const [projectAddress, setProjectAddress] = useState(selectedProject.address);
        const [addressError, setAddressError] = useState(false);

        const [projectTypeError, setProjectTypeError] = useState(false);
        const [roofType, setRoofType] = React.useState(selectedProject.type);
        const [roofTypes, setRoofTypes] = useState([]);

        const [projectAccessError, setProjectAccessError] = useState(false);
        const [roofAccess, setRoofAccess] = React.useState(selectedProject.access);
        const [roofAccesss, setRoofAccesss] = useState([]);

        const [note, setNote] = useState(selectedProject.note);

        const [checked, setChecked] = useState(false);


        const [currentService, setCurrentService]= useState(null);
        const [lastService, setLastService]= useState(null);

        useEffect(()=>{
            setRoofTypes(Global.roof_types);
            setRoofAccesss(Global.roof_access);
            getCurrentServices();
            getHistoryServices();
        },[])

        const getCurrentServices = ()=>{
            axios.get(url+'get-active-service-project/'+selectedProject._id)
            .then(
                res =>{
                    if(res.data.data[0]!==undefined){
                        setCurrentService(res.data.data[(res.data.data.length-1)])
                    }
                   
                }
            ).catch(
                err=>{

                }
            )
        }
        const getHistoryServices = ()=>{
             axios.get(url+'get-finished-services-project-id/'+selectedProject._id)
            .then(
                res =>{
                    if(res.data.data[0]!==undefined){
                        setLastService(res.data.data[0]);
                    }
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        const updateProject = ()=>{
            if(projectName===''){
                setProjectError(true);
            }
            if(projectAddress===''){
                setAddressError(true);
            }
            if(roofType===''){
                setProjectTypeError(true);
            }
            if(roofAccess===''){
                setProjectAccessError(true);
            }
            if(projectError || projectTypeError || addressError || projectAccessError){
                return
            }else{
                //arrancar w8 screen
                var formData={};
                formData.name=projectName;
                formData.address=projectAddress;
                formData.access=roofAccess.label===undefined?roofAccess:roofAccess.label;
                formData.type=roofType.label===undefined?roofType:roofType.label;
                formData.contact=contact;
                formData.contact_number=contactPhone;
                formData.note=note;
                formData.id=selectedProject._id;
                formData.userChange=user.name;
                axios.put(url+'update-project',formData)
                .then(
                    res=>{
                        setCreateEditProject(false);
                        setSelectedProject(null)
                    }
                )
                .catch(
                    err=>{
                        console.log(err)
                    }
                )
            }
        }
        const deleteDetailProject=()=>{
            var formData={};
            formData.id=selectedProject._id;
            Swal.fire({
                title: 'Delete property',
                text:'Are you sure you want to eliminate this property? You can always edit any details',
                confirmButtonText: 'Yes',
                showDenyButton: true,
                denyButtonText:'No'
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire({
                        title: 'Delete property',
                        text:'Your property has been deleted. We will contact you in the next 24 hours.',
                        confirmButtonText: 'Ok',
                    }).then(()=>{
                        axios.put(url+'update-property-delete',formData)
                        .then(
                            res=>{
                                setCreateEditProject(false);
                                setSelectedProject(null);
                                setLastService(null);
                                setCurrentService(null);
                            }
                        )
                        .catch(
                            err=>{
                                Swal.fire(
                                    {
                                        title: 'Ups',
                                        text:'Something went wrong!',
                                        confirmButtonText: 'Close',
                                        allowOutsideClick: false,
                                        showCloseButton: true
                                    }
                                )
                            }
                        )
                    })
                }else if(result.isDenied){
                    return
                }
            })
           
        }
        return(
            <div className="details-form-container">
                <div className="back-btn-container">
                    <div className="back-btn-tf" onClick={(e)=>{
                        e.preventDefault();
                        setCreateEditProject(false);
                        setSelectedProject(null);
                        setLastService(null);
                        setCurrentService(null);
                    }}>
                        <BackIcon/>
                    </div>
                </div>
                <div className="container-left-details create-project">
                    <div className="container-form-data">
                        <div className="detail-header">
                            <div className="detail-form-title">
                                <div className="detail-title">
                                    Edit property
                                </div>
                                <Checkbox
                                    checked={checked}
                                    onChange={(e)=>{ setChecked(e.target.checked)}}
                                ></Checkbox>
                            </div>
                            <div className="btn-cancel">
                                <Button 
                                    className="cancelBtn" 
                                    variant="contained"
                                    onClick={()=>{
                                        deleteDetailProject()
                                    }}
                                >
                                    Cancel service
                                </Button>
                            </div>
                        </div>
                        <div className="form">
                            <div className="form-content">
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Property name" 
                                        variant="outlined"
                                        value={projectName}
                                        required
                                        error={projectError}
                                        helperText={projectError?('This field is required.'):('')}
                                        onChange={(e)=>{setProjectName(e.target.value);if(projectError)setProjectError(false)}}
                                        disabled={!checked}
                                    />
                                </div>
                              
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Property address" 
                                        variant="outlined"
                                        required
                                        value={projectAddress}
                                        error={addressError}
                                        helperText={addressError?('This field is required.'):('')}
                                        onChange={(e)=>{setProjectAddress(e.target.value);if(addressError)setAddressError(false)}}
                                        disabled={!checked}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Onsite contact name" 
                                        variant="outlined"
                                        value={contact}
                                        onChange={(e)=>{setContact(e.target.value);}}
                                        disabled={!checked}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Onsite contact phone" 
                                        variant="outlined"
                                        value={contactPhone}
                                        onChange={(e)=>{setContactPhone(e.target.value);}}
                                        disabled={!checked}
                                    />
                                </div>
                                
                            </div>
                            <div className="form-content">
                                <div className="form-group">
                                    <Autocomplete
                                        className="autocomplete-full" 
                                        options={roofTypes}
                                        value={roofType}
                                        disabled={!checked}
                                        onChange={(event, newValue) => {
                                            setRoofType(newValue===null?'':newValue.label);
                                            if(projectTypeError)setProjectTypeError(false);
                                        }}
                                        renderInput={(params)=> <TextField 
                                            {...params} 
                                            label="Roof type" 
                                            className="login-input" 
                                            id="outlined-basic" 
                                            variant="outlined" 
                                            required 
                                            error={projectTypeError}
                                            helperText={projectTypeError?('This field is required.'):('')}
                                            >
                                            </TextField>
                                        }
                                    />
                                    
                                </div>
                                <div className="form-group">
                                    <Autocomplete
                                        className="autocomplete-full" 
                                        options={roofAccesss}
                                        value={roofAccess}
                                        disabled={!checked}
                                        onChange={(event, newValue) => {
                                            setRoofAccess(newValue===null?'':newValue.label);
                                            if(projectAccessError)setProjectAccessError(false);
                                        }}
                                        renderInput={(params)=> <TextField 
                                            {...params} 
                                            label="Roof access" 
                                            className="login-input" 
                                            id="outlined-basic" 
                                            variant="outlined" 
                                            required 
                                            error={projectAccessError}
                                            helperText={projectAccessError?('This field is required.'):('')}
                                            >
                                            </TextField>
                                        }
                                    />
                                    
                                    
                                </div>
                                <div className="form-group multiline-form">
                                    <TextField
                                        className="login-input multiline" 
                                        multiline
                                        maxRows={2}
                                        id="outlined-basic" 
                                        label="Note" 
                                        variant="outlined"
                                        onChange={(e)=>{setNote(e.target.value)}}
                                        value={note}
                                        disabled={!checked}
                                    />
                                </div>
                            
                            </div>
                        </div>
                        <div className="send-form-container">
                            {
                                checked&&

                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    disabled={!checked}
                                    onClick={()=>{
                                        updateProject()
                                    }}
                                >
                                    Save
                                </Button>
                            }
                        </div>
                        <div className="services-container-detail">
                            <div className="last-a-container">
                                <div className="main-b-title">Active service</div>
                                {
                                    currentService!==null?(
                                        <div className="last-activity-finished">
                                            <div className="table-header">
                                                <div className="table-h-label">Creation date</div>
                                                <div className="table-h-label">Schedule date</div>
                                                <div className="table-h-label">Type of service</div>
                                                <div className="table-h-label">Status</div>
                                                <div className="table-h-label"></div>
                                            </div>
                                            <div className="table-content">
                                                <div className="table-row">

                                                    <div className="table-r-label">{format(new Date(currentService.creationDate), 'dd/MM/yyyy')}</div>
                                                    <div className="table-r-label">{format(new Date(currentService.startingDate), 'dd/MM/yyyy')}</div>
                                                    <div className="table-r-label">{currentService.serviceType}</div>
                                                    <div className="table-r-label">{currentService.status}</div>
                                                    <div className="table-r-label-f">
                                                        <div className="link-green" onClick={()=>{setEditDetailService(true); setDetailsData(currentService)}}>
                                                            view details
                                                        </div>
                                                    <GoIcon/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ):(
                                        <div className="table-r-label no-service">There's not active service</div>
                                        //request service button
                                    )
                                }
                               
                            </div> 
                            <div className="last-f-container">
                                <div className="main-b-title">Closed Service</div>
                                {

                                    lastService!==null?(
                                            <div className="last-activity-finished">
                                                <div className="table-header">
                                                    <div className="table-h-label">Schedule date</div>
                                                    <div className="table-h-label">Finished date</div>
                                                    <div className="table-h-label">Type of service</div>
                                                    <div className="table-h-label">Status</div>
                                                    <div className="table-h-label"></div>
                                                </div>
                                                <div className="table-content">
                                                    <div className="table-row">
                                                        <div className="table-r-label">{format(new Date(lastService.startingDate), 'dd/MM/yyyy')}</div>
                                                        <div className="table-r-label">{format(new Date(lastService.finishedDate), 'dd/MM/yyyy')}</div>
                                                        <div className="table-r-label">{lastService.serviceType}</div>
                                                        <div className="table-r-label">{lastService.status}</div>
                                                        <div className="table-r-label-f">
                                                            <div className="link-green" onClick={()=>{downloadInvoice(lastService.invoiceUrl, lastService.imageReportUrl, lastService._id)/*setEditDetailService(true); setDetailsData(item)*/}}>
                                                            get resume
                                                            </div>
                                                        <GoIcon/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ):(
                                            <div className="table-r-label no-service">There's no finished service</div>
                                            //request service button
                                        )
                                }
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        )
        
    }
    const _onReady = (event)=> {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    const filterServices =(filter)=>{
       if(filter===''){
            setProcessFilteredForms(null);
       }else{
            var newProcessList=processForms.filter((item)=>{
                return item.projectData[0].name.toLowerCase().search(filter)!==-1
            });
            setProcessFilteredForms(newProcessList);
        }
       
    }
    const filterServicesFinished =(filter)=>{
        if(filter===''){
             setFinishedFilteredForms(null);
        }else{
             var newProcessList=finishedForms.filter((item)=>{
                 return item.projectData[0].name.toLowerCase().search(filter)!==-1
             });
             setFinishedFilteredForms(newProcessList);
         }
        
    }
    const sortSwitcherActive=(filter)=>{
        var sortArray=processFilteredForms?processFilteredForms:processForms;
        switch (filter) {
            case 0:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.serviceType===b.serviceType) return 0
                    return a.serviceType.localeCompare(b.serviceType);
                });
                setProcessFilteredForms(newArray);
            break;
            case 1:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.projectData[0].name===b.projectData[0].name) return 0
                    return a.projectData[0].name.localeCompare(b.projectData[0].name,undefined,{numeric:true});
                });
                setProcessFilteredForms(newArray);
            break;
            case 2:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.status===b.status) return 0
                    return a.status.localeCompare(b.status);
                });
                setProcessFilteredForms(newArray);
            break;
            case 3:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.creationDate===b.creationDate) return 0
                    return a.creationDate.localeCompare(b.creationDate);
                });
                setProcessFilteredForms(newArray);
            break;
        
            default:
                break;
        }
    }
    const sortSwitcherFinished=(filter)=>{
        var sortArray=finishedFilteredForms?finishedFilteredForms:finishedForms;
        switch (filter) {
            case 0:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.serviceType===b.serviceType) return 0
                    return a.serviceType.localeCompare(b.serviceType);
                });
                setProcessFilteredForms(newArray);
            break;
            case 1:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.projectData[0].name===b.projectData[0].name) return 0
                    return a.projectData[0].name.localeCompare(b.projectData[0].name,undefined,{numeric:true});
                });
                setProcessFilteredForms(newArray);
            break;
            case 2:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.status===b.status) return 0
                    return a.status.localeCompare(b.status);
                });
                setProcessFilteredForms(newArray);
            break;
            case 3:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.creationDate===b.creationDate) return 0
                    return a.creationDate.localeCompare(b.creationDate);
                });
                setProcessFilteredForms(newArray);
            break;
        
            default:
                break;
        }
    }
    const RequestUserList = ({data}) =>{

        return(
            <React.Fragment>
            <div className="table-header">
                 <div className="table-h-label">Type of request</div>
                 <div className="table-h-label">User name</div>
                 <div className="table-h-label">Mail</div>
                 <div className="table-h-label">Requested date</div>
                 <div className="table-h-label"></div>
             </div>
             <div className="table-content">
                 {
                     data.map((item,index)=>{
                         return(
                             <div className="table-row" key={index}>
                                 <div className="table-r-label">{item.requestType}</div>
                                 <div className="table-r-label">{item.userData[0].name}</div>
                                 <div className="table-r-label">{item.userData[0].mail}</div>
                                 <div className="table-r-label">{format(new Date(item.finishedDate), 'dd/MM/yyyy')}</div>
                                 <div className="table-r-label-f">
                                    {
                                        item.requestType==='W9'?(
                                            <React.Fragment>

                                                <div className="link-green" onClick={()=>{downloadRequest(item.document,item._id)}}>
                                                    download W9
                                                </div>
                                                <GoIcon/>
                                            </React.Fragment>
                                        ):(
                                            <React.Fragment>

                                                <div className="link-green" onClick={()=>{downloadRequest(item.document,item._id)}}>
                                                    download COI
                                                </div>
                                                <GoIcon/>
                                            </React.Fragment>
                                        )
                                    }
                                    
                                 </div>
                             </div>
                         )
                     })
                 }
             </div>
         </React.Fragment>
        )
    }
    const closeModal=()=>{
        setOpenModal(false); getProcessForms()
    }
    const MyAccountView = ()=>{
        const [projects, setProjects] = useState(null);
        const [checked, setChecked] = useState(false);

        const [name, setName] = useState(user.name);
        const [nameError, setNameError] = useState(false);

        const [companyName, setCompanyName] = useState(user.companyName);

        const [mail, setMail] = useState(user.mail);
        const [mailError, setMailError] = useState(false);

        const [state, setState] = useState(user.state);
        const [stateError, setStateError] = useState(false);

        const [city, setCity] = useState(user.city);
        const [cityError, setCityError] = useState(false);

        const [address, setAddress] = useState(user.address);
        const [addressError, setAddressError] = useState(false);

        const [phone, setPhone] = useState(user.phoneNumber);
        const [phoneError, setPhoneError] = useState(false);



        useEffect(()=>{
            getProjects()

        },[]);
        const getProjects=()=>{
            axios.get(url+'get-projects/'+user._id)
            .then(
                res=>{
                    setProjects(res.data.data[0].projectstList);
                }
            )
            .catch(
                err=>{
                }
            )
        }
        const deleteService=(data)=>{
            var formData={};
            formData.id=data._id;
            Swal.fire({
                title: 'Delete property',
                text:'Are you sure you want to eliminate this property? You can always edit any details',
                confirmButtonText: 'Yes',
                showDenyButton: true,
                denyButtonText:'No'
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire({
                        title: 'Delete property',
                        text:'Your property has been deleted. We will contact you in the next 24 hours.',
                        confirmButtonText: 'Ok',
                    }).then(()=>{
                        axios.put(url+'update-property-delete',formData)
                        .then(
                            res=>{
                                getProjects();
                            }
                        )
                        .catch(
                            err=>{
                                Swal.fire(
                                    {
                                        title: 'Ups',
                                        text:'Something went wrong!',
                                        confirmButtonText: 'Close',
                                        allowOutsideClick: false,
                                        showCloseButton: true
                                    }
                                )
                            }
                        )
                    })
                }else if(result.isDenied){
                    return
                }
            })
           
        }
        const updateUser = ()=>{
            if(name==='' && companyName===''){
                setNameError(true);
            }
            if(address===''){
                setAddressError(true);
            }
            if(city===''){
                setCityError(true);
            }
            if(phone===''){
                setPhoneError(true);
            }
            if(state===''){
                setStateError(true);
            }
            if(mail===''){
                setMailError(true);
            }
            if(mailError || phoneError || nameError || cityError || addressError || stateError){
                return
            }else{
                //arrancar w8 screen
                var formData={};
                formData.name=name;
                formData.address=address;
                formData.mail=mail;
                formData.phone=phone;
                formData.companyName=companyName;
                formData.state=state;
                formData.city=city;
                formData.id=user._id;

                axios.put(url+'update-user',formData)
                .then(
                    res=>{
                        debugger
                        socket.emit('update-users-done')
                        verify();
                        /*setCreateEditProject(false);
                        setSelectedProject(null)*/
                    }
                )
                .catch(
                    err=>{
                        Swal.fire(
                            {
                                title: 'Ups',
                                text:'Something went wrong!',
                                confirmButtonText: 'Close',
                                allowOutsideClick: false,
                                showCloseButton: true
                            }
                        )
                    }
                )
            }
        }
        if(projects===null){
            return(
                <CircularProgress></CircularProgress>
            )
        }
        return(
            <div className="selected-option-container">
                <div className="content-selected profile-view">
                    <div className="container-form-data">
                        <div className="detail-header">
                            <div className="detail-form-title">
                                <div className="detail-title">
                                    My profile
                                </div>
                                <Checkbox
                                    checked={checked}
                                    onChange={(e)=>{ setChecked(e.target.checked)}}
                                ></Checkbox>
                            </div>
                            <div className="detail-status-bar">
                                
                            </div>
                        </div>
                        <div className="form profile-data">
                            <div className="form-content">
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="User Name" 
                                        variant="outlined"
                                        disabled={!checked}
                                        value={name}
                                        onChange={(e)=>{setName(e.target.value);if(nameError)setNameError(false)}}
                                        error={nameError}
                                        helperText={nameError?('This field is required.'):('')}
                                    />
                                </div>

                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Company Address" 
                                        variant="outlined"
                                        disabled={!checked}
                                        required
                                        value={address}
                                        onChange={(e)=>{setAddress(e.target.value);if(addressError)setAddressError(false)}}
                                        error={addressError}
                                        helperText={addressError?('This field is required.'):('')}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="City" 
                                        variant="outlined"
                                        disabled={!checked}
                                        required
                                        value={city}
                                        onChange={(e)=>{setCity(e.target.value);if(cityError)setCityError(false)}}
                                        error={cityError}
                                        helperText={cityError?('This field is required.'):('')}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="State" 
                                        variant="outlined"
                                        disabled={!checked}
                                        required
                                        value={state}
                                        onChange={(e)=>{setState(e.target.value);if(stateError)setStateError(false)}}
                                        error={stateError}
                                        helperText={stateError?('This field is required.'):('')}
                                    />
                                </div>
                            </div>
                            <div className="form-content">
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Company Name" 
                                        variant="outlined"
                                        disabled={!checked}
                                        value={companyName}
                                        onChange={(e)=>{setCompanyName(e.target.value);}}
                                    />
                                </div>

                                <div className="form-group">
                                    <TextField
                                    className="login-input" 
                                        id="outlined-basic" 
                                        label="Mail" 
                                        variant="outlined"
                                        disabled
                                        required
                                        value={mail}
                                        onChange={(e)=>{setMail(e.target.value);if(mailError)setMailError(false)}}
                                        error={mailError}
                                        helperText={mailError?('This field is required.'):('')}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                    className="login-input"
                                        
                                        id="outlined-basic" 
                                        label="Phone number" 
                                        variant="outlined"
                                        disabled={!checked}
                                        required
                                        value={phone}
                                        onChange={(e)=>{setPhone(e.target.value);if(phoneError)setPhoneError(false)}}
                                        error={phoneError}
                                        helperText={phoneError?('This field is required.'):('')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="send-form-container">
                            {
                                checked&&

                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    disabled={!checked}
                                    onClick={()=>{
                                        updateUser()
                                    }}
                                >
                                    Save
                                </Button>
                            }
                        </div>
                        <div className="project-list-container">
                            <div className="project-list-header">
                                <div className="detail-title">
                                    My properties
                                </div>
                                <div className="project-list">

                                    <div className="new project-item" onClick={(e)=>{

                                        e.preventDefault();
                                        setCreateEditProject(true);
                                    }}>
                                       
                                        <div className="btn-new-label">Add new property</div>
                                        <div className="icon">
                                            <span>+</span>
                                        </div>
                                    </div>
                                    {
                                        projects.map((item,i)=>{
                                            return(
                                                <div className="project-item" key={'project-item-key'+i}>
                                                    <div className="delete-icon"
                                                        onClick={(e)=>{e.preventDefault();deleteService(item)}}
                                                    >
                                                        <DeleteIcon
                                                            sx={{color:"white", fontSize:22}}
                                                        ></DeleteIcon>
                                                    </div>
                                                    <div className="project-name"
                                                        onClick={(e)=>{e.preventDefault(); setCreateEditProject(true); setSelectedProject(item)}}
                                                    >
                                                    
                                                        <div className="project-l-m">{item.name}</div>
                                                        <div className="project-l">{item.address}</div>
                                                    </div>
                                                    
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                        </div>
                           
                    </div>
                </div>
            </div>
        )
    }
    const getManagerData=()=>{
        axios.get(url+'get-users')
        .then(
            res=>{
                setUsersList(res.data.data);
            }
        )
        .catch(
            err=>{
            }
        );
        
        axios.get(url+'get-services')
        .then(
            res=>{
                debugger;
                //setServicesList(res.data.data);
                if(servicesList){
                    setServicesList([...res.data.data])
                }else{
                    setServicesList(res.data.data);
                }
            }
        )
        .catch(
            err=>{

            }
        );

        axios.get(url+'get-history')
        .then(
            res=>{
                debugger;
                if(finishedList){
                    setFinishedList([...res.data.data])
                }else{
                    setFinishedList(res.data.data);
                }
            }
        )
        .catch(
            err=>{
            }
        );
        axios.get(url+'get-dashboard')
        .then(
            res=>{
                setDashBoard(res.data.data);
            }
        )
        .catch(
            err=>{
            }
        );
        axios.get(url+'get-request')
        .then(
            res=>{
                setRequestList(res.data.data)
            }
        )
        .catch(
            err=>{
            }
        ) 
        axios.get(url+'get-managers')
        .then(
            res=>{
                setManagersList(res.data.data)
            }
        )
        .catch(
            err=>{
            }
        )
        axios.get(url+'get-sliders')
        .then(
            res=>{
                setSlidersList(res.data.data)
            }
        ).catch(
            err=>{
            }
        )   
    }
    const getManagerDataById=()=>{
        axios.get(url+'get-users-by-managerid/'+user._id)
        .then(
            res=>{
                setUsersList(res.data.data.users);
                setDashBoard(res.data.data.dashBoard);
                setServicesList(res.data.data.currentServices);
                setFinishedList(res.data.data.finishedServices);
                setRequestList(res.data.data.requests);
            }
        )
        .catch(
            err=>{
            }
        );
        axios.get(url+'get-sliders')
        .then(
            res=>{
                setSlidersList(res.data.data)
            }
        ).catch(
            err=>{
            }
        )   
        
    }
    const UsersList = ({data}) =>{
        if(data.length===0){
            return(
                <React.Fragment>
                    <div className="table-header">
                        <div className="table-h-label">Name</div>
                        <div className="table-h-label">Company Address</div>
                        <div className="table-h-label">Phone number</div>
                        <div className="table-h-label">Active</div>
                        <div className="table-h-label"></div>
                    </div>
                    <div className="table-contents">
                        <div className="table-r-label no-services">There is currently no users</div>
                    </div>
                </React.Fragment>
            )
        }
        return(
            <React.Fragment>
               <div className="table-header">
                    <div className="table-h-label">Name</div>
                    <div className="table-h-label">Company Address</div>
                    <div className="table-h-label">Phone number</div>
                    <div className="table-h-label">Active</div>
                    <div className="table-h-label"></div>
                </div>
                <div className="table-content">
                    {
                        data.map((item,index)=>{
                            return(
                                <div className="table-row" key={index}>
                                    <div className="table-r-label">{item.name}</div>
                                    <div className="table-r-label">{item.address}</div>
                                    <div className="table-r-label">{item.phoneNumber}</div>
                                    <div className="table-r-label">{item.active?'Activo':'Inactivo'}</div>
                                    <div className="table-r-label-f">
                                        <div className="link-green" onClick={()=>{setDetailsUsersView(true); setDetailUserData(item)}}>
                                           view profile
                                        </div>
                                       <GoIcon/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </React.Fragment>
        )
    }
    const ManagerList = ({data}) =>{
        if(data.length===0){
            return(
                <React.Fragment>
                    <div className="table-header">
                        <div className="table-h-label">Manager name</div>
                        <div className="table-h-label">Pass</div>
                        <div className="table-h-label">Mail</div>
                        <div className="table-h-label">Active</div>
                        <div className="table-h-label"></div>
                    </div>
                    <div className="table-contents">
                        <div className="table-r-label no-services">There is currently no managers</div>
                    </div>
                </React.Fragment>
            )
        }
        return(
            <React.Fragment>
               <div className="table-header">
                    <div className="table-h-label">Manager name</div>
                    <div className="table-h-label">Pass</div>
                    <div className="table-h-label">Mail</div>
                    <div className="table-h-label">Active</div>
                    <div className="table-h-label"></div>
                </div>
                <div className="table-content">
                    {
                        data.map((item,index)=>{
                            return(
                                <div className="table-row" key={index}>
                                    <div className="table-r-label">{item.name}</div>
                                    <div className="table-r-label">{item.pass}</div>
                                    <div className="table-r-label">{item.mail}</div>
                                    <div className="table-r-label">{item.active?'Activo':'Inactivo'}</div>
                                    <div className="table-r-label-f">
                                        <div className="link-green" onClick={()=>{setDetailsManagersView(true); setDetailsManagerData(item)}}>
                                           view profile
                                        </div>
                                       <GoIcon/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </React.Fragment>
        )
    }
    const ServicesList = ({data}) =>{
        if(data.length===0){
            return(
                <React.Fragment>
                    <div className="table-header">
                        <div className="table-h-label">Property name</div>
                        <div className="table-h-label">Address</div>
                        <div className="table-h-label">Type of service</div>
                        <div className="table-h-label">Status</div>
                        <div className="table-h-label">Creation Date</div>
                        <div className="table-h-label"></div>
                    </div>
                    <div className="table-contents">
                        <div className="table-r-label no-services">There is currently no active services</div>
                    </div>
                </React.Fragment>
            )
        }
        return(
            <React.Fragment>
            <div className="table-header">
                 <div className="table-h-label">Property name</div>
                 <div className="table-h-label">Address</div>
                 <div className="table-h-label">Type of service</div>
                 <div className="table-h-label">Status</div>
                 <div className="table-h-label">Creation Date</div>
                 <div className="table-h-label"></div>
             </div>
             <div className="table-content">
                 {
                     data.map((item,index)=>{
                         return(
                             <div className={item.status==='Pending'?"table-row pending-row":"table-row"} key={index}>
                                 <div className="table-r-label">{item.projectData[0].name}</div>
                                 <div className="table-r-label">{item.projectData[0].address}</div>
                                 <div className="table-r-label">{item.serviceType}</div>
                                 <div className="table-r-label">{item.status}</div>
                                 <div className="table-r-label">{format(new Date(item.creationDate), 'dd/MM/yyyy')}</div>
                                 <div className="table-r-label-f">
                                     <div className="link-green" onClick={()=>{
                                        setDetailServiceManagerView(true);
                                        setServiceDetailManager(item);
                                    }}>
                                        view details
                                     </div>
                                    <GoIcon/>
                                 </div>
                             </div>
                         )
                     })
                 }
             </div>
         </React.Fragment>
        )
    }
    const FinishedManagerList = ({data}) =>{
        if(data.length===0){
            return(
                <React.Fragment>
                    <div className="table-header">
                        <div className="table-h-label">Property name</div>
                        <div className="table-h-label">Address</div>
                        <div className="table-h-label">Type of service</div>
                        <div className="table-h-label">Status</div>
                        <div className="table-h-label">Finished Date</div>
                        <div className="table-h-label"></div>
                    </div>
                    <div className="table-contents">
                        <div className="table-r-label no-services">There are no finalized services</div>
                    </div>
                </React.Fragment>
            )
        }
        return(
            <React.Fragment>
            <div className="table-header">
                 <div className="table-h-label">Property name</div>
                 <div className="table-h-label">Address</div>
                 <div className="table-h-label">Type of service</div>
                 <div className="table-h-label">Status</div>
                 <div className="table-h-label">Finished Date</div>
                 <div className="table-h-label"></div>
             </div>
             <div className="table-content">
                 {
                     data.map((item,index)=>{
                         return(
                             <div className="table-row" key={index}>
                                 <div className="table-r-label">{item.projectData[0].name}</div>
                                 <div className="table-r-label">{item.projectData[0].address}</div>
                                 <div className="table-r-label">{item.serviceType}</div>
                                 <div className="table-r-label">{item.status}</div>
                                 <div className="table-r-label">{format(new Date(item.finishedDate), 'dd/MM/yyyy')}</div>
                                 <div className="table-r-label-f">
                                     <div className="link-green" onClick={()=>{setDetailFinishedManagerView(true);setFinishedDetailManager(item)}}>
                                        view details
                                     </div>
                                    <GoIcon/>
                                 </div>
                             </div>
                         )
                     })
                 }
             </div>
         </React.Fragment>
        )
    }
    const RequestManagerList = ({data}) =>{
        if(data.length===0){
            return(
                <React.Fragment>
                    <div className="table-header">
                        <div className="table-h-label">Type of request</div>
                        <div className="table-h-label">User name</div>
                        <div className="table-h-label">Mail</div>
                        <div className="table-h-label">Date</div>
                        <div className="table-h-label"></div>
                    </div>
                    <div className="table-contents">
                        <div className="table-r-label no-services">There are no active requests</div>
                    </div>
                </React.Fragment>
            )
        }
        return(
            <React.Fragment>
            <div className="table-header">
                 <div className="table-h-label">Type of request</div>
                 <div className="table-h-label">User name</div>
                 <div className="table-h-label">Mail</div>
                 <div className="table-h-label">Date</div>
                 <div className="table-h-label"></div>
             </div>
             <div className="table-content">
                 {
                     data.map((item,index)=>{
                         return(
                             <div className="table-row" key={index}>
                                 <div className="table-r-label">{item.requestType}</div>
                                 <div className="table-r-label">{item.userData[0].name}</div>
                                 <div className="table-r-label">{item.userData[0].mail}</div>
                                 <div className="table-r-label">{format(new Date(item.creationDate), 'dd/MM/yyyy')}</div>
                                 <div className="table-r-label-f">
                                    {
                                        item.requestType==='W9'?(
                                            <React.Fragment>

                                                <div className="link-green" onClick={()=>{updateRequestW9(item)}}>
                                                    upload W9
                                                </div>
                                                <GoIcon/>
                                            </React.Fragment>
                                        ):(
                                            <React.Fragment>

                                                <div className="link-green" onClick={()=>{setCoiModal(true); setCoiData(item)}}>
                                                    upload file
                                                </div>
                                                <GoIcon/>
                                            </React.Fragment>
                                        )
                                    }
                                    
                                 </div>
                             </div>
                         )
                     })
                 }
             </div>
         </React.Fragment>
        )
    }
    /**Filters de manager */
    const filterUsers =(filter)=>{
        if(filter===''){
             setUsersFilteredList(null);
        }else{
             var newUsersList=usersList.filter((item)=>{
                 return item.name.toLowerCase().search(filter.toLowerCase())!==-1
             });
             setUsersFilteredList(newUsersList);
         }
        
    }
    const filterServicesList =(filter)=>{
      
        if(filter===''){
             setServicesFilteredList(null);
        }else{
             var newUsersList=servicesList.filter((item)=>{
                return item.projectData[0].name.toLowerCase().search(filter.toLowerCase())!==-1
             });
             setServicesFilteredList(newUsersList);
         }
        
    }
    const filterFinishedList =(filter)=>{
        if(filter===''){
            setFinishedFilteredList(null);
        }else{
            var newUsersList=finishedList.filter((item)=>{
                return item.projectData[0].name.toLowerCase().search(filter.toLowerCase())!==-1
            });
            setFinishedFilteredList(newUsersList);
         }
        
    }
    const filterRequestList =(filter)=>{
        if(filter===''){
            setRequestFilteredList(null);
        }else{
            var newUsersList=requestList.filter((item)=>{
                return item.userData[0].mail.toLowerCase().search(filter.toLowerCase())!==-1
            });
            setRequestFilteredList(newUsersList);
         }
        
    }
    /**SOrts de manager */
    const sortSwitcherUsersServices=(filter)=>{
        var sortArray=servicesFilteredList?servicesFilteredList:servicesList;
        switch (filter) {
            case 0:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.serviceType===b.serviceType) return 0
                    return a.serviceType.localeCompare(b.serviceType);
                });
                setServicesFilteredList(newArray);
            break;
            case 1:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.projectData[0].name===b.projectData[0].name) return 0
                    return a.projectData[0].name.localeCompare(b.projectData[0].name,undefined,{numeric:true});
                });
                setServicesFilteredList(newArray);
            break;
            case 2:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.status===b.status) return 0
                    return a.status.localeCompare(b.status);
                });
                setServicesFilteredList(newArray);
            break;
            case 3:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.creationDate===b.creationDate) return 0
                    return a.creationDate.localeCompare(b.creationDate);
                });
                setServicesFilteredList(newArray);
            break;
        
            default:
                break;
        }
    }
    const sortSwitcherUsersHistory=(filter)=>{
        var sortArray=finishedFilteredList?finishedFilteredList:finishedList;
        switch (filter) {
            case 0:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.serviceType===b.serviceType) return 0
                    return a.serviceType.localeCompare(b.serviceType);
                });
                setFinishedFilteredList(newArray);
            break;
            case 1:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.projectData[0].name===b.projectData[0].name) return 0
                    return a.projectData[0].name.localeCompare(b.projectData[0].name,undefined,{numeric:true});
                });
                setFinishedFilteredList(newArray);
            break;
            case 2:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.status===b.status) return 0
                    return a.status.localeCompare(b.status);
                });
                setFinishedFilteredList(newArray);
            break;
            case 3:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.creationDate===b.creationDate) return 0
                    return a.creationDate.localeCompare(b.creationDate);
                });
                setFinishedFilteredList(newArray);
            break;
        
            default:
                break;
        }
    }
    const sortSwitcherRequest=(filter)=>{
        var sortArray=requestFilteredList?requestFilteredList:requestList;
        switch (filter) {
            case 0:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.requestType===b.requestType) return 0
                    return a.requestType.localeCompare(b.requestType);
                });
                setRequestFilteredList(newArray);
            break;
            case 1:
                var newArray=sortArray.sort((a,b)=>{
                    if(a.creationDate===b.creationDate) return 0
                    return a.creationDate.localeCompare(b.creationDate);
                });
                setRequestFilteredList(newArray);
            break;
        
            default:
                break;
        }
    }
    const FormPropertyDetails = ()=>{
        const [date, setDate] = useState(new Date());

        const [contact, setContact] = useState(selectedUserProject.contact);
        const [contactError, setContactError] = useState(false);

        const [contactPhone, setContactPhone] = useState(selectedUserProject.contact_number);
        const [contactPhoneError, setContactPhoneError] = useState(false);

        const [projectName, setProjectName] = useState(selectedUserProject.name);
        const [projectError, setProjectError] = useState(false);

        const [projectAddress, setProjectAddress] = useState(selectedUserProject.address);
        const [addressError, setAddressError] = useState(false);

        const [projectTypeError, setProjectTypeError] = useState(false);
        const [roofType, setRoofType] = React.useState(selectedUserProject.type);
        const [roofTypes, setRoofTypes] = useState([]);

        const [projectAccessError, setProjectAccessError] = useState(false);
        const [roofAccess, setRoofAccess] = React.useState(selectedUserProject.access);
        const [roofAccesss, setRoofAccesss] = useState([]);

        const [note, setNote] = useState(selectedUserProject.note);

        const [checked, setChecked] = useState(false);


        const [currentService, setCurrentService]= useState(null);
        const [lastService, setLastService]= useState(null);

        useEffect(()=>{
            debugger;
            setRoofTypes(Global.roof_types);
            setRoofAccesss(Global.roof_access);
            setRoofServiceM(Global.services[0]);
            setRoofServicesM(Global.services);
            setRoofAccesss(Global.roof_access);
            getCurrentServices();
            getHistoryServices();

        },[])

        const getCurrentServices = ()=>{
            axios.get(url+'get-active-service-project/'+selectedUserProject._id)
            .then(
                res =>{
                    if(res.data.data[0]!==undefined){
                        debugger;
                        setCurrentService(res.data.data[(res.data.data.length-1)])
                    }
                   
                }
            ).catch(
                err=>{

                }
            )
        }
        const getHistoryServices = ()=>{
             axios.get(url+'get-finished-services-project-id/'+selectedUserProject._id)
            .then(
                res =>{
                    ;
                    if(res.data.data!==undefined){
                        setLastService(res.data.data);
                    }
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        const updateProject = ()=>{
            if(projectName===''){
                setProjectError(true);
            }
            if(projectAddress===''){
                setAddressError(true);
            }
            if(roofType===''){
                setProjectTypeError(true);
            }
            if(roofAccess===''){
                setProjectAccessError(true);
            }
            if(projectError || projectTypeError || addressError || projectAccessError){
                return
            }else{
                //arrancar w8 screen
                var formData={};
                formData.name=projectName;
                formData.address=projectAddress;
                formData.access=roofAccess.label===undefined?roofAccess:roofAccess.label;
                formData.type=roofType.label===undefined?roofType:roofType.label;
                formData.contact=contact;
                formData.contact_number=contactPhone;
                formData.note=note;
                formData.id=selectedUserProject._id;
                formData.userChange=user.name;
                debugger;
                axios.put(url+'update-project',formData)
                .then(
                    res=>{
                        setSelectedUserViewProject(false);
                        setSelectedUserProject(null);
                        if(user.manager){
                            getManagerDataById();
                        }else{
    
                            getManagerData()
                        }
                    }
                )
                .catch(
                    err=>{
                        console.log(err)
                    }
                )
            }
        }
        return(
            <div className="details-form-container">
                <div className="back-btn-container">
                    <div className="back-btn-tf" onClick={()=>{
                        setSelectedUserViewProject(false);
                        setSelectedUserProject(null);
                    }}>
                        <BackIcon/>
                    </div>
                </div>
                <div className="container-left-details create-project">
                    <div className="container-form-data">
                        <div className="detail-header">
                            <div className="detail-form-title">
                                <div className="detail-title">
                                    Edit property
                                </div>
                                <Checkbox
                                    checked={checked}
                                    onChange={(e)=>{ setChecked(e.target.checked)}}
                                ></Checkbox>
                            </div>
                            <div className="detail-status-bar">
                                
                            </div>
                        </div>
                        <div className="form">
                            <div className="form-content">
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Property name" 
                                        variant="outlined"
                                        value={projectName}
                                        required
                                        error={projectError}
                                        helperText={projectError?('This field is required.'):('')}
                                        onChange={(e)=>{setProjectName(e.target.value);if(projectError)setProjectError(false)}}
                                        disabled={!checked}
                                    />
                                </div>
                              
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Property address" 
                                        variant="outlined"
                                        required
                                        value={projectAddress}
                                        error={addressError}
                                        helperText={addressError?('This field is required.'):('')}
                                        onChange={(e)=>{setProjectAddress(e.target.value);if(addressError)setAddressError(false)}}
                                        disabled={!checked}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Onsite contact name" 
                                        variant="outlined"
                                        value={contact}
                                        onChange={(e)=>{setContact(e.target.value);}}
                                        disabled={!checked}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Onsite contact phone" 
                                        variant="outlined"
                                        value={contactPhone}
                                        onChange={(e)=>{setContactPhone(e.target.value);}}
                                        disabled={!checked}
                                    />
                                </div>
                                
                            </div>
                            <div className="form-content">
                                <div className="form-group">
                                    <Autocomplete
                                        className="autocomplete-full" 
                                        options={roofTypes}
                                        value={roofType}
                                        disabled={!checked}
                                        onChange={(event, newValue) => {
                                            console.log(newValue)
                                            setRoofType(newValue===null?'':newValue.label);
                                            if(projectTypeError)setProjectTypeError(false);
                                        }}
                                        renderInput={(params)=> <TextField 
                                            {...params} 
                                            label="Roof type" 
                                            className="login-input" 
                                            id="outlined-basic" 
                                            variant="outlined" 
                                            required 
                                            error={projectTypeError}
                                            helperText={projectTypeError?('This field is required.'):('')}
                                            >
                                            </TextField>
                                        }
                                    />
                                    
                                </div>
                                <div className="form-group">
                                    <Autocomplete
                                        className="autocomplete-full" 
                                        options={roofAccesss}
                                        value={roofAccess}
                                        disabled={!checked}
                                        onChange={(event, newValue) => {
                                            console.log(newValue)
                                            setRoofAccess(newValue===null?'':newValue.label);
                                            if(projectAccessError)setProjectAccessError(false);
                                        }}
                                        renderInput={(params)=> <TextField 
                                            {...params} 
                                            label="Roof access" 
                                            className="login-input" 
                                            id="outlined-basic" 
                                            variant="outlined" 
                                            required 
                                            error={projectAccessError}
                                            helperText={projectAccessError?('This field is required.'):('')}
                                            >
                                            </TextField>
                                        }
                                    />
                                    
                                    
                                </div>
                                <div className="form-group multiline-form">
                                    <TextField
                                        className="login-input multiline" 
                                        multiline
                                        maxRows={2}
                                        id="outlined-basic" 
                                        label="Note" 
                                        variant="outlined"
                                        onChange={(e)=>{setNote(e.target.value)}}
                                        value={note}
                                        disabled={!checked}
                                    />
                                </div>
                            
                            </div>
                        </div>
                        <div className="send-form-container">
                            {
                                checked&&

                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    disabled={!checked}
                                    onClick={()=>{
                                        updateProject()
                                    }}
                                >
                                    Save
                                </Button>
                            }
                        </div>
                        <div className="services-container-detail">
                            <div className="last-a-container">
                                <div className="header-and-btn">

                                    <div className="main-b-title">Active service</div>
                                    <div className="btn-create-service" onClick={(e)=>{debugger; e.preventDefault();setNewServiceModal(true)}}>
                                        <div className="icon">+</div>
                                    </div>
                                </div>
                                {
                                    currentService!==null?(
                                        <div className="last-activity-finished">
                                            <div className="table-header">
                                                <div className="table-h-label">Creation date</div>
                                                <div className="table-h-label">Schedule date</div>
                                                <div className="table-h-label">Type of service</div>
                                                <div className="table-h-label">Status</div>
                                                <div className="table-h-label"></div>
                                            </div>
                                            <div className="table-content">
                                                <div className="table-row">

                                                    <div className="table-r-label">{format(new Date(currentService.creationDate), 'dd/MM/yyyy')}</div>
                                                    <div className="table-r-label">{format(new Date(currentService.startingDate), 'dd/MM/yyyy')}</div>
                                                    <div className="table-r-label">{currentService.serviceType}</div>
                                                    <div className="table-r-label">{currentService.status}</div>
                                                    <div className="table-r-label-f">
                                                        <div className="link-green" onClick={()=>{setEditDetailServiceUserView(true); setServiceDetailUserProject(currentService)}}>
                                                            view details
                                                        </div>
                                                    <GoIcon/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ):(
                                        <div className="table-r-label no-service">There's not active service</div>
                                        //request service button
                                    )
                                }
                               
                            </div> 
                            <div className="last-f-container">
                                <div className="main-b-title">Completed services</div>
                                {

                                    lastService!==null?(
                                            <div className="last-activity-finished">
                                                {
                                                    lastService.length>=1&&

                                                    <div className="table-header">
                                                        <div className="table-h-label">Scheduled date</div>
                                                        <div className="table-h-label">Finished date</div>
                                                        <div className="table-h-label">Type of service</div>
                                                        <div className="table-h-label">Status</div>
                                                        <div className="table-h-label"></div>
                                                    </div>

                                                }
                                                <div className="table-content">
                                                    {
                                                        lastService.length===0?(
                                                            <div className="table-r-label no-service">There's not completed service</div>
                                                        ):(

                                                            lastService.map((item,i)=>(
                                                                <div className="table-row" key={'Table-history-row-'+i}>
                                                                    <div className="table-r-label">{format(new Date(item.startingDate), 'dd/MM/yyyy')}</div>
                                                                    <div className="table-r-label">{format(new Date(item.finishedDate), 'dd/MM/yyyy')}</div>
                                                                    <div className="table-r-label">{item.serviceType}</div>
                                                                    <div className="table-r-label">{item.status}</div>
                                                                    <div className="table-r-label-f">
                                                                        <div className="link-green" onClick={()=>{setEditDetailServiceUserView(true); setServiceDetailUserProject(item)}}>
                                                                        view details
                                                                        </div>
                                                                    <GoIcon/>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        )
                                                    }
                                                    {
                                                        
                                                    }
                                                   
                                                </div>
                                            </div>
                                        ):(
                                            <div className="table-r-label no-service">There's not completed service</div>
                                            //request service button
                                        )
                                }
                            </div>
                            {
                          
                                selectedUserProject.log&&
                                <div className="log-container">
                                    <div className="detail-form-title">
                                        <div className="main-b-title">
                                            Property changes log
                                        </div>
                                    </div>
                                    <div className="changes-log-list">
                                        {
                                            selectedUserProject.log.map((item,i)=>(

                                                <div key={'log-content-'+i} className='log-content'>
                                                    <div className="log-date">{format(new Date(item.date), 'dd/MM/yyyy')}</div>
                                                    <div className="log-user">User: {item.user}</div>
                                                    <div className="log-data">Change: {item.data}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
        
    }
    const UserAccountView = ()=>{
        /**AQUI */
        const [projects, setProjects] = useState(null);
    

        useEffect(()=>{
            getProjects()
            setRoofTypesM(Global.roof_types);
            setRoofTypeM(Global.roof_types[0]);
            setRoofAccesssM(Global.roof_access);
            setRoofAccessM(Global.roof_access[0]);
            setRoofServicesM(Global.services);
            setRoofServiceM(Global.services[0]);

        },[]);
        const deleteServiceManager=(data)=>{
            var formData={};
            formData.id=data._id;
            Swal.fire({
                title: 'Delete property',
                text:'Are you sure you want to eliminate this property? You can always edit any details',
                confirmButtonText: 'Yes',
                showDenyButton: true,
                denyButtonText:'No'
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire({
                        title: 'Delete property',
                        text:'Your property has been deleted. We will contact you in the next 24 hours.',
                        confirmButtonText: 'Ok',
                    }).then(()=>{
                        axios.put(url+'update-property-delete',formData)
                        .then(
                            res=>{
                                if(user.manager){
                                    getManagerDataById();
                                }else{
                                    getManagerData()
                                }
                            }
                        )
                        .catch(
                            err=>{
                                Swal.fire(
                                    {
                                        title: 'Ups',
                                        text:'Something went wrong!',
                                        confirmButtonText: 'Close',
                                        allowOutsideClick: false,
                                        showCloseButton: true
                                    }
                                )
                            }
                        )
                    })
                }else if(result.isDenied){
                    return
                }
            })
           
        }
        const getProjects=()=>{
            axios.get(url+'get-projects/'+detailUserData._id)
            .then(
                res=>{
                    setProjects(res.data.data[0].projectstList);
                }
            )
            .catch(
                err=>{
                }
            )
        }
        const deleteUser = () => {
            Swal.fire({
                title: 'Delete user',
                text:'Are you sure you want to eliminate this user? You can always edit any details',
                confirmButtonText: 'Yes',
                showDenyButton: true,
                denyButtonText:'No'
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire({
                        title: 'Delete user',
                        text:'The user has been deleted.',
                        confirmButtonText: 'Ok',
                    }).then(()=>{
                        axios.delete(url+'delete-user/'+detailUserData._id)
                        .then(
                            res=>{
                                debugger;
                                if(user.manager){
                                    getManagerDataById();
                                }else{
                                    getManagerData();
                                }
                                setDetailUserData(null);
                                setDetailsUsersView(false);
                            }
                        )
                        .catch(
                            err=>{
                                Swal.fire(
                                    {
                                        title: 'Ups',
                                        text:'Something went wrong!',
                                        confirmButtonText: 'Close',
                                        allowOutsideClick: false,
                                        showCloseButton: true
                                    }
                                )
                            }
                        )
                    })
                }else if(result.isDenied){
                    return
                }
            });
        }

        if(projects===null){
            return(
                <CircularProgress></CircularProgress>
            )
        }
        return(
            <div className="selected-option-container">
                 <div className="back-btn-container manager-p">
                        <div className="back-btn-tf" onClick={()=>{
                            setDetailUserData(null);
                            setDetailsUsersView(false);
                        }}>
                            <BackIcon/>
                        </div>
                    </div>
                <div className="content-selected profile-view">
                    <div className="container-form-data">
                        <div className="detail-header">
                            <div className="detail-form-title">
                                <div className="detail-title">
                                    {detailUserData.name+''}'s profile
                                </div>
                            </div>
                            <div className="detail-status-bar">
                                {
                                    user.superManager && (
                                        <div className="btn-cancel">
                                            <Button 
                                                className="cancelBtn" 
                                                variant="contained"
                                                onClick={()=>{
                                                    deleteUser()
                                                }}
                                            >
                                                Delete user
                                            </Button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="form profile-data">
                            <div className="form-content">
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Name" 
                                        variant="outlined"
                                        
                                        value={detailUserData.name}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="City" 
                                        variant="outlined"
                                        
                                        value={detailUserData.city}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                    className="login-input" 
                                        id="outlined-basic" 
                                        label="Mail" 
                                        variant="outlined"
                                        
                                        value={detailUserData.mail}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                    className="login-input"
                                        
                                        id="outlined-basic" 
                                        label="Phone number" 
                                        variant="outlined"
                                        
                                        value={detailUserData.phoneNumber}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="project-list-container">
                            <div className="project-list-header">
                                <div className="detail-title">
                                    Properties
                                </div>
                                
                                <div className="project-list">
                                <div className="new project-item" onClick={(e)=>{
                                    e.preventDefault();
                                    setNewPropertyModal(true)
                                }}>

                                    <div className="btn-new-label">Add new property</div>
                                    <div className="icon">
                                        <span>+</span>
                                    </div>
                                </div>
                                    {
                                        projects.map((item,i)=>{
                                            return(
                                                <div className="project-item" key={'project-item-key'+i}>
                                                     <div className="delete-icon"
                                                        onClick={(e)=>{e.preventDefault();deleteServiceManager(item)}}
                                                    >
                                                        <DeleteIcon
                                                            sx={{color:"white", fontSize:22}}
                                                        ></DeleteIcon>
                                                    </div>
                                                    <div className="project-name" onClick={(e)=>{e.preventDefault(); setSelectedUserViewProject(true); setSelectedUserProject(item)}}>
                                                        <div className="project-l-m">{item.name}</div>
                                                        <div className="project-l">{item.address}</div>    
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                        </div>
                        {
                          
                            detailUserData.log?.length!==0&&
                            <div className="log-container">
                                <div className="detail-form-title">
                                    <div className="detail-title">
                                        User changes log
                                    </div>
                                </div>
                                <div className="changes-log-list">
                                    {
                                        detailUserData.log.map((item,i)=>(

                                            <div key={'log-content-'+i} className='log-content'>
                                                <div className="log-date">{format(new Date(item.date), 'dd/MM/yyyy')}</div>
                                                <div className="log-user">User: {item.user}</div>
                                                <div className="log-data">Change: {item.data}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            
                        }
                        
                    </div>
                </div>
            </div>
        )
    }
    const ManagerAccountView = ()=>{
        const [users, setUsers] = useState(null);
        const [unAssignedUsers, setUnAssignedUsers] = useState(null);
        const [pickedUser, setPickedUser]= useState(null);

        const [mail, setMail] = useState(detailManagerData.mail);
        const [mailError, setMailError] = useState(false);

        const [name, setName] = useState(detailManagerData.name);
        const [nameError, setNameError] = useState(false);

        const [pass, setPass] = useState(detailManagerData.pass);
        const [passError, setPassError] = useState(false);


        const [checked, setChecked] = useState(false);
        useEffect(()=>{
            getUsers()

        },[]);
        const getUsers=()=>{
            axios.get(url+'get-users-manager/'+detailManagerData._id)
            .then(
                res=>{
                    setUsers(res.data.data);
                }
            )
            .catch(
                err=>{
                }
            )
            axios.get(url+'get-unassigned-users')
            .then(
                res=>{
                    debugger;
                    setUnAssignedUsers(res.data.data);
                }
            )
            .catch(
                err=>{
                }
            )
        }
        const assignUserManager =()=>{
            var formData= {};
            formData.managerId=detailManagerData._id;
            formData.id=pickedUser._id;
            axios.put(url+'user-assing-manager', formData)
            .then(
                res=>{
                    debugger;
                    setAssignModal(false);
                    Swal.fire({
                        title: 'User assigned!',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        showCloseButton: true}
                    ).then(()=>{
                        if(user.manager){

                            getManagerDataById();
                        }else{
    
                            getManagerData()
                        }
                        setPickedUser(null);
                    })
                }
            )
            .catch(
                err=>{
                    setAssignModal(false);
                    Swal.fire(
                        {
                            title: 'Ups',
                            text:'Something went wrong!',
                            confirmButtonText: 'Close',
                            allowOutsideClick: false,
                            showCloseButton: true
                        }
                    )
                }
            )
        }
        const unAassignUserManager =(data)=>{
            var formData= {};
            formData.id=data._id;
            debugger;
            Swal.fire({
                title: 'Unassign user',
                text:'Are you sure you want to unassign this user?',
                confirmButtonText: 'Yes',
                showDenyButton: true,
                denyButtonText:'No'
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire({
                        title: 'Unassign user',
                        text:'Procces succed',
                        confirmButtonText: 'Ok',
                    }).then(()=>{
                        axios.put(url+'user-unassing-manager',formData)
                        .then(
                            res=>{
                                getUsers();
                            }
                        )
                        .catch(
                            err=>{
                                Swal.fire(
                                    {
                                        title: 'Ups',
                                        text:'Something went wrong!',
                                        confirmButtonText: 'Close',
                                        allowOutsideClick: false,
                                        showCloseButton: true
                                    }
                                )
                            }
                        )
                    })
                }else if(result.isDenied){
                    return
                }
            })
        }
        const updateManager =()=>{
            if(name===''){
                setNameError(true);
            }
            if(pass===''){
                setPassError(true);
            }
            if(mail===''){
                setMailError(true);
            }
            if(mailError || passError || nameError){
                return
            }else{
                //arrancar w8 screen
                var formData={};
                formData.name=name;
                formData.pass=pass;
                formData.mail=mail;
                formData.id=detailManagerData._id;

                axios.put(url+'update-user-manager',formData)
                .then(
                    res=>{
                        debugger
                        setDetailsManagerData(res.data.data)
                        if(user.manager){
                            getManagerDataById();
                        }else{
    
                            getManagerData()
                        }
                        /*setCreateEditProject(false);
                        setSelectedProject(null)*/
                    }
                )
                .catch(
                    err=>{
                        Swal.fire(
                            {
                                title: 'Ups',
                                text:'Something went wrong!',
                                confirmButtonText: 'Close',
                                allowOutsideClick: false,
                                showCloseButton: true
                            }
                        )
                    }
                )
            }
        }
        if(users===null){
            return(
                <CircularProgress></CircularProgress>
            )
        }
        return(
            <div className="selected-option-container">
                 <div className="back-btn-container manager-p">
                        <div className="back-btn-tf" onClick={()=>{
                            setDetailsManagerData(null);
                            setDetailsManagersView(false);
                        }}>
                            <BackIcon/>
                        </div>
                    </div>
                <div className="content-selected profile-view">
                    <div className="container-form-data">
                        <div className="detail-header">
                            <div className="detail-form-title">
                                <div className="detail-title">
                                    {detailManagerData.name+' '}profile
                                </div>
                            </div>

                            <Checkbox
                                checked={checked}
                                onChange={(e)=>{ setChecked(e.target.checked)}}
                            ></Checkbox>
                            <div className="detail-status-bar">
                                
                            </div>
                        </div>
                        <div className="form profile-data">
                            <div className="form-content">
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Name" 
                                        variant="outlined"
                                        disabled={!checked}
                                        value={name}
                                        required
                                        error={nameError}
                                        helperText={nameError?('This field is required.'):('')}
                                        onChange={(e)=>{setName(e.target.value);if(nameError)setNameError(false)}}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        className="login-input" 
                                        id="outlined-basic" 
                                        label="Pass" 
                                        variant="outlined"
                                        disabled={!checked}
                                        value={pass}
                                        required
                                        error={passError}
                                        helperText={passError?('This field is required.'):('')}
                                        onChange={(e)=>{setPass(e.target.value);if(passError)setPassError(false)}}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                    className="login-input" 
                                        id="outlined-basic" 
                                        label="Mail" 
                                        variant="outlined"
                                        disabled={!checked}
                                        value={mail}
                                        required
                                        error={mailError}
                                        helperText={mailError?('This field is required.'):('')}
                                        onChange={(e)=>{setMail(e.target.value);if(mailError)setMailError(false)}}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                    className="login-input"
                                        
                                        id="outlined-basic" 
                                        label="Active" 
                                        variant="outlined"
                                        disabled
                                        value={detailManagerData.active}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="send-form-container">
                            {
                                checked&&

                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    disabled={!checked}
                                    onClick={()=>{
                                        updateManager()
                                    }}
                                >
                                    Save
                                </Button>
                            }
                        </div>
                        <div className="project-list-container">
                            <div className="project-list-header">
                                <div className="detail-title">
                                    Users assigned
                                </div>
                                <div className="project-list">

                                <div className="new project-item" onClick={(e)=>{
                                        e.preventDefault();
                                        setAssignModal(true);
                                    }}>
                                    <div className="icon">
                                        <span>+</span>
                                    </div>
                                </div>
                                    {
                                        /**Users */
                                        users.map((item,i)=>{
                                            return(
                                                <div className="project-item" key={'project-item-key'+i} onClick={(e)=>{e.preventDefault()}}>
                                                     <div className="delete-icon"
                                                        onClick={(e)=>{e.preventDefault();unAassignUserManager(item)}}
                                                    >
                                                        <DeleteIcon
                                                            sx={{color:"white", fontSize:22}}
                                                        ></DeleteIcon>
                                                    </div>
                                                    <div className="project-name">
                                                        <div className="project-l-m">{item.name}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                        </div>
                           
                    </div>
                </div>
                    <Modal
                        open={assignModal}
                        onClose={(e)=>{e.preventDefault(); setAssignModal(false)}}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                        >
                        <Box sx={{width:400, height:450, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                            <div className="back-btn-modal">
                                <div className="back-btn-tf" onClick={(e)=>{
                                    e.preventDefault();
                                    setAssignModal(false);
                                }}>
                                    <BackIcon/>
                                </div>
                            </div>
                            <form className="form">
                                <div className="form-content">
                                    <React.Fragment>
                                        <div className='table-r-label'>Select an user to be assigned:</div>
                                        {
                                            unAssignedUsers!==null?(
                                                <div className='projects-slider'>
                                                    {
                                                        unAssignedUsers.length===0?(
                                                            <div className='projects-slider'>
                                                                <div>There is currently no users unassigned</div>
                                                            </div>
                                                        ):(
                                                            
                                                            unAssignedUsers.map((item,i)=>{
                                                                return(
                                                                    <div className={pickedUser!==null?pickedUser._id===item._id?'project-card picked':'project-card':'project-card'}  key={'project-detail-'+i} onClick={(e)=>{e.preventDefault(); setPickedUser(item)}}>
                                                                        <div className='project-label'>{item.name}</div>
                                                                    </div>
                                                                )
                                                            })
                                                        )
                                                    }
                                                </div>
                                            ):(
                                                <div className='projects-slider'>
                                                    <CircularProgress/>
                                                </div>
                                            )
                                        }
                                    </React.Fragment>
                                </div>
                            </form>  
                            <Button
                                className="cancelBtn" 
                                variant="contained"
                                disabled={pickedUser===null}
                                onClick={()=>{
                                    assignUserManager();
                                }}
                            >
                               Assign
                            </Button>
                        </Box>

                    </Modal>
            </div>
        )
    }
    const FormDetailServiceUser = ()=>{
        const [date, setDate] = useState(new Date());
        const [projectImages, setProjectImages] = useState(null);
        const [newMessage, setNewMessage] = useState('');
        const [chat, setChat] = useState([]);
        const [status, setStatus] = useState(null);
        const [downloadGallery, setDownloadGallery] = useState(null);
        const [prodDisabled, setProdDisabled] =useState(false);

        const [statusDb, setStatusDb] = useState(null);
        const [companyCams, setCompanyCams]= useState('');
        useEffect(()=>{
            setDate(serviceDetailUserProject.startingDate);
            getChat(serviceDetailUserProject._id);
            switch (serviceDetailUserProject.status) {
                case 'Pending':
                    setStatus(0);
                    setStatusDb(0);
                break;
                case 'Canceled':
                    setStatus(0);
                    setStatusDb(0);
                break;
                case 'Scheduled':
                    setStatus(25);
                    setStatusDb(25);
                break;
                case 'Proposal':
                    setStatus(50);
                    setStatusDb(50);
                break;
                case 'Working on it':
                    setStatus(75);
                    setStatusDb(75);
                break;
                case 'Finished':
                    setStatus(100);
                    setStatusDb(100);
                break;
                default:
                    break;
            }
            if(serviceDetailUserProject.companyCams){
                getImages(serviceDetailUserProject.companyCams);
            }
            if(serviceDetailUserProject.status==='Working on it' && !user.superManager || serviceDetailUserProject.status==='Finished' && !user.superManager){
                setProdDisabled(true);
            }
       
        },[]);
        useEffect(()=>{
            socket.on("update-messages-"+serviceDetailUserProject._id,(lastMessage)=>{
               if(lastMessage.userId===user._id){

                    setChat([...chat]);
                }else{

                    setChat([...chat, lastMessage]);
                }
            });
            return()=>{
                socket.off("update-messages-"+serviceDetailUserProject._id)
            }
        })
        const getChat=(id)=>{
            axios.get(url+'get-service-chat/'+id)
            .then(
                res=>{
                        setChat(res.data.data)
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        const getImages=(id)=>{
            axios.get(url+'get-project-images/'+serviceDetailUserProject._id+'/'+id)
            .then(
                res=>{
                    var galleryDownload=[];
                    var itemsPushed=[];
                    var finalItems= [];
                    res.data.data.map((item,i)=>{
                        
                        galleryDownload.push(item.uris[3].url)
                        var ii=i+1;
                        if(ii % 4 === 0){
                            itemsPushed.push(item);
                            finalItems.push(itemsPushed);
                            itemsPushed= [];
                        }else{
                            itemsPushed.push(item);
                            if(res.data.data.length===ii){
                                finalItems.push(itemsPushed);
                            }
                        }
                        
                    });
                    setProjectImages(finalItems);
                    setDownloadGallery(galleryDownload);
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        const updateService=async()=>{
            const formData={};
               const newChat={date:new Date(),message:newMessage,user:user.name, userId:user._id};
            var chatArray=chat;
            chatArray.push(newChat);
            formData.chat=chatArray;
            formData.id=serviceDetailUserProject._id;
            await axios.put(url+'update-service',formData)
            .then(
                res =>{
                    socket.emit('new-message-manager',{id:res.data.data[0]._id,chat:newChat});
                    setNewMessage('');
                }
            )
            .catch(
                err=>{

                }
            )
        }
        const updateServiceCancel=async()=>{
            const formData={};
            formData.deleted=true;
            formData.id=serviceDetailUserProject._id;
            Swal.fire({
                title: 'Cancel service',
                text:'We will contact you after you cancel the service, Are you sure to continue?',
                confirmButtonText: 'Yes',
                showDenyButton: true,
                denyButtonText:'No'
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire({
                        title: 'Cancel service',
                        text:'Your service has been cancelled. We will contact you in the next 24 hours.',
                        confirmButtonText: 'Ok',
                    }).then(()=>{
                        setEditDetailServiceUserView(false);
                        setServiceDetailUserProject(null);
                        axios.put(url+'update-service-cancel',formData)
                        .then(
                            res =>{
                                if(user.manager){

                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                            }
                        )
                        .catch(
                            err=>{

                            }
                        )
                    })
                }else if(result.isDenied){

                }
            })
        }
        const updateCams=()=>{
            var formData={};
            formData.id=serviceDetailUserProject._id;
            formData.companyCams=companyCams;
            axios.put(url+'update-service-cams',formData)
            .then(
                res =>{
                    setServiceDetailManager(res.data.data[0]);
                    getImages(res.data.data[0].companyCams);
                    socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                }
            )
            .catch(
                err=>{

                }
            )
        }
        const updateServiceScheduled=async(option)=>{

            const formData={};
            formData.startingDate=date;
            formData.id=serviceDetailUserProject._id;
            switch (option) {
                case 0:
                    await axios.put(url+'update-service-state-scheduled',formData)
                    .then(
                        res =>{
                            setServiceDetailUserProject(res.data.data[0]);
                            socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                            Swal.fire({
                                title: 'Service status changed',
                                text:'This service has been scheduled. Remember to constantly update the progress of the project.',
                                confirmButtonText: 'Ok',
                            }).then(()=>{
                            })
                        }
                    )
                    .catch(
                        err=>{
        
                        }
                    )
                    break;
                case 1:
                    await axios.put(url+'update-service-state-working',formData)
                    .then(
                        res =>{
                            setServiceDetailUserProject(res.data.data[0]);
                            socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                            Swal.fire({
                                title: 'Service status changed',
                                text:'This service has been set to Working on it. Remember to constantly update the progress of the project.',
                                confirmButtonText: 'Ok',
                            }).then(()=>{
                            })
                        }
                    )
                    .catch(
                        err=>{
        
                        }
                    )
                    break;
                case 2:
                    debugger;
                    var isCanceled=false;
                    const { value: file } = await Swal.fire({
                        title: 'Upload an invoice',
                        text:'To finish this service firstable upload the service invoice & the picture report.',
                        input: 'file',
                        className:'file-pop',
                        showCloseButton: true,
                        inputAttributes: {
                          'accept': 'application/pdf',
                          'aria-label': 'Upload file'
                        }
                    })
                    if(file===undefined || file===null){
                        Swal.fire({
                            title: 'Procces canceled',
                            text:'You must upload an invoice file to finished the service',
                            confirmButtonText: 'Ok',
                        }).then(()=>{
                        })
                    }else{
                        const data= new FormData();
                        data.append('file',file);
                        data.append('id',serviceDetailUserProject._id);
                        await axios.put(url+'update-service-upload-invoice',data)
                        .then(
                            async res =>{
                                const { value: file2 } = await Swal.fire({
                                    title: 'Upload an picture report',
                                    text:'Now select the picture report.',
                                    input: 'file',
                                    className:'file-pop',
                                    showCloseButton: true,
                                    inputAttributes: {
                                      'accept': 'application/pdf',
                                      'aria-label': 'Upload file'
                                    }
                                })
                                if(file2===undefined || file2===null){
                                    Swal.fire({
                                        title: 'Procces canceled',
                                        text:'You must upload an picture report to finished the service',
                                        confirmButtonText: 'Ok',
                                    }).then(()=>{
                                    })
                                }else{
                                    const data= new FormData();
                                    data.append('file',file2);
                                    data.append('id',serviceDetailUserProject._id);
                                    await axios.put(url+'update-service-upload-images',data)
                                    .then(
                                        res =>{
                                            setServiceDetailUserProject(res.data.data[0]);
                                            socket.emit('update-service-manager',{userId:res.data.data[0].userId});
                                            Swal.fire({
                                                title: 'Service status changed',
                                                text:'This service has been set to Finished.',
                                                confirmButtonText: 'Ok',
                                            }).then(()=>{
                                            })
                                            
                                    })
                                }

                               
                            }
                        )
                        .catch(
                            err=>{
            
                            }
                        )
                      
                       
                    }
                       
                    

                    break;
                case 3:
                    var isCanceled=false;
                    const { value: file3 } = await Swal.fire({
                        title: 'Upload a proposal',
                        text:'To change the state to proposal you must upload a proposule file',
                        input: 'file',
                        className:'file-pop',
                        showCloseButton: true,
                        inputAttributes: {
                            'accept': 'application/pdf',
                            'aria-label': 'Upload file'
                        }
                    })
                    if(file3===undefined || file3===null){
                        Swal.fire({
                            title: 'Procces canceled',
                            text:'You must upload a proposal file to change state to proposal the service',
                            confirmButtonText: 'Ok',
                        }).then(()=>{
                        })
                    }else{
                        const data= new FormData();
                        data.append('file',file3);
                        data.append('id',serviceDetailUserProject._id);
                        await axios.put(url+'update-service-upload-proposal',data)
                        .then(
                            async res =>{
                                
                                setServiceDetailUserProject(res.data.data[0]);
                                socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                                if(user.manager){

                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                                Swal.fire({
                                    title: 'Service status changed',
                                    text:'This service has been set to Proposal.',
                                    confirmButtonText: 'Ok',
                                }).then(()=>{
                                })
                                
                        })
                        .catch(
                            err=>{
            
                            }
                        )
                        
                        
                    }

                    break;
                case 4:
                    var isCanceled=false;
                    const { value: file4 } = await Swal.fire({
                        title: 'Upload a proposal',
                        input: 'file',
                        className:'file-pop',
                        showCloseButton: true,
                        inputAttributes: {
                            'accept': 'application/pdf',
                            'aria-label': 'Upload file'
                        }
                    })
                    if(file4===undefined || file4===null){
                        Swal.fire({
                            title: 'Procces canceled',
                            confirmButtonText: 'Ok',
                        }).then(()=>{
                        })
                    }else{
                        const data= new FormData();
                        data.append('file',file4);
                        data.append('id',serviceDetailUserProject._id);
                        await axios.put(url+'update-service-proposal',data)
                        .then(
                            async res =>{
                                
                                setServiceDetailUserProject(res.data.data[0]);
                                socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                                if(user.manager){

                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                                Swal.fire({
                                    title: 'Service status changed',
                                    text:'This service has been set to Proposal.',
                                    confirmButtonText: 'Ok',
                                }).then(()=>{
                                })
                                
                        })
                        .catch(
                            err=>{
            
                            }
                        )
                        
                        
                    }

                    break;
                default:
                    break;
            }
           
        }
        const updateServiceSlider=async()=>{
            const formData={};
            formData.startingDate=date;
            formData.id=serviceDetailUserProject._id;
            formData.userChange=user.name;
            const { value: text } = await Swal.fire({
                title: 'Change Service Status',
                text:'You must explain why your changing this status',
                input: 'textarea',

                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here',
                    'class':'area-text-status'
                }
                })
                
                if (text) {
                    //Servicio reverse status
                    if(status===25){
                        formData.status='Scheduled';
                        formData.message=text;
                        await axios.put(url+'update-service-reverse-status',formData)
                        .then(
                            res =>{
                                debugger;
                                setServiceDetailUserProject(res.data.data[0]);
                                socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                                if(user.manager){

                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                                Swal.fire({
                                    title: 'Service status changed',
                                    text:'This service has been scheduled. Remember to constantly update the progress of the project.',
                                    confirmButtonText: 'Ok',
                                }).then(()=>{
                                })
                            }
                        )
                        .catch(
                            err=>{
            
                            }
                        )
                    }else if(status===0){
                        formData.status='Pending';
                        formData.message=text;
                        await axios.put(url+'update-service-reverse-status',formData)
                        .then(
                            res =>{
                                setServiceDetailUserProject(res.data.data[0]);
                                socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                                if(user.manager){
    
                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                                Swal.fire({
                                    title: 'Service status changed',
                                    text:'This service has been change to Pending.',
                                    confirmButtonText: 'Ok',
                                }).then(()=>{
                                })
                            }
                        )
                        .catch(
                            err=>{
            
                            }
                        )
                    }

                }
           
        }
        if(serviceDetailUserProject && status!==null){
            return(
                <div className="details-form-container detail-service-project">
                     <div className="back-btn-container">
                        <div className="back-btn-tf" onClick={(e)=>{
                            e.preventDefault();
                            setEditDetailServiceUserView(false);
                            setServiceDetailUserProject(null);
                        }}>
                            <BackIcon/>
                        </div>
                        <div className="btn-cancel">
                            <Button 
                                className="cancelBtn" 
                                variant="contained"
                                onClick={()=>{
                                    updateServiceCancel()
                                }}
                            >
                                Cancel service
                            </Button>
                        </div>
                    </div>
                    <div className="container-left-details">
                        <div className="container-form-data">
                            
                            <div className="detail-header">
                                <div className="detail-form-title">
                                    <div className="detail-title">
                                        Service information
                                    </div>
                                    <div className="slide-container">
                                        <div className="table-r-label">Status bar:</div>
                                        <ThemeProvider theme={theme}>

                                            <Slider
                                                aria-label="Custom marks"
                                                disabled={prodDisabled}
                                                value={status}
                                                onChange={(e, newValue)=>{
                                                    if(newValue<=statusDb && statusDb<=50){
                                                        setStatus(newValue)}
                                                    }
                                                }
                                                step={null}
                                                valueLabelDisplay="auto"
                                                marks={sliderMarks}
                                                sx={{width:500}}
                                            />
                                        </ThemeProvider>
                                    </div>
                                    {
                                        statusDb!==status&&

                                        <Button
                                            className="cancelBtn" 
                                            variant="contained"
                                            disabled={statusDb===status}
                                            onClick={()=>{
                                                updateServiceSlider();
                                            }}
                                        >
                                            Save
                                        </Button>
                                    }
                                </div>
                                <div className="detail-status-bar">
                                    
                                </div>
                            </div>
                            <div className="form">
                                <div className="form-content">
                                    <div className="form-group">
                                        <TextField
                                            className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Type of service" 
                                            variant="outlined"
                                            
                                            value={serviceDetailUserProject.serviceType}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Property name" 
                                            variant="outlined"
                                            
                                            value={serviceDetailUserProject.projectData[0].name}
                                        />
                                    </div>
                                    {
                                        date&&
                                        <div className="form-group">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Starting date"
                                                className="login-input"
                                                disabled={prodDisabled}
                                                value={date}
                                                onChange={(newValue)=>{setDate(newValue)}}
                                                renderInput={(params) => <TextField className="login-input" fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    }
                                   
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Address" 
                                            variant="outlined"
                                            
                                            value={serviceDetailUserProject.projectData[0].address}
                                        />
                                    </div>
                                </div>
                                <div className="form-content">
                                
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled
                                            id="outlined-basic" 
                                            label="Customer" 
                                            variant="outlined"
                                            
                                            value={detailUserData.name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Representative" 
                                            variant="outlined"
                                            
                                            value={serviceDetailUserProject.projectData[0].contact}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Type of roof" 
                                            variant="outlined"
                                            
                                            value={serviceDetailUserProject.projectData[0].roofType}
                                        />
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Property description/Client request</div>
                                <div className="form-group-text-area">
                                    <textarea
                                        className="text-area-detail"
                                        disabled 
                                        id="outlined-basic" 
                                        label="Nota" 
                                        variant="outlined"
                                        
                                        multiline="true"
                                        value={serviceDetailUserProject.note}
                                    />
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Media</div>
                                {
                                    serviceDetailUserProject.companyCams===undefined?(

                                        <React.Fragment>
                                            <div className="form-group">
                                                <TextField
                                                    className="login-input"
                                                    id="outlined-basic" 
                                                    label="Company Cam ID" 
                                                    variant="outlined"
                                                    onChange={(e)=>{e.preventDefault(); setCompanyCams(e.target.value)}}
                                                    value={companyCams}
                                                />
                                            </div>
                                        
                                            <Button
                                                className="cancelBtn" 
                                                variant="contained"
                                                disabled={prodDisabled}
                                                onClick={()=>{
                                                   updateCams();
                                                
                                                }}
                                            >
                                                Add
                                            </Button>
                                    </React.Fragment>
                                    ):(
                                        <React.Fragment>
                                            
                                            <Carousel animation='slide' indicators={true} autoPlay={false} 
                                            >  
                                            {
                                                projectImages&&
                                                    projectImages.map((item, i) => (
                                                        <div className="itemCatContainer" key={i}>
                                                            {
                                                                item.map((item2,ii)=>(
                                                                    <div key={'image-carousel'+ii} onClick={(e)=>{e.preventDefault(); openImageZoom(item2.uris[3].url)}}>
                                                                        <img  className="image-viewer" src={item2.uris[4].url}></img>
                                                                    </div>
                                                                    
                                                                ))
                                                            }
                                                        </div>
                                                    ))}
                                            </Carousel>
                                            <Button
                                                className="cancelBtn" 
                                                variant="contained"
                                                disabled={prodDisabled}
                                                onClick={()=>{
                                                    downloadGallery.map((item, i) => (
                                                        saveAs(item,'traderoofing-webapp'+new Date()+'.jpeg')
                                                    ))
                                                
                                                }}
                                            >
                                                Download all
                                            </Button>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                            {

                                serviceDetailUserProject.proposals&& serviceDetailUserProject.status==='Proposal' &&
                                <div className="description-detail">
                                    <div className="table-h-label">Proposal</div>
                                    <div className="proposal-list">

                                        {
                                            serviceDetailUserProject.proposals.map((item,i)=>(
                                                <div className='proposal-pdf-container' key={'proposal-pdf'+i}
                                                    onClick={()=>{
                                                        debugger;
                                                        downloadProposal(item.fileName)
                                                    }}
                                                >
                                                    <div className="pdf-Icon">
                                                        <PictureAsPdfIcon
                                                            sx={{color:"#1B3541", fontSize:60}}
                                                        ></PictureAsPdfIcon>
                                                    </div>
                                                    <div className="pdf-label">{item.fileName}</div>
                                                </div>
                                            ))

                                            
                                        }
                                    </div>
                                    <Button
                                        className="cancelBtn" 
                                        variant="contained"
                                        disabled={prodDisabled}
                                        onClick={()=>{
                                        
                                            updateServiceScheduled(4)
                                        }}
                                    >
                                        Upload proposal
                                    </Button>

                                </div>
                            }
                            {
                                chat.length!==0?(
                                    <div className="description-detail">
                                    <div className="table-h-label">Notes</div>
                                    {
                                        chat.map((item,i)=>(
                        
                                            <div className={item.userId===user._id?"note-item":"note-item receive"} key={i}>
                                                <div className="table-h-label">{item.user}</div>
                                                <div className="table-r-label">{item.message}</div>
                                                <div className="table-r-label time">{formatDistance(new Date(),new Date(item.date))}</div>
                                            </div>
                                        ))
                                    }
                                        
                                </div>
                                ):(
                                    <div className="description-detail">
                                    <div className="table-h-label">Notes</div>
                                </div>
                                )
                            }
                          
                            <div className="form-group-text-area">
                                <textarea
                                    className="text-area-detail"
                                    id="outlined-basic" 
                                    label="Write here" 
                                    variant="outlined"
                                    placeholder="Write here..."
                                    multiline="true"
                                    value={newMessage}
                                    onChange={(e)=>{
                                        setNewMessage(e.target.value)
                                    }}
                                />
                            </div>
                           
                            <div className="btn-container-manager">
                            
                                <div className="btn-send">
                                    <Button 
                                        className="loginBtn" 
                                        variant="contained"
                                        disabled={newMessage==='' || prodDisabled}
                                        onClick={()=>{
                                            updateService()
                                        }}
                                    >
                                        Send message
                                    </Button>
                                </div>
                                {
                                    serviceDetailUserProject.status==='Pending' &&
                                    <div className="btn-send">

                                        <Button 
                                            className="cancelBtn" 
                                            variant="contained"
                                            onClick={()=>{
                                                updateServiceScheduled(0)
                                            }}
                                        >
                                            Set to scheduled
                                        </Button>
                                    </div>
                                }
                                {
                                    serviceDetailUserProject.status==='Proposal' &&
                                    <div className="btn-send">

                                    <Button 
                                        className="cancelBtn" 
                                        variant="contained"
                                        onClick={()=>{
                                            updateServiceScheduled(1)
                                        }}
                                    >
                                        Set to Working
                                    </Button>
                                    </div>
                                }
                               
                                {
                                    serviceDetailUserProject.status==='Working on it' &&
                                    <div className="btn-send">

                                    <Button 
                                        className="cancelBtn" 
                                        variant="contained"
                                        disabled={prodDisabled}
                                        onClick={()=>{
                                            updateServiceScheduled(2)
                                        }}
                                    >
                                        Set to Finished
                                    </Button>
                                </div>
                                }
                                   {
                                    serviceDetailUserProject.status==='Scheduled' &&
                                    <div className="btn-send">

                                    <Button 
                                        className="cancelBtn" 
                                        variant="contained"
                                        onClick={()=>{
                                            updateServiceScheduled(3)
                                        }}
                                    >
                                        Set to Proposal
                                    </Button>
                                    </div>
                                }
                                
                                
                            </div>
                            {
                          
                                serviceDetailUserProject.log&&
                                    <div className="log-container">
                                        <div className="description-detail">
                                            <div className="table-h-label">Service changes log</div>
                                        </div>
                                        <div className="changes-log-list">
                                            {
                                                serviceDetailUserProject.log.map((item,i)=>(

                                                    <div key={'log-content-'+i} className='log-content'>
                                                        <div className="log-date">{format(new Date(item.date), 'dd/MM/yyyy')}</div>
                                                        <div className="log-user">User: {item.user}</div>
                                                        <div className="log-data">Change: {item.data}</div>
                                                        <div className="log-data">Message: {item.message}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                
                            } 
                        </div>
                    </div>
                </div>
            )
        }
    }
    const FormDetailViewManager = ()=>{
        const [date, setDate] = useState(new Date());
        const [projectImages, setProjectImages] = useState(null);
        const [newMessage, setNewMessage] = useState('');
        const [chat, setChat] = useState([]);
        const [status, setStatus] = useState(null);
        const [statusDb, setStatusDb] = useState(null);
        const [downloadGallery, setDownloadGallery] = useState(null);
        const [prodDisabled, setProdDisabled] =useState(false);
        const [companyCams, setCompanyCams]= useState('');
        useEffect(()=>{
            debugger;
            setDate(serviceDetailManager.startingDate);
            getChat(serviceDetailManager._id);
            switch (serviceDetailManager.status) {
                case 'Pending':
                    setStatus(0);
                    setStatusDb(0);
                break;
                case 'Canceled':
                    setStatus(0);
                    setStatusDb(0);
                break;
                case 'Scheduled':
                    setStatus(25);
                    setStatusDb(25);
                break;
                case 'Proposal':
                    setStatus(50);
                    setStatusDb(50);
                break;
                case 'Working on it':
                    setStatus(75);
                    setStatusDb(75);
                break;
                case 'Finished':
                    setStatus(100);
                    setStatusDb(100);
                break;
                default:
                    break;
            }
            if(serviceDetailManager.companyCams){
                getImages(serviceDetailManager.companyCams);
            }
           
            if(serviceDetailManager.status==='Working on it' && !user.superManager || serviceDetailManager.status==='Finished' && !user.superManager){
                setProdDisabled(true);
            }
        },[]);

        useEffect(()=>{
            socket.on("update-messages-"+serviceDetailManager._id,(lastMessage)=>{
                debugger
               if(lastMessage.userId===user._id){

                    setChat([...chat]);
                }else{

                    setChat([...chat, lastMessage]);
                }
            });
            return()=>{
                socket.off("update-messages-"+serviceDetailManager._id)
            }
        })
        const getChat=(id)=>{
            debugger;
            axios.get(url+'get-service-chat/'+id)
            .then(
                res=>{
                        setChat(res.data.data)
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        const getImages=(id)=>{
            axios.get(url+'get-project-images/'+serviceDetailManager._id+'/'+id)
            .then(
                res=>{
                    var galleryDownload=[];
                    var itemsPushed=[];
                    var finalItems= [];
                    res.data.data.map((item,i)=>{
                        
                        galleryDownload.push(item.uris[3].url)
                        var ii=i+1;
                        if(ii % 4 === 0){
                            itemsPushed.push(item);
                            finalItems.push(itemsPushed);
                            itemsPushed= [];
                        }else{
                            itemsPushed.push(item);
                            if(res.data.data.length===ii){
                                finalItems.push(itemsPushed);
                            }
                        }
                        
                    });
                    setProjectImages(finalItems);
                    setDownloadGallery(galleryDownload);
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        const updateService=async()=>{
            const formData={};
               const newChat={date:new Date(),message:newMessage,user:user.name, userId:user._id};
            var chatArray=chat;
            chatArray.push(newChat);
            formData.chat=chatArray;
            formData.id=serviceDetailManager._id;
            await axios.put(url+'update-service',formData)
            .then(
                res =>{
                    socket.emit('new-message-manager',({id:serviceDetailManager._id, chat:newChat}))
                    setNewMessage('');
                }
            )
            .catch(
                err=>{

                }
            )
        }
        const updateServiceCancel=async()=>{
            const formData={};
            formData.deleted=true;
            formData.id=serviceDetailManager._id;
            Swal.fire({
                title: 'Cancel service',
                text:'We will contact you after you cancel the service, Are you sure to continue?',
                confirmButtonText: 'Yes',
                showDenyButton: true,
                denyButtonText:'No'
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire({
                        title: 'Cancel service',
                        text:'Your service has been cancelled. We will contact you in the next 24 hours.',
                        confirmButtonText: 'Ok',
                    }).then(()=>{
                        setDetailServiceManagerView(false);
                        setServiceDetailManager(null);
                        axios.put(url+'update-service-cancel',formData)
                        .then(
                            res =>{
                                if(user.manager){

                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                            }
                        )
                        .catch(
                            err=>{

                            }
                        )
                    })
                }else if(result.isDenied){

                }
            })
        }
        const updateCams=()=>{
            var formData={};
            formData.id=serviceDetailManager._id;
            formData.companyCams=companyCams;
            axios.put(url+'update-service-cams',formData)
            .then(
                res =>{
                    if(user.manager){

                        getManagerDataById();
                    }else{

                        getManagerData()
                    }
                    setServiceDetailManager(res.data.data[0]);
                    socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                    getImages(res.data.data[0].companyCams);
                }
            )
            .catch(
                err=>{

                }
            )
        }
        const updateServiceScheduled=async(option)=>{

            const formData={};
            formData.startingDate=date;
            formData.id=serviceDetailManager._id;
            switch (option) {
                case 0:
                    await axios.put(url+'update-service-state-scheduled',formData)
                    .then(
                        res =>{
                            setServiceDetailManager(res.data.data[0]);
                            socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                            if(user.manager){

                                getManagerDataById();
                            }else{
        
                                getManagerData()
                            }
                            Swal.fire({
                                title: 'Service status changed',
                                text:'This service has been scheduled. Remember to constantly update the progress of the project.',
                                confirmButtonText: 'Ok',
                            }).then(()=>{
                            })
                        }
                    )
                    .catch(
                        err=>{
        
                        }
                    )
                    break;
                case 1:
                    await axios.put(url+'update-service-state-working',formData)
                    .then(
                        res =>{
                            setServiceDetailManager(res.data.data[0]);
                            socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                            if(user.manager){

                                getManagerDataById();
                            }else{
        
                                getManagerData()
                            }
                            Swal.fire({
                                title: 'Service status changed',
                                text:'This service has been set to Working on it. Remember to constantly update the progress of the project.',
                                confirmButtonText: 'Ok',
                            }).then(()=>{
                            })
                        }
                    )
                    .catch(
                        err=>{
        
                        }
                    )
                    break;
                case 2:
                    var isCanceled=false;
                    const { value: file } = await Swal.fire({
                        title: 'Upload an invoice',
                        text:'To finish this service firstable upload the service invoice & the picture report.',
                        input: 'file',
                        className:'file-pop',
                        showCloseButton: true,
                        inputAttributes: {
                          'accept': 'application/pdf',
                          'aria-label': 'Upload file'
                        }
                    })
                    if(file===undefined || file===null){
                        Swal.fire({
                            title: 'Procces canceled',
                            text:'You must upload an invoice file to finished the service',
                            confirmButtonText: 'Ok',
                        }).then(()=>{
                        })
                    }else{
                        const data= new FormData();
                        data.append('file',file);
                        data.append('id',serviceDetailManager._id);
                        await axios.put(url+'update-service-upload-invoice',data)
                        .then(
                            async res =>{
                                const { value: file2 } = await Swal.fire({
                                    title: 'Upload an picture report',
                                    text:'Now select the picture report.',
                                    input: 'file',
                                    className:'file-pop',
                                    showCloseButton: true,
                                    inputAttributes: {
                                      'accept': 'application/pdf',
                                      'aria-label': 'Upload file'
                                    }
                                })
                                if(file2===undefined || file2===null){
                                    Swal.fire({
                                        title: 'Procces canceled',
                                        text:'You must upload an picture report to finished the service',
                                        confirmButtonText: 'Ok',
                                    }).then(()=>{
                                    })
                                }else{
                                    const data= new FormData();
                                    data.append('file',file2);
                                    data.append('id',serviceDetailManager._id);
                                    await axios.put(url+'update-service-upload-images',data)
                                    .then(
                                        res =>{
                                            setServiceDetailManager(res.data.data[0]);
                                            socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                                            if(user.manager){

                                                getManagerDataById();
                                            }else{
                        
                                                getManagerData()
                                            }
                                            Swal.fire({
                                                title: 'Service status changed',
                                                text:'This service has been set to Finished.',
                                                confirmButtonText: 'Ok',
                                            }).then(()=>{
                                            })
                                            
                                    })
                                }

                               
                            }
                        )
                        .catch(
                            err=>{
            
                            }
                        )
                      
                       
                    }
                       
                    

                    break;
                case 3:
                    var isCanceled=false;
                    const { value: file3 } = await Swal.fire({
                        title: 'Upload a proposal',
                        text:'To change the state to proposal you must upload a proposule file',
                        input: 'file',
                        className:'file-pop',
                        showCloseButton: true,
                        inputAttributes: {
                            'accept': 'application/pdf',
                            'aria-label': 'Upload file'
                        }
                    })
                    if(file3===undefined || file3===null){
                        Swal.fire({
                            title: 'Procces canceled',
                            text:'You must upload a proposal file to change state to proposal the service',
                            confirmButtonText: 'Ok',
                        }).then(()=>{
                        })
                    }else{
                        const data= new FormData();
                        data.append('file',file3);
                        data.append('id',serviceDetailManager._id);
                        await axios.put(url+'update-service-upload-proposal',data)
                        .then(
                            async res =>{
                                
                                setServiceDetailManager(res.data.data[0]);
                                socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                                if(user.manager){

                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                                Swal.fire({
                                    title: 'Service status changed',
                                    text:'This service has been set to Proposal.',
                                    confirmButtonText: 'Ok',
                                }).then(()=>{
                                })
                                
                        })
                        .catch(
                            err=>{
            
                            }
                        )
                        
                        
                    }

                    break;
                case 4:
                    var isCanceled=false;
                    const { value: file4 } = await Swal.fire({
                        title: 'Upload a proposal',
                        input: 'file',
                        className:'file-pop',
                        showCloseButton: true,
                        inputAttributes: {
                            'accept': 'application/pdf',
                            'aria-label': 'Upload file'
                        }
                    })
                    if(file4===undefined || file4===null){
                        Swal.fire({
                            title: 'Procces canceled',
                            confirmButtonText: 'Ok',
                        }).then(()=>{
                        })
                    }else{
                        const data= new FormData();
                        data.append('file',file4);
                        data.append('id',serviceDetailManager._id);
                        await axios.put(url+'update-service-proposal',data)
                        .then(
                            async res =>{
                                
                                setServiceDetailManager(res.data.data[0]);
                                socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                                if(user.manager){

                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                                Swal.fire({
                                    title: 'Service status changed',
                                    text:'This service has been set to Proposal.',
                                    confirmButtonText: 'Ok',
                                }).then(()=>{
                                })
                                
                        })
                        .catch(
                            err=>{
            
                            }
                        )
                        
                        
                    }

                    break;
                default:
                break;
            }
           
        }
        const updateServiceSlider=async()=>{
            const formData={};
            formData.startingDate=date;
            formData.id=serviceDetailManager._id;
            formData.userChange=user.name;
            const { value: text } = await Swal.fire({
                title: 'Change Service Status',
                text:'You must explain why your changing this status',
                input: 'textarea',

                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here',
                    'class':'area-text-status'
                }
                })
                
                if (text) {
                    //Servicio reverse status
                    if(status===25){
                        formData.status='Scheduled';
                        formData.message=text;
                        await axios.put(url+'update-service-reverse-status',formData)
                    .then(
                        res =>{
                            setServiceDetailManager(res.data.data[0]);
                            socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                            if(user.manager){

                                getManagerDataById();
                            }else{
        
                                getManagerData()
                            }
                            Swal.fire({
                                title: 'Service status changed',
                                text:'This service has been scheduled. Remember to constantly update the progress of the project.',
                                confirmButtonText: 'Ok',
                            }).then(()=>{
                            })
                        }
                    )
                    .catch(
                        err=>{
        
                        }
                    )
                    }else if(status===0){
                        formData.status='Pending';
                        formData.message=text;
                        await axios.put(url+'update-service-reverse-status',formData)
                        .then(
                            res =>{
                                setServiceDetailManager(res.data.data[0]);
                                socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                                if(user.manager){
    
                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                                Swal.fire({
                                    title: 'Service status changed',
                                    text:'This service has been change to Pending.',
                                    confirmButtonText: 'Ok',
                                }).then(()=>{
                                })
                            }
                        )
                        .catch(
                            err=>{
            
                            }
                        )
                    }

                }
           
        }
        if(serviceDetailManager && status!==null){
            return(
                <div className="details-form-container detail-service-project">
                     <div className="back-btn-container">
                        <div className="back-btn-tf" onClick={(e)=>{
                            e.preventDefault();
                            setDetailServiceManagerView(false);
                            setServiceDetailManager(null);
                        }}>
                            <BackIcon/>
                        </div>
                        <div className="btn-cancel">
                            <Button 
                                className="cancelBtn" 
                                variant="contained"
                                onClick={()=>{
                                    updateServiceCancel()
                                }}
                            >
                                Cancel service
                            </Button>
                        </div>
                    </div>
                    <div className="container-left-details">
                        <div className="container-form-data">
                            
                            <div className="detail-header">
                                <div className="detail-form-title">
                                    <div className="detail-title">
                                        Service information
                                    </div>
                                    <div className="slide-container">
                                        <div className="table-r-label">Status bar:</div>
                                        <ThemeProvider theme={theme}>

                                            <Slider
                                                aria-label="Custom marks"
                                                disabled={prodDisabled}
                                                value={status}
                                                onChange={(e, newValue)=>{
                                                    if(newValue<=statusDb && statusDb<=50){
                                                        setStatus(newValue)}
                                                    }
                                                }
                                                step={null}
                                                valueLabelDisplay="auto"
                                                marks={sliderMarks}
                                                sx={{width:500}}
                                            />
                                        </ThemeProvider>
                                    </div>
                                    {
                                        statusDb!==status&&

                                        <Button
                                            className="cancelBtn" 
                                            variant="contained"
                                            disabled={statusDb===status}
                                            onClick={()=>{
                                                updateServiceSlider();
                                            }}
                                        >
                                            Save
                                        </Button>
                                    }
                                </div>
                                <div className="detail-status-bar">
                                    
                                </div>
                            </div>
                            <div className="form">
                                <div className="form-content">
                                    <div className="form-group">
                                        <TextField
                                            className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Type of service" 
                                            variant="outlined"
                                            
                                            value={serviceDetailManager.serviceType}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Property name" 
                                            variant="outlined"
                                            
                                            value={serviceDetailManager.projectData[0].name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Starting date"
                                                className="login-input"
                                                disabled={prodDisabled}
                                                value={date}
                                                onChange={(newValue)=>{setDate(newValue)}}
                                                renderInput={(params) => <TextField className="login-input" fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Address" 
                                            variant="outlined"
                                            
                                            value={serviceDetailManager.projectData[0].address}
                                        />
                                    </div>
                                </div>
                                <div className="form-content">
                                
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled
                                            id="outlined-basic" 
                                            label="Customer" 
                                            variant="outlined"
                                            
                                            value={serviceDetailManager.userData[0].name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Representative" 
                                            variant="outlined"
                                            
                                            value={serviceDetailManager.projectData[0].contact}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Type of roof" 
                                            variant="outlined"
                                            
                                            value={serviceDetailManager.projectData[0].type}
                                        />
                                    </div>
                                
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Property description/Client request</div>
                                <div className="form-group-text-area">
                                    <textarea
                                        className="text-area-detail"
                                        disabled 
                                        id="outlined-basic" 
                                        label="Nota" 
                                        variant="outlined"
                                        
                                        multiline="true"
                                        value={serviceDetailManager.note}
                                    />
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Media</div>
                                {
                                    serviceDetailManager.companyCams===undefined?(

                                        <React.Fragment>
                                            <div className="form-group">
                                                <TextField
                                                    className="login-input"
                                                    id="outlined-basic" 
                                                    label="Company Cam ID" 
                                                    variant="outlined"
                                                    onChange={(e)=>{e.preventDefault(); setCompanyCams(e.target.value)}}
                                                    value={companyCams}
                                                />
                                            </div>
                                        
                                            <Button
                                                className="cancelBtn" 
                                                variant="contained"
                                                disabled={companyCams===''?(true):(false)}
                                                onClick={()=>{
                                                   updateCams();
                                                
                                                }}
                                            >
                                                Add
                                            </Button>
                                    </React.Fragment>
                                    ):(
                                        <React.Fragment>
                                            
                                            <Carousel animation='slide' indicators={true} autoPlay={false} 
                                            >  
                                            {
                                                projectImages&&
                                                    projectImages.map((item, i) => (
                                                        <div className="itemCatContainer" key={i}>
                                                            {
                                                                item.map((item2,ii)=>(
                                                                    <div key={'image-carousel'+ii} onClick={(e)=>{e.preventDefault(); openImageZoom(item2.uris[3].url)}}>
                                                                        <img  className="image-viewer" src={item2.uris[4].url}></img>
                                                                    </div>
                                                                    
                                                                ))
                                                            }
                                                        </div>
                                                    ))}
                                            </Carousel>
                                            <Button
                                                className="cancelBtn" 
                                                variant="contained"
                                                onClick={()=>{
                                                    downloadGallery.map((item, i) => (
                                                        saveAs(item,'traderoofing-webapp'+new Date()+'.jpeg')
                                                    ))
                                                
                                                }}
                                            >
                                                Download all
                                            </Button>
                                        </React.Fragment>
                                    )
                                }
                               
                               
                            </div>
                            {

                                serviceDetailManager.proposals&& serviceDetailManager.status==='Proposal' &&
                                <div className="description-detail">
                                    <div className="table-h-label">Proposal</div>
                                    <div className="proposal-list">

                                        {
                                            serviceDetailManager.proposals.map((item,i)=>(
                                                <div className='proposal-pdf-container' key={'proposal-pdf'+i}
                                                    onClick={()=>{
                                                        downloadProposal(item.fileName)

                                                    }}
                                                >
                                                    <div className="pdf-Icon">
                                                        <PictureAsPdfIcon
                                                            sx={{color:"#1B3541", fontSize:60}}
                                                        ></PictureAsPdfIcon>
                                                    </div>
                                                    <div className="pdf-label">{item.fileName}</div>
                                                </div>
                                            ))

                                            
                                        }
                                    </div>
                                    <Button
                                        className="cancelBtn" 
                                        variant="contained"
                                        onClick={()=>{
                                        
                                            updateServiceScheduled(4)
                                        }}
                                    >
                                        Upload proposal
                                    </Button>
                                
                                </div>
                            }
                            {
                                chat.length!==0?(
                                    <div className="description-detail">
                                    <div className="table-h-label">Notes</div>
                                    {
                                        chat.map((item,i)=>(
                        
                                            <div className={item.userId===user._id?"note-item":"note-item receive"} key={i}>
                                                <div className="table-h-label">{item.user}</div>
                                                <div className="table-r-label">{item.message}</div>
                                                <div className="table-r-label time">{formatDistance(new Date(),new Date(item.date))}</div>
                                            </div>
                                        ))
                                    }
                                        
                                </div>
                                ):(
                                    <div className="description-detail">
                                    <div className="table-h-label">Notes</div>
                                </div>
                                )
                            }
                            <div className="form-group-text-area">
                                <textarea
                                    className="text-area-detail"
                                    id="outlined-basic" 
                                    label="Write here" 
                                    variant="outlined"
                                    placeholder="Write here..."
                                    multiline="true"
                                    value={newMessage}
                                    onChange={(e)=>{
                                        setNewMessage(e.target.value)
                                    }}
                                />
                            </div>
                           
                            <div className="btn-container-manager">
                                <div className="btn-send">
                                    <Button 
                                        className="loginBtn" 
                                        variant="contained"
                                        disabled={newMessage==='' || prodDisabled}
                                        onClick={()=>{
                                            updateService()
                                        }}
                                    >
                                        Send message
                                    </Button>
                                </div>
                                {
                                    serviceDetailManager.status==='Pending' &&
                                    <div className="btn-send">

                                    <Button 
                                        className="cancelBtn" 
                                        variant="contained"
                                        onClick={()=>{
                                            updateServiceScheduled(0)
                                        }}
                                    >
                                        Set to scheduled
                                    </Button>
                                    </div>
                                }
                                {
                                    serviceDetailManager.status==='Scheduled' &&
                                    <div className="btn-send">

                                    <Button 
                                        className="cancelBtn" 
                                        variant="contained"
                                        onClick={()=>{
                                            updateServiceScheduled(3)
                                        }}
                                    >
                                        Set to Proposal
                                    </Button>
                                    </div>
                                }
                                {
                                    serviceDetailManager.status==='Proposal' &&
                                    <div className="btn-send">

                                    <Button 
                                        className="cancelBtn" 
                                        variant="contained"
                                        onClick={()=>{
                                            updateServiceScheduled(1)
                                        }}
                                    >
                                        Set to Working
                                    </Button>
                                    </div>
                                }
                                {
                                    serviceDetailManager.status==='Working on it' &&
                                    <div className="btn-send">

                                    <Button 
                                        className="cancelBtn" 
                                        variant="contained"
                                        disabled={prodDisabled}
                                        onClick={()=>{
                                            updateServiceScheduled(2)
                                        }}
                                    >
                                        Set to Finished
                                    </Button>
                                    </div>
                                }
                            
                            </div>
                            {
                          
                                serviceDetailManager.log&&
                                    <div className="log-container">
                                        <div className="description-detail">
                                            <div className="table-h-label">Service changes log</div>
                                        </div>
                                        <div className="changes-log-list">
                                            {
                                                serviceDetailManager.log.map((item,i)=>(

                                                    <div key={'log-content-'+i} className='log-content'>
                                                        <div className="log-date">{format(new Date(item.date), 'dd/MM/yyyy')}</div>
                                                        <div className="log-user">User: {item.user}</div>
                                                        <div className="log-data">Change: {item.data}</div>
                                                        <div className="log-data">Message: {item.message}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                
                            } 
                        </div>
                    </div>
                </div>
            )
        }
    }
    const FormDetailViewManagerFinished = ()=>{
        const [date, setDate] = useState(new Date());
        const [projectImages, setProjectImages] = useState(null);
        const [newMessage, setNewMessage] = useState('');
        const [chat, setChat] = useState(null);
        const [status, setStatus] = useState(null);
        const [downloadGallery, setDownloadGallery] = useState(null);
        const [companyCams, setCompanyCams]= useState('');
        useEffect(()=>{
            console.log(finishedDetailManager)
            setDate(finishedDetailManager.startingDate);
            getChat(finishedDetailManager._id);
            switch (finishedDetailManager.status) {
                case 'Pending':
                    setStatus(0);
                break;
                case 'Canceled':
                    setStatus(0);
                break;
                case 'Scheduled':
                    setStatus(25);
                break;
                case 'Proposal':
                    setStatus(50);
                break;
                case 'Working on it':
                    setStatus(75);
                break;
                case 'Finished':
                    setStatus(100);
                break;
                default:
                    break;
            }
            if(finishedDetailManager.companyCams){
                getImages(finishedDetailManager.companyCams);
            }
        },[]);

        useEffect(()=>{
            socket.on("update-messages-"+finishedDetailManager._id,(lastMessage)=>{
                debugger
               if(lastMessage.userId===user._id){

                    setChat([...chat]);
                }else{

                    setChat([...chat, lastMessage]);
                }
            });
            return()=>{
                socket.off("update-messages-"+finishedDetailManager._id)
            }
        })
        const getImages=async (id)=>{
            await axios.get(url+'get-project-images/'+finishedDetailManager._id+'/'+id)
            .then(
                res=>{
                    debugger;
                    var galleryDownload=[];
                    var itemsPushed=[];
                    var finalItems= [];
                    res.data.data.map((item,i)=>{
                        
                        galleryDownload.push(item.uris[3].url)
                        var ii=i+1;
                        if(ii % 4 === 0){
                            itemsPushed.push(item);
                            finalItems.push(itemsPushed);
                            itemsPushed= [];
                        }else{
                            itemsPushed.push(item);
                            if(res.data.data.length===ii){
                                finalItems.push(itemsPushed);
                            }
                        }
                        
                    });
                    setDownloadGallery(galleryDownload);
                    setProjectImages(finalItems)
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        const updateService=async()=>{
            const formData={};
               const newChat={date:new Date(),message:newMessage,user:user.name, userId:user._id};
            var chatArray=chat;
            chatArray.push(newChat);
            formData.chat=chatArray;
            formData.id=finishedDetailManager._id;
            await axios.put(url+'update-service',formData)
            .then(
                res =>{
                    socket.emit('new-message-manager',({id:finishedDetailManager._id, chat:newChat}))
                    setNewMessage('');
                }
            )
            .catch(
                err=>{

                }
            )
        }
        const updateServiceCancel=async()=>{
            const formData={};
            formData.deleted=true;
            formData.id=finishedDetailManager._id;
            Swal.fire({
                title: 'Cancel service',
                text:'We will contact you after you cancel the service, Are you sure to continue?',
                confirmButtonText: 'Yes',
                showDenyButton: true,
                denyButtonText:'No'
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire({
                        title: 'Cancel service',
                        text:'Your service has been cancelled. We will contact you in the next 24 hours.',
                        confirmButtonText: 'Ok',
                    }).then(()=>{
                        setDetailFinishedManagerView(false);
                        setFinishedDetailManager(null);
                        axios.put(url+'update-service-cancel',formData)
                        .then(
                            res =>{
                                if(user.manager){

                                    getManagerDataById();
                                }else{
            
                                    getManagerData()
                                }
                            }
                        )
                        .catch(
                            err=>{

                            }
                        )
                    })
                }else if(result.isDenied){

                }
            })
        }
        const updateCams=()=>{
            var formData={};
            formData.id=finishedDetailManager._id;
            formData.companyCams=companyCams;
            axios.put(url+'update-service-cams',formData)
            .then(
                res =>{
                    setServiceDetailManager(res.data.data[0]);
                    socket.emit('update-service-manager',{userId:res.data.data[0].userId})
                    getImages(res.data.data[0].companyCams);
                }
            )
            .catch(
                err=>{

                }
            )
        }
        const getChat=(id)=>{
            debugger;
            axios.get(url+'get-service-chat/'+id)
            .then(
                res=>{
                        setChat(res.data.data)
                }
            ).catch(
                err=>{
                    
                }
            )
        }
        if(status!==null && finishedDetailManager && projectImages){
            return(
                    <div className="details-form-container detail-service-project">
                         <div className="back-btn-container">
                            <div className="back-btn-tf" onClick={()=>{
                                setDetailFinishedManagerView(false);
                                setFinishedDetailManager(null);
                            }}>
                                <BackIcon/>
                            </div>
                            <div className="btn-cancel">
                                <Button 
                                    className="cancelBtn" 
                                    variant="contained"
                                    onClick={()=>{
                                        updateServiceCancel()
                                    }}
                                >
                                    Cancel service
                                </Button>
                            </div>
                        </div>
                        <div className="container-left-details">
                            <div className="container-form-data">
                                
                                <div className="detail-header">
                                    <div className="detail-form-title">
                                        <div className="detail-title">
                                            Service information
                                        </div>
                                        <div className="slide-container">
                                            <div className="table-r-label">Status bar:</div>
                                            <ThemeProvider theme={theme}>
    
                                                <Slider
                                                    aria-label="Custom marks"
                                                    value={status}
                                                    disabled
                                                    onChange={(e, newValue)=>{setStatus(newValue)}}
                                                    valueLabelDisplay="auto"
                                                    marks={sliderMarks}
                                                    sx={{width:500}}
                                                />
                                            </ThemeProvider>
                                        </div>
                                    </div>
                                    <div className="detail-status-bar">
                                        
                                    </div>
                                </div>
                                <div className="form">
                                    <div className="form-content">
                                        <div className="form-group">
                                            <TextField
                                                className="login-input" 
                                                disabled
                                                id="outlined-basic" 
                                                label="Type of service" 
                                                variant="outlined"
                                                
                                                value={finishedDetailManager.serviceType}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                className="login-input"
                                                disabled 
                                                id="outlined-basic" 
                                                label="Property name" 
                                                variant="outlined"
                                                
                                                value={finishedDetailManager.projectData[0].name}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Starting date"
                                                    className="login-input"
                                                    value={date}
                                                    onChange={(newValue)=>{setDate(newValue)}}
                                                    renderInput={(params) => <TextField className="login-input" fullWidth {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                            className="login-input" 
                                                disabled
                                                id="outlined-basic" 
                                                label="Address" 
                                                variant="outlined"
                                                
                                                value={finishedDetailManager.projectData[0].address}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-content">
                                    
                                        <div className="form-group">
                                            <TextField
                                            className="login-input"
                                                disabled
                                                id="outlined-basic" 
                                                label="Customer" 
                                                variant="outlined"
                                                
                                                value={user.name}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                            className="login-input" 
                                                disabled
                                                id="outlined-basic" 
                                                label="Representative" 
                                                variant="outlined"
                                                
                                                value={finishedDetailManager.projectData[0].representative}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                            className="login-input"
                                                disabled 
                                                id="outlined-basic" 
                                                label="Type of roof" 
                                                variant="outlined"
                                                
                                                value={finishedDetailManager.projectData[0].type}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                className="login-input"
                                                disabled 
                                                id="outlined-basic" 
                                                label="City" 
                                                variant="outlined"
                                                
                                                value={detailUserData.city}
                                            />
                                        </div>
                                    
                                    </div>
                                </div>
                                <div className="description-detail">
                                    <div className="table-h-label">Property description/Client request</div>
                                    <div className="form-group-text-area">
                                        <textarea
                                            className="text-area-detail"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Nota" 
                                            variant="outlined"
                                            
                                            multiline="true"
                                            value={finishedDetailManager.note}
                                        />
                                    </div>
                                </div>
                                <div className="description-detail">
                                    <div className="table-h-label">Media</div>
                                    {
                                        finishedDetailManager.companyCams===undefined && projectImages===null?(
    
                                            <React.Fragment>
                                                <div className="form-group">
                                                    <TextField
                                                        className="login-input"
                                                        id="outlined-basic" 
                                                        label="Company Cam ID" 
                                                        variant="outlined"
                                                        onChange={(e)=>{e.preventDefault(); setCompanyCams(e.target.value)}}
                                                        value={companyCams}
                                                    />
                                                </div>
                                            
                                                <Button
                                                    className="cancelBtn" 
                                                    variant="contained"
                                                    onClick={()=>{
                                                    updateCams();
                                                    
                                                    }}
                                                >
                                                    Add
                                                </Button>
                                        </React.Fragment>
                                        ):(
                                            <React.Fragment>
                                                        <Carousel animation='slide' indicators={true} autoPlay={false} 
                                                        >  
                                                            {
                                                                projectImages&&
                                                                    projectImages.map((item, i) => (
                                                                    <div className="itemCatContainer" key={i}>
                                                                        {
                                                                            item.map((item2,ii)=>(
                                                                                <div key={'image-carousel'+ii} onClick={(e)=>{e.preventDefault(); openImageZoom(item2.uris[3].url)}}>
                                                                                    <img  className="image-viewer" src={item2.uris[4].url}></img>
                                                                                </div>
                                                                                
                                                                            ))
                                                                        }
                                                                    </div>
                                                                    ))
                                                            }
                                                    </Carousel>
                                                    <Button
                                                        className="cancelBtn" 
                                                        variant="contained"
                                                        onClick={()=>{
                                                            downloadGallery.map((item, i) => (
                                                                saveAs(item,'traderoofing-webapp'+new Date()+'.jpeg')
                                                            ))
                                                        
                                                        }}
                                                    >
                                                        Download all
                                                    </Button>
    
                                            </React.Fragment>
                                        )
                                    }
                                </div>
                                {

                                    finishedDetailManager.proposals&&
                                    <div className="description-detail">
                                        <div className="table-h-label">Proposal</div>
                                        <div className="proposal-list">

                                            {
                                                finishedDetailManager.proposals.map((item,i)=>(
                                                    <div className='proposal-pdf-container' key={'proposal-pdf'+i}
                                                        onClick={()=>{
                                                            downloadProposal(item.fileName)

                                                        }}
                                                    >
                                                        <div className="pdf-Icon">
                                                            <PictureAsPdfIcon
                                                                sx={{color:"#1B3541", fontSize:60}}
                                                            ></PictureAsPdfIcon>
                                                        </div>
                                                        <div className="pdf-label">{item.fileName}</div>
                                                    </div>
                                                ))

                                                
                                            }
                                        </div>
                                    </div>
                                }  
                                {
                                    chat.length!==0?(
                                        <div className="description-detail">
                                        <div className="table-h-label">Notes</div>
                                        {
                                            chat.map((item,i)=>(
                            
                                                <div className={item.userId===user._id?"note-item":"note-item receive"} key={i}>
                                                    <div className="table-h-label">{item.user}</div>
                                                    <div className="table-r-label">{item.message}</div>
                                                    <div className="table-r-label time">{formatDistance(new Date(),new Date(item.date))}</div>
                                                </div>
                                            ))
                                        }
                                            
                                    </div>
                                    ):(
                                        <div className="description-detail">
                                        <div className="table-h-label">Notes</div>
                                    </div>
                                    )
                                }
                               
                                <div className="form-group-text-area">
                                    <textarea
                                        className="text-area-detail"
                                        id="outlined-basic" 
                                        label="Write here" 
                                        variant="outlined"
                                        placeholder="Write here..."
                                        multiline="true"
                                        value={newMessage}
                                        onChange={(e)=>{
                                            setNewMessage(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className="btn-send">
                                    <Button 
                                        className="loginBtn" 
                                        variant="contained"
                                        disabled={newMessage===''}
                                        onClick={()=>{
                                            updateService()
                                        }}
                                    >
                                        Send message
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                
            )
        }
        if(status!==null && finishedDetailManager){
            return(
                <div className="details-form-container detail-service-project">
                     <div className="back-btn-container">
                        <div className="back-btn-tf" onClick={()=>{
                            setDetailFinishedManagerView(false);
                            setFinishedDetailManager(null);
                        }}>
                            <BackIcon/>
                        </div>
                        <div className="btn-cancel">
                            <Button 
                                className="cancelBtn" 
                                variant="contained"
                                onClick={()=>{
                                    updateServiceCancel()
                                }}
                            >
                                Cancel service
                            </Button>
                        </div>
                    </div>
                    <div className="container-left-details">
                        <div className="container-form-data">
                            
                            <div className="detail-header">
                                <div className="detail-form-title">
                                    <div className="detail-title">
                                        Service information
                                    </div>
                                    <div className="slide-container">
                                        <div className="table-r-label">Status bar:</div>
                                        <ThemeProvider theme={theme}>

                                            <Slider
                                                aria-label="Custom marks"
                                                value={status}
                                                disabled
                                                onChange={(e, newValue)=>{setStatus(newValue)}}
                                                valueLabelDisplay="auto"
                                                marks={sliderMarks}
                                                sx={{width:500}}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>
                                <div className="detail-status-bar">
                                    
                                </div>
                            </div>
                            <div className="form">
                                <div className="form-content">
                                    <div className="form-group">
                                        <TextField
                                            className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Type of service" 
                                            variant="outlined"
                                            
                                            value={finishedDetailManager.serviceType}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Property name" 
                                            variant="outlined"
                                            
                                            value={finishedDetailManager.projectData[0].name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Starting date"
                                                className="login-input"
                                                value={date}
                                                onChange={(newValue)=>{setDate(newValue)}}
                                                renderInput={(params) => <TextField className="login-input" fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Address" 
                                            variant="outlined"
                                            
                                            value={finishedDetailManager.projectData[0].address}
                                        />
                                    </div>
                                </div>
                                <div className="form-content">
                                
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled
                                            id="outlined-basic" 
                                            label="Customer" 
                                            variant="outlined"
                                            
                                            value={user.name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input" 
                                            disabled
                                            id="outlined-basic" 
                                            label="Representative" 
                                            variant="outlined"
                                            
                                            value={finishedDetailManager.projectData[0].representative}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="Type of roof" 
                                            variant="outlined"
                                            
                                            value={finishedDetailManager.projectData[0].type}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            className="login-input"
                                            disabled 
                                            id="outlined-basic" 
                                            label="City" 
                                            variant="outlined"
                                            
                                            value={detailUserData.city}
                                        />
                                    </div>
                                
                                </div>
                            </div>
                            <div className="description-detail">
                                <div className="table-h-label">Property description/Client request</div>
                                <div className="form-group-text-area">
                                    <textarea
                                        className="text-area-detail"
                                        disabled 
                                        id="outlined-basic" 
                                        label="Nota" 
                                        variant="outlined"
                                        
                                        multiline="true"
                                        value={finishedDetailManager.note}
                                    />
                                </div>
                            </div>
                            {

                                finishedDetailManager.proposals&&
                                <div className="description-detail">
                                    <div className="table-h-label">Proposal</div>
                                    <div className="proposal-list">

                                        {
                                            finishedDetailManager.proposals.map((item,i)=>(
                                                <div className='proposal-pdf-container' key={'proposal-pdf'+i}
                                                    onClick={()=>{
                                                        downloadProposal(item.fileName)

                                                    }}
                                                >
                                                    <div className="pdf-Icon">
                                                        <PictureAsPdfIcon
                                                            sx={{color:"#1B3541", fontSize:60}}
                                                        ></PictureAsPdfIcon>
                                                    </div>
                                                    <div className="pdf-label">{item.fileName}</div>
                                                </div>
                                            ))

                                            
                                        }
                                    </div>
                                </div>
                            }  
                            {
                                finishedDetailManager.chat.length!==0?(
                                    <div className="description-detail">
                                    <div className="table-h-label">Notes</div>
                                    {
                                         finishedDetailManager.chat.map((item,i)=>(
                        
                                            <div className={item.userId===user._id?"note-item":"note-item receive"} key={i}>
                                                <div className="table-h-label">{item.user}</div>
                                                <div className="table-r-label">{item.message}</div>
                                                <div className="table-r-label time">{formatDistance(new Date(),new Date(item.date))}</div>
                                            </div>
                                        ))
                                    }
                                        
                                </div>
                                ):(
                                    <div className="description-detail">
                                    <div className="table-h-label">Notes</div>
                                </div>
                                )
                            }
                           
                            <div className="form-group-text-area">
                                <textarea
                                    className="text-area-detail"
                                    id="outlined-basic" 
                                    label="Write here" 
                                    variant="outlined"
                                    placeholder="Write here..."
                                    multiline="true"
                                    value={newMessage}
                                    onChange={(e)=>{
                                        setNewMessage(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="btn-send">
                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    disabled={newMessage===''}
                                    onClick={()=>{
                                        updateService()
                                    }}
                                >
                                    Send message
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    const saveManagerForm =  async () =>{
        if(managerError || managerMailError || managerPassError){
            debugger;
            return
        }
        const formData={};
        formData.name=managerName;
        formData.pass=managerPass;
        formData.mail=managerMail;
        if(managerType==='0'){
            formData.superManager=true;
        }else{
            formData.manager=true;
        }
        await axios.post(url+'create-manager', formData)
            .then( res =>{
                const response=res.data.data;
                if(res){
                    
                    setCreateAdmin(false);
                    Swal.fire({
                        title: 'Manager created!',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        showCloseButton: true}
                    ).then(()=>{
                        getManagerData();
                        setManagerMail('');
                        setManagerPass('');
                        setManagerName('');
                    })
                }else{
                    setCreateAdmin(false);
                    Swal.fire(
                        {
                            title: 'Ups',
                            text:'Something went wrong!',
                            confirmButtonText: 'Close',
                            allowOutsideClick: false,
                            showCloseButton: true
                        }
                    )
                   
                }
            }, error =>{
                setCreateAdmin(false);
                Swal.fire(
                    {
                        title: 'Ups',
                        text:'Something went wrong!',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false,
                        showCloseButton: true
                    }
                )
            })
         
    }

    const saveProjectManager = ()=>{
        if(projectNameM===''){
            setProjectErrorM(true);
        }
        if(projectAddressM===''){
            setAddressErrorM(true);
        }
        if(roofAccessM===''){
            setProjectAccessErrorM(true);
        }
        if(projectErrorM ||  addressErrorM || projectAccessErrorM){
            return
        }else{
            //arrancar w8 screen
            var formData={};
            formData.name=projectNameM;
            formData.address=projectAddressM;
            formData.access=roofAccessM.label?roofAccessM.label:roofAccessM;
            formData.type=roofTypeM.label?roofTypeM.label:roofTypeM;
            formData.contact=contactM;
            formData.contact_number=contactPhoneM;
            formData.note=noteM;
            formData.user=detailUserData._id;
            axios.post(url+'create-project',formData)
            .then(
                res=>{
                    setNewPropertyModal(false);
                    Swal.fire({
                        title: 'Property created',
                        icon:'success',
                        text:'The property was succesfull created.',
                        confirmButtonText: 'Ok',
                    }).then(()=>{
                        if(user.manager){

                            getManagerDataById();
                        }else{
    
                            getManagerData()
                        }

                    })
                }
            )
            .catch(
                err=>{
                    console.log(err)
                }
            )
        }
        //create form data
        //run service
        //redirect
    }
    const saveFormManager =  async () =>{
        if(sDateM===null){
            return
        }
        const formData={};
        debugger;
        formData.userId=selectedUserProject.userId;
        formData.projectId=selectedUserProject._id;
        formData.serviceType=roofServiceM.label?roofServiceM.label:roofServiceM;
        formData.startingDate=sDateM;
        formData.status='Pending';
        formData.note=serviceNoteM;
        await axios.post(url+'create-service-manager', formData)
            .then( res =>{
                if(res){
                    setNewServiceModal(false)
                    Swal.fire({
                        title: 'Service created!',
                        text:'Remember to report the user about this service.',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        showCloseButton: true}
                    ).then(()=>{
                        if(user.manager){
                            getManagerDataById();
                        }else{
                            getManagerData()
                        }
                    })
                }else{
                    Swal.fire(
                        {
                            title: 'Ups',
                            text:'Something went wrong!',
                            confirmButtonText: 'Close',
                            allowOutsideClick: false,
                            showCloseButton: true
                        }
                    )
                   
                }
            }, error =>{
                Swal.fire(
                    {
                        title: 'Ups',
                        text:'Something went wrong!',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false,
                        showCloseButton: true
                    }
                )
            })
         
    }
    switch (option){
        case 1:
            if(user.name){
                if(detailView){
                    return(
                        <React.Fragment>

                            <FormDetailLastView />
                            <Modal
                                open={zoomModal}
                                onClose={(e)=>{e.preventDefault()}}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                                >
                                <Box sx={{width:900, height:900, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                                    <div className="back-btn-modal">
                                        <div className="back-btn-tf" onClick={(e)=>{
                                            e.preventDefault();
                                            setZoomModal(false);
                                            setZoomImage(null);
                                        }}>
                                            <BackIcon/>
                                        </div>
                                    </div>
                                    <img  className="image-modal-big" src={zoomImage}></img>
                                    <Button
                                        className="cancelBtn" 
                                        variant="contained"
                                        onClick={()=>{
                                            saveAs(zoomImage,'traderoofing-webapp'+new Date()+'.jpeg')
                                        }}
                                    >
                                        Download
                                    </Button>
                                </Box>

                            </Modal>
                        </React.Fragment>
                    )
                }else{
                    return(
                        <div className="selected-option-container home">
                            <div className="content-selected">
                                {
                                    slidersList!==0&&
                                    <div className="slider-container">
                                        <div className="main-b-title"> What is new in Trade Roofing App</div>
                                        <Carousel 
                                            className='Carousel always-show'
                                            indicators={true}
                                            autoPlay
                                            animation='slide'
                                            interval={10000}
                                            duration={2000}
                                            navButtonsAlwaysVisible={true}
                                            //NextIcon={<RigthNavSlider/>}
                                            //PrevIcon={<LeftNavSlider/>}
                                            navButtonsWrapperProps={{className:"btn-wrapper"}}
                                            indicatorIconButtonProps={{
                                                style: {
                                                    padding: '10px',    // 1
                                                    color: 'gray',
                                                    opacity: 0.5       // 3
                                                }
                                            }}
                                        >
                                            {
                                                slidersList.map((item, i) => (
                                                    <article key={'Slider-'+item._id} className="slider-preview" style={{ backgroundImage: item.bg!==''?'url('+item.bg+')':'none'}}>
                                                        <div className="slider-cover"></div>
                                                        <div className="slider-title">{item.title}</div>
                                                        <div className="slider-paragraph">{item.paragraph}</div>
                                                        {
                                                            item.url!==''&&
                                                            <div className="slider-btn">
                                                                <a href={item.url} target="_blank">
                                                                    Go
                                                                </a>
                                                                
                                                            </div>
                                                        }
                                                    </article>
                                                ))
                                            }
                                        </Carousel>
                                    </div>
                                }
                                <div className="last-a-container">
                                    <div className="main-b-title">Active service</div>
                                    <div className="last-activity-finished">
                                        <div className="table-header">
                                            <div className="table-h-label">Property name</div>
                                            <div className="table-h-label">Address</div>
                                            <div className="table-h-label">Type of service</div>
                                            <div className="table-h-label">Status</div>
                                            <div className="table-h-label">Creation Date</div>
                                            <div className="table-h-label"></div>
                                        </div>
                                        <div className="table-content">
                                            {
                                                lastActiveService!==null?(
                                                    <div className="table-row">
                                                        <div className="table-r-label">{lastActiveService.projectData[0].name}</div>
                                                        <div className="table-r-label">{lastActiveService.projectData[0].address}</div>
                                                        <div className="table-r-label">{lastActiveService.serviceType}</div>
                                                        <div className="table-r-label">{lastActiveService.status}</div>
                                                        <div className="table-r-label">{format(new Date(lastActiveService.creationDate), 'dd/MM/yyyy')}</div>
                                                        <div className="table-r-label-f">
                                                            <div className="link-green" onClick={()=>{setDetailView(true); setDetailsData(lastActiveService)}}>
                                                                view details
                                                            </div>
                                                        <GoIcon/>
                                                        </div>
                                                    </div>
                                                ):(

                                                    <div className="table-r-label no-service">There is currently no active service</div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div> 
                                <div className="last-f-container">
                                    <div className="main-b-title">Closed service</div>
                                    <div className="last-activity-finished">
                                        <div className="table-header">
                                            <div className="table-h-label">Property name</div>
                                            <div className="table-h-label">Address</div>
                                            <div className="table-h-label">Type of service</div>
                                            <div className="table-h-label">Status</div>
                                            <div className="table-h-label">Finished Date</div>
                                            <div className="table-h-label"></div>
                                        </div>
                                        <div className="table-content">
                                            {
                                                lastFinishedService!==null?(
                                                    <div className="table-row">
                                                    <div className="table-r-label">{lastFinishedService.projectData[0].name}</div>
                                                    <div className="table-r-label">{lastFinishedService.projectData[0].address}</div>
                                                    <div className="table-r-label">{lastFinishedService.serviceType}</div>
                                                    <div className="table-r-label">{lastFinishedService.status}</div>
                                                    <div className="table-r-label">{format(new Date(lastFinishedService.finishedDate), 'dd/MM/yyyy')}</div>
                                                    <div className="table-r-label-f">
                                                        {
                                                            lastFinishedService.status==='Finished'&&
                                                            <React.Fragment>
                                                                <div className="link-green" onClick={()=>{
                                                                    downloadInvoice(lastFinishedService.invoiceUrl, lastFinishedService.imageReportUrl, lastFinishedService._id)
                                                                }}>
                                                                    get resume
                                                                </div>
                                                                <GoIcon/>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                ):(
                                                    <div className="table-r-label no-service">There is no closed service</div>
                                                )
                                               
                                            }
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    )
                }
            }else{
                return(
                    <div className="selected-option-container">
                        <div className="content-selected centered">
                            <Complete user={user} update={()=>updateUser()}/>
                        </div>
                    </div>  
                )
            }
            break;
        case 2:
            if(!createEditProject){
                return(
                    <MyAccountView />
                )
            }else{
                if(!selectedProject){
                    return(
                        <FormCreateProject />
                    )
                }else{
                    if(editDetailService){
                        return(
                            <React.Fragment>

                                <FormDetailService />
                                <Modal
                                    open={zoomModal}
                                    onClose={(e)=>{e.preventDefault()}}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                                >
                                    <Box sx={{width:900, height:900, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                                        <div className="back-btn-modal">
                                            <div className="back-btn-tf" onClick={(e)=>{
                                                e.preventDefault();
                                                setZoomModal(false);
                                                setZoomImage(null);
                                            }}>
                                                <BackIcon/>
                                            </div>
                                        </div>
                                        <img  className="image-modal-big" src={zoomImage}></img>
                                        <Button
                                            className="cancelBtn" 
                                            variant="contained"
                                            onClick={()=>{
                                                saveAs(zoomImage,'traderoofing-webapp'+new Date()+'.jpeg')
                                            }}
                                        >
                                            Download
                                        </Button>
                                    </Box>
                                </Modal>
                            </React.Fragment>
                        )
                    }else{
                        return(
                            <FormEditProject />
                        )
                    }
                    
                }
                
            }
            break;
        case 3:
            if(calendarEvents===null){
                <div className="selected-option-container">
                    <div className="content-selected">
                        <CircularProgress></CircularProgress>
                    </div>
                </div>
            }else{
                if(detailViewCalendar){
                    return(
                        <React.Fragment>

                            <FormDetailViewCalendar />
                            <Modal
                                open={zoomModal}
                                onClose={(e)=>{e.preventDefault()}}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                            >
                                <Box sx={{width:900, height:900, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                                    <div className="back-btn-modal">
                                        <div className="back-btn-tf" onClick={(e)=>{
                                            e.preventDefault();
                                            setZoomModal(false);
                                            setZoomImage(null);
                                        }}>
                                            <BackIcon/>
                                        </div>
                                    </div>
                                    <img  className="image-modal-big" src={zoomImage}></img>
                                    <Button
                                        className="cancelBtn" 
                                        variant="contained"
                                        onClick={()=>{
                                            saveAs(zoomImage,'traderoofing-webapp'+new Date()+'.jpeg')
                                        }}
                                    >
                                        Download
                                    </Button>
                                </Box>
                            </Modal>
                        </React.Fragment>
                    )
                }else{
                    return(
                        <div className="selected-option-container">
                            <div className="content-selected">
                                <div>
                                    <Calendar
                                    localizer={localizer}
                                    eventPropGetter={event => ({className:'category-'+event.status.toLowerCase()})}
                                    events={calendarEvents}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ height: 500 }}
                                    views={['month']}
                                    onSelectSlot={(slotInfo) => {
                                        setOpenModal(true);
                                        setCalendarPickDate(slotInfo.start);
                                    }}
                                    onSelectEvent={(data)=>{
                                        setDetailsData(data.data);
                                        setSelectedProject(data.data.projectData[0])
                                        setDetailViewCalendar(true);
                                    }}
                                    selectable
                                    popup={true}
                                    />
                                </div>
                                <div className="markers">
                                    <div className="marker-container">
                                        <div className="marker-color category-pending"></div>
                                        <div className='marker-label'>Pending</div>
                                    </div>
                                    <div className="marker-container">
                                        <div className="marker-color category-scheduled"></div>
                                        <div className='marker-label'>Scheduled</div>
                                    </div>
                                    <div className="marker-container">
                                        <div className="marker-color category-proposal"></div>
                                        <div className='marker-label'>Proposal</div>
                                    </div>
                                    <div className="marker-container">
                                        <div className="marker-color category-underway"></div>
                                        <div className='marker-label'>Working on it</div>
                                    </div>
                                    <div className="marker-container">
                                        <div className="marker-color category-finished"></div>
                                        <div className='marker-label'>Finished</div>
                                    </div>
                                    <div className="marker-container">
                                        <div className="marker-color category-canceled"></div>
                                        <div className='marker-label'>Canceled</div>
                                    </div>
                                </div>
                            </div>

                            <Modal
                                open={openModal}
                                onClose={(e)=>{e.preventDefault()}}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                                >
                                <Box sx={{width:700, height:740, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                                    <div className="back-btn-modal">
                                        <div className="back-btn-tf" onClick={(e)=>{
                                            e.preventDefault();
                                            setOpenModal(false)
                                        }}>
                                            <BackIcon/>
                                        </div>
                                    </div>
                                    <CalendarBook socketWs={socket} userL={user} dateL={calendarPickDate} close={()=>closeModal()}></CalendarBook>
                                </Box>
                            </Modal>
                        </div>
                        
                    )
                }
            }
            break;
        case 4:
            if(detailView){
                return(
                    <React.Fragment>
                        <FormDetailView />
                        <Modal
                            open={zoomModal}
                            onClose={(e)=>{e.preventDefault()}}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                        >
                            <Box sx={{width:900, height:900, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                                <div className="back-btn-modal">
                                    <div className="back-btn-tf" onClick={(e)=>{
                                        e.preventDefault();
                                        setZoomModal(false);
                                        setZoomImage(null);
                                    }}>
                                        <BackIcon/>
                                    </div>
                                </div>
                                <img  className="image-modal-big" src={zoomImage}></img>
                                <Button
                                    className="cancelBtn" 
                                    variant="contained"
                                    onClick={()=>{
                                        saveAs(zoomImage,'traderoofing-webapp'+new Date()+'.jpeg')
                                    }}
                                >
                                    Download
                                </Button>
                            </Box>
                        </Modal>
                    </React.Fragment>
                )
            }else{

                return(
                    <div className="selected-option-container">
                        <div className="content-selected">
                            <div className="bottom-menu-selected">
                                <div className="header-selected">
                                    <div className="selected-title">My services</div>
                                    <div className="resultSortBtn">
                                        <div className="sortSelect">
                                            <Box sx={{backgroundColor:'#000000',borderRadius:10, height:40, width:200}}>
                                                <FormControl >
                                                    <FormHelperText>Order by: </FormHelperText>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={sortServices}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onChange={(e)=>{
                                                            setSortServices(e.target.value);
                                                            sortSwitcherActive(e.target.value)}}
                                                        >
                                                        <MenuItem value={0}className="emptyVal">Type of service
                                                        </MenuItem>
                                                        <MenuItem value={1} className="selectedVal">Property name</MenuItem>
                                                        <MenuItem value={2} className="selectedVal">Status</MenuItem>
                                                        <MenuItem value={3} className="selectedVal">Date</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                                <div className="body-selected">
                                <div className="finderTop">
                                    <Box sx={{backgroundColor:'#ffffff',borderRadius:10, height:40, width:340, position:'relative' }}>
                                        <TextField className="login-input" id="input-with-sx"  placeholder="Search" onChange={(e)=>{e.preventDefault(); filterServices(e.target.value)}}/>
                                        <IconButton className='search-icon'color="primary" aria-label="upload picture" component="span" onClick={(e)=>{}}>
                                            <Search sx={{ color: '#000000', mr: 1, my: 0.5 }} />
                                        </IconButton>
                                    </Box>
                                </div>
                                    <div className="list-container">
                                        {
                                            processForms?(
                                                <InProcessList data={processFilteredForms!==null?processFilteredForms:processForms}/>
                                            ):(
                                                <div className="table-r-label no-services">There is currently no active services</div>
                                            )
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            break;
        case 5:

            return(
                <div className="selected-option-container">
                    <div className="content-selected">
                        <div className="bottom-menu-selected">
                            <div className="header-selected">
                                <div className="selected-title">Requests</div>
                                <div className="resultSortBtn">
                                    <div className="sortSelect">
                                        <Box sx={{backgroundColor:'#000000',borderRadius:10, height:40, width:200}}>
                                            <FormControl >
                                                <FormHelperText>Order by: </FormHelperText>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={sortRequest}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    onChange={(e)=>{
                                                        setSortRequest(e.target.value);
                                                        sortSwitcherRequest(e.target.value)}}
                                                    >
                                                    <MenuItem value={0}className="emptyVal">Type of request
                                                    </MenuItem>
                                                    <MenuItem value={1} className="selectedVal">Date</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                            <div className="body-selected">
                            <div className="finderTop">
                                <Box sx={{backgroundColor:'#ffffff',borderRadius:10, height:40, width:340, position:'relative' }}>
                                    <TextField className="login-input" id="input-with-sx"  placeholder="Search" onChange={(e)=>{e.preventDefault(); filterRequestList(e.target.value)}}/>
                                    <IconButton className='search-icon'color="primary" aria-label="upload picture" component="span" onClick={(e)=>{}}>
                                        <Search sx={{ color: '#000000', mr: 1, my: 0.5 }} />
                                    </IconButton>
                                </Box>
                            </div>
                                <div className="list-container">
                                    {
                                        requestUserList?(
                                            <RequestUserList data={requestUserFilteredList!==null?requestUserFilteredList:requestUserList}/>
                                        ):(
                                            <div className="table-r-label no-services">There are no active requests</div>
                                        )
                                    }
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            )
        break;
        case 6:
            if(finishedDetailView){
                return(
                    <FormDetailFinishedView />
                )
            }else{
                return(
                    <div className="selected-option-container">
                    <div className="content-selected">
                        <div className="bottom-menu-selected">
                            <div className="header-selected">
                                <div className="selected-title">History</div>
                                <div className="resultSortBtn">
                                    <div className="sortSelect">
                                        <Box sx={{backgroundColor:'#000000',borderRadius:10, height:40, width:200}}>
                                            <FormControl >
                                                <FormHelperText>Order by: </FormHelperText>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={sortFinished}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    onChange={(e)=>{
                                                        setSortFinished(e.target.value);
                                                        sortSwitcherFinished(e.target.value);
                                                        }
                                                    }
                                                        
                                                >
                                                    <MenuItem value={0} className="emptyVal">Type of service
                                                    </MenuItem>
                                                    <MenuItem value={1} className="selectedVal">Property name</MenuItem>
                                                    <MenuItem value={2} className="selectedVal">Status</MenuItem>
                                                    <MenuItem value={3} className="selectedVal">Date</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                            <div className="body-selected">
                                <div className="finderTop">
                                    <Box sx={{backgroundColor:'#ffffff',borderRadius:10, height:40, width:340, position:'relative' }}>
                                        <TextField className="login-input" id="input-with-sx"  placeholder="Search" onChange={(e)=>{e.preventDefault(); filterServicesFinished(e.target.value)}}/>
                                        <IconButton className='search-icon'color="primary" aria-label="upload picture" component="span" onClick={(e)=>{}}>
                                            <Search sx={{ color: '#000000', mr: 1, my: 0.5 }} />
                                        </IconButton>
                                    </Box>
                                </div>
                                <div className="list-container">
                                        {
                                            finishedForms ?(
                                                <FinishedList data={finishedFilteredForms!==null?finishedFilteredForms:finishedForms}/>
                                            ):(
                                                <div className="table-r-label no-services">There are no finalized servies</div>
                                            )
                                        }
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        break;
        case 7:
            return(
                <div className="selected-option-container">
                <div className="content-selected">
                    <div className="main-b-title">Learn how to use our booking app</div>
                    <div className="youtube-container">
                        <YouTube videoId="tAbafxhaIBg" opts={opts} onReady={_onReady} />
                    </div>
                </div>
            </div>
            )
        break;
        case 8:
            return(
                <div className="selected-option-container">
                    <div className="content-selected">
                        <div className="main-b-title">Do you need to contact one of our services?</div>
                        <div className="info-container">
                            <div className="main-b-sub-title">Sales team</div>
                            <div className="sales content-info">
                                <div className="email-data">
                                    <div className="data-label">Send us an email</div>
                                    <div className="data-content">contact@traderoofing.com</div>
                                    <div className="svg-container">
                                        <img src={dollarSvg}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-container">
                            <div className="main-b-sub-title">Production team</div>
                            <div className="production content-info">
                                <div className="email-data">
                                    <div className="data-label">Send us an email</div>
                                    <div className="data-content">production@traderoofing.com</div>
                                    <div className="svg-container">
                                        <img src={engineSvg}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-container">
                            <div className="main-b-sub-title">Accounting</div>
                            <div className="accounting content-info">
                                <div className="email-data">
                                    <div className="data-label">Send us an email</div>
                                    <div className="data-content">accounting@traderoofing.com</div>
                                    <div className="svg-container">
                                        <img src={calculatorSvg}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-container">
                            <div className="main-b-sub-title center">To access any department call:</div>
                            <div className="phone content-info">
                                <div className="email-data">
                                    <div className="phone-contact-label">
                                        <a href='tel:2063398545'> {'(206) 339-8545'}</a>
                                    </div>
                                    <div className="svg-container phone">
                                        <img src={phoneSvg}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        break;
        case 9:
            if(dashBoard===null){
                <div className="selected-option-container">
                    <div className="content-selected">
                        <CircularProgress></CircularProgress>
                    </div>
                </div>
            }else{
                return(
                    <div className="selected-option-container">
                        <div className="content-selected">
                            <div className="manager-dashboard">
                                <div className="manager-card"
                                    onClick={(e)=>{e.preventDefault();menuController(10)}}
                                >
                                    <div className="manager-card-bg"></div>
                                    <div className="manager-label">Registered Users</div>
                                    <div className="manager-counter">{dashBoard.activeUsers.length}</div>
                                    
                                </div>
                                <div className="manager-card"
                                    onClick={(e)=>{e.preventDefault();menuController(10)}}
                                >
                                    <div className="manager-card-bg"></div>
                                    <div className="manager-label">Active Users</div>
                                    <div className="manager-counter">{dashBoard.activeUsers.length}</div>
                                </div>
                                <div className="manager-card"
                                    onClick={(e)=>{e.preventDefault();menuController(13)}}
                                >
                                    <div className="manager-card-bg"></div>
                                    <div className="manager-label">W9 Requests</div>
                                    <div className="manager-counter">{dashBoard.w9.length}</div>
                                </div>
                                <div className="manager-card"
                                    onClick={(e)=>{e.preventDefault();menuController(13)}}
                                >
                                    <div className="manager-card-bg"></div>
                                    <div className="manager-label">COI Requests</div>
                                    <div className="manager-counter">{dashBoard.coi.length}</div>
                                </div>
                                <div className="manager-card"
                                    onClick={(e)=>{e.preventDefault();menuController(11)}}
                                >
                                    <div className="manager-card-bg"></div>
                                    <div className="manager-label">Active Services</div>
                                    <div className="manager-counter">{dashBoard.services.length}</div>
                                </div>
                                <div className="manager-card"
                                    onClick={(e)=>{e.preventDefault();menuController(11)}}
                                >
                                    <div className="manager-card-bg"></div>
                                    <div className="manager-label">Pending Inspections</div>
                                    <div className="manager-counter">{dashBoard.inspections.length}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )    
            }
            
        break;
        case 10:
            if(detailUsersView){
                if(!selectedUserViewProject){
                    return(
                        <React.Fragment>
                            <UserAccountView />
                            <Modal
                                open={newPropertyModal}
                                onClose={(e)=>{e.preventDefault(); setNewPropertyModal(false)}}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                            >
                                <Box sx={{width:400, height:750, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                                    <div className="back-btn-modal">
                                        <div className="back-btn-tf" onClick={(e)=>{
                                            e.preventDefault();
                                            setNewPropertyModal(false);
                                        }}>
                                            <BackIcon/>
                                        </div>
                                    </div>
                                    <div className="step step-1">
                                        <div className="stepBtn">
                                            <div className="back-header">
                                            </div>
                                        </div>
                                        <div className="step-header"> Create property</div>
                                        <div className="step-form">
                                            <form className="form">
                                                <div className="form-content">
                                                    <div className="form-group">
                                                        <TextField
                                                            className="login-input" 
                                                            id="outlined-basic" 
                                                            label="Property name" 
                                                            variant="outlined"
                                                            value={projectNameM}
                                                            required
                                                            error={projectErrorM}
                                                            helperText={projectErrorM?('This field is required.'):('')}
                                                            onChange={(e)=>{setProjectNameM(e.target.value);if(projectErrorM)setProjectErrorM(false)}}
                                                        />
                                                    </div>
                                                
                                                    <div className="form-group">
                                                        <TextField
                                                            className="login-input" 
                                                            id="outlined-basic" 
                                                            label="Property address" 
                                                            variant="outlined"
                                                            required
                                                            value={projectAddressM}
                                                            error={addressErrorM}
                                                            helperText={addressErrorM?('This field is required.'):('')}
                                                            onChange={(e)=>{setProjectAddressM(e.target.value);if(addressErrorM)setAddressErrorM(false)}}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <TextField
                                                            className="login-input" 
                                                            id="outlined-basic" 
                                                            label="Onsite contact name" 
                                                            variant="outlined"
                                                            value={contactM}
                                                            onChange={(e)=>{setContactM(e.target.value);}}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <TextField
                                                            className="login-input" 
                                                            id="outlined-basic" 
                                                            label="Onsite contact phone" 
                                                            variant="outlined"
                                                            value={contactPhoneM}
                                                            onChange={(e)=>{setContactPhoneM(e.target.value);}}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <Autocomplete
                                                            className="autocomplete-full login-input" 
                                                            options={roofTypesM}
                                                            value={roofTypeM}
                                                        
                                                            onChange={(event, newValue) => {
                                                                console.log(newValue)
                                                                setRoofTypeM(newValue===null?'':newValue.label);
                                                                if(projectTypeErrorM)setProjectTypeErrorM(false);
                                                            }}
                                                            renderInput={(params)=> <TextField 
                                                                {...params} 
                                                                label="Roof Type" 
                                                                className="login-input" 
                                                                id="outlined-basic" 
                                                                variant="outlined"
                                                                error={projectTypeErrorM}
                                                                helperText={projectTypeErrorM?('This field is required.'):('')}
                                                                >
                                                                </TextField>
                                                            }
                                                        />
                                                        
                                                    </div>
                                                    <div className="form-group">
                                                        <Autocomplete
                                                            className="autocomplete-full login-input" 
                                                            options={roofAccesssM}
                                                            value={roofAccessM}
                                                        
                                                            onChange={(event, newValue) => {
                                                                console.log(newValue)
                                                                setRoofAccessM(newValue===null?'':newValue.label);
                                                                if(projectAccessErrorM)setProjectAccessErrorM(false);
                                                            }}
                                                            renderInput={(params)=> <TextField 
                                                                {...params} 
                                                                label="Roof access" 
                                                                className="login-input" 
                                                                id="outlined-basic" 
                                                                variant="outlined" 
                                                                required 
                                                                error={projectAccessErrorM}
                                                                helperText={projectAccessErrorM?('This field is required.'):('')}
                                                                >
                                                                </TextField>
                                                            }
                                                        />
                                                        
                                                        
                                                    </div>
                                                    <div className="form-group">
                                                        <TextField
                                                            className="login-input multiline" 
                                                            multiline
                                                            maxRows={2}
                                                            id="outlined-basic" 
                                                            label="Note" 
                                                            variant="outlined"
                                                            onChange={(e)=>{setNoteM(e.target.value)}}
                                                            value={noteM}
                                                        />
                                                    </div>
                                                </div>

                                        

                                            </form>  
                                        </div>
                                    </div> 
                                    <Button
                                        className="cancelBtn" 
                                        variant="contained"
                                        onClick={()=>{
                                            if(projectNameM==='')setProjectErrorM(true);
                                            if(projectAddressM==='')setAddressErrorM(true);
                                            if(roofAccessM==='')setProjectAccessErrorM(true);
                                            if(projectNameM===''|| projectAddressM==='' || roofAccessM===''){
                                                return
                                            }else{

                                                saveProjectManager();
                                            }
                                        }}
                                    >
                                        Confirm
                                    </Button>
                                </Box>

                            </Modal>
                        </React.Fragment>
                    )
                }else{
                    if(editDetailServiceUserView){
                        return(
                            <React.Fragment>
                                <FormDetailServiceUser />
                                <Modal
                                    open={zoomModal}
                                    onClose={(e)=>{e.preventDefault()}}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                                >
                                    <Box sx={{width:800, height:800, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                                        <div className="back-btn-modal">
                                            <div className="back-btn-tf" onClick={(e)=>{
                                                e.preventDefault();
                                                setZoomModal(false);
                                                setZoomImage(null);
                                            }}>
                                                <BackIcon/>
                                            </div>
                                        </div>
                                        <img  className="image-modal-big" src={zoomImage}></img>
                                        <Button
                                            className="cancelBtn" 
                                            variant="contained"
                                            onClick={()=>{
                                                saveAs(zoomImage,'traderoofing-webapp'+new Date()+'.jpeg')
                                            }}
                                        >
                                            Download
                                        </Button>
                                    </Box>
                                </Modal>
                            </React.Fragment>
                        )
                    }else{
                        return(
                            <React.Fragment>
                                <FormPropertyDetails />
                                <Modal
                                    open={newServiceModal}
                                    onClose={(e)=>{e.preventDefault(); setNewServiceModal(false)}}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                                >
                                    <Box sx={{width:400, height:850, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                                        <div className="back-btn-modal">
                                            <div className="back-btn-tf" onClick={(e)=>{
                                                e.preventDefault();
                                                setNewServiceModal(false);
                                            }}>
                                                <BackIcon/>
                                            </div>
                                        </div>
                                        {
                                            selectedUserProject&&
                                            <div className="step step-1">
                                                <div className="stepBtn">
                                                    <div className="back-header">
                                                    </div>
                                                </div>
                                                <div className="step-header"> Create service</div>
                                                <div className="step-form">
                                                    <form className="form">
                                                        <div className="form-content">
                                                            <div className="form-group">
                                                                <TextField
                                                                    disabled
                                                                    id="outlined-basic"
                                                                    className='login-input'
                                                                    label="Property Name" 
                                                                    variant="outlined"
                                                                    value={selectedUserProject.name}
                                                                    fullWidth
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <TextField
                                                                    disabled
                                                                    id="outlined-basic"
                                                                    className='login-input'
                                                                    label="Roof type" 
                                                                    variant="outlined"
                                                                    value={selectedUserProject.type}
                                                                    fullWidth
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <TextField
                                                                    disabled
                                                                    id="outlined-basic"
                                                                    className='login-input'
                                                                    label="Roof Access" 
                                                                    variant="outlined"
                                                                    value={selectedUserProject.access}
                                                                    fullWidth
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <TextField
                                                                    disabled
                                                                    id="outlined-basic"
                                                                    className='login-input'
                                                                    label="Property Address" 
                                                                    variant="outlined"
                                                                    value={selectedUserProject.address}
                                                                    fullWidth
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <Autocomplete
                                                                    className="autocomplete-full login-input" 
                                                                    options={roofServicesM}
                                                                    value={roofServiceM}
                                                                
                                                                    onChange={(event, newValue) => {
                                                                        console.log(newValue)
                                                                        setRoofServiceM(newValue===null?'':newValue.label);
                                                                        if(projectServiceErrorM)setProjectServiceErrorM(false);
                                                                    }}
                                                                    renderInput={(params)=> <TextField 
                                                                        {...params} 
                                                                        label="Service" 
                                                                        className="login-input" 
                                                                        id="outlined-basic" 
                                                                        variant="outlined" 
                                                                        required 
                                                                        error={projectServiceErrorM}
                                                                        helperText={projectServiceErrorM?('This field is required.'):('')}
                                                                        >
                                                                        </TextField>
                                                                    }
                                                                />
                                                                
                                                                
                                                            </div>
                                                            <div className="form-group">
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DatePicker
                                                                        label="Inspection Date"
                                                                        value={sDateM}
                                                                        onChange={(newValue) => {
                                                                            setSDateM(newValue);
                                                                        }}
                                                                        renderInput={(params) => <TextField required className='login-input' fullWidth {...params} />}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                            <div className="form-group area">
                                                                <textarea className="text-area-tf" placeholder='Note' maxLength={250} value={serviceNoteM} onChange={(e)=>{e.preventDefault(); setServiceNoteM(e.target.value)}} />
                                                            </div> 
                                                        </div>
                                    
                                                    </form>
                                                </div>
                                            </div> 
                                        }
                                        <Button
                                            className="cancelBtn" 
                                            variant="contained"
                                            onClick={()=>{
                                                saveFormManager();
                                            }}
                                        >
                                            Confirm
                                        </Button>
                                    </Box>

                                </Modal>
                            </React.Fragment>
                        )
                    }
                    
                }
            }else{
                return(
                    <div className="selected-option-container">
                        <div className="content-selected">
                            <div className="bottom-menu-selected">
                                <div className="header-selected">
                                    <div className="selected-title">Users list</div>
                                    <div className="resultSortBtn">
                                        <div className="sortSelect">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="body-selected">
                                <div className="finderTop">
                                    <Box sx={{backgroundColor:'#ffffff',borderRadius:10, height:40, width:340, position:'relative' }}>
                                        <TextField className="login-input" id="input-with-sx"  placeholder="Search" onChange={(e)=>{e.preventDefault(); filterUsers(e.target.value)}}/>
                                        <IconButton className='search-icon'color="primary" aria-label="upload picture" component="span" onClick={(e)=>{}}>
                                            <Search sx={{ color: '#000000', mr: 1, my: 0.5 }} />
                                        </IconButton>
                                    </Box>
                                </div>
                                    <div className="list-container">
                                        {
                                            usersList?(
                                                <UsersList data={usersFilteredList!==null?usersFilteredList:usersList}/>
                                            ):(
                                                <div>Cargando....</div>
                                            )
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        break;
        case 11:
            if(detailServiceManagerView){
                return(
                    <React.Fragment>
                        <FormDetailViewManager />
                        <Modal
                            open={zoomModal}
                            onClose={(e)=>{e.preventDefault()}}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                        >
                            <Box sx={{width:900, height:900, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                                <div className="back-btn-modal">
                                    <div className="back-btn-tf" onClick={(e)=>{
                                        e.preventDefault();
                                        setZoomModal(false);
                                        setZoomImage(null);
                                    }}>
                                        <BackIcon/>
                                    </div>
                                </div>
                                <img  className="image-modal-big" src={zoomImage}></img>
                            </Box>
                            
                        </Modal>
                    </React.Fragment>
                )
            }else{

                return(
                    <div className="selected-option-container">
                        <div className="content-selected">
                            <div className="bottom-menu-selected">
                                <div className="header-selected">
                                    <div className="selected-title">Services</div>
                                    <div className="resultSortBtn">
                                        <div className="sortSelect">
                                            <Box sx={{backgroundColor:'#000000',borderRadius:10, height:40, width:200}}>
                                                <FormControl >
                                                    <FormHelperText>Order by: </FormHelperText>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={sortServicesManager}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onChange={(e)=>{
                                                            setSortServicesManager(e.target.value);
                                                            sortSwitcherUsersServices(e.target.value)}}
                                                        >
                                                        <MenuItem value={0}className="emptyVal">Type of service
                                                        </MenuItem>
                                                        <MenuItem value={1} className="selectedVal">Property name</MenuItem>
                                                        <MenuItem value={2} className="selectedVal">Status</MenuItem>
                                                        <MenuItem value={3} className="selectedVal">Date</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                                <div className="body-selected">
                                <div className="finderTop">
                                    <Box sx={{backgroundColor:'#ffffff',borderRadius:10, height:40, width:340, position:'relative' }}>
                                        <TextField className="login-input" id="input-with-sx"  placeholder="Search" onChange={(e)=>{e.preventDefault(); filterServicesList(e.target.value)}}/>
                                        <IconButton className='search-icon'color="primary" aria-label="upload picture" component="span" onClick={(e)=>{}}>
                                            <Search sx={{ color: '#000000', mr: 1, my: 0.5 }} />
                                        </IconButton>
                                    </Box>
                                </div>
                                    <div className="list-container">
                                        {
                                            servicesList?(
                                                <ServicesList data={servicesFilteredList!==null?servicesFilteredList:servicesList}/>
                                            ):(
                                                <div>Cargando....</div>
                                            )
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        break;
        case 12:
            if(detailFinishedManagerView){
                return(
                    <React.Fragment>
                        <FormDetailViewManagerFinished />
                        <Modal
                            open={zoomModal}
                            onClose={(e)=>{e.preventDefault()}}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                        >
                            <Box sx={{width:900, height:900, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                                <div className="back-btn-modal">
                                    <div className="back-btn-tf" onClick={(e)=>{
                                        e.preventDefault();
                                        setZoomModal(false);
                                        setZoomImage(null);
                                    }}>
                                        <BackIcon/>
                                    </div>
                                </div>
                                <img  className="image-modal-big" src={zoomImage}></img>
                                <Button
                                    className="cancelBtn" 
                                    variant="contained"
                                    onClick={()=>{
                                        saveAs(zoomImage,'traderoofing-webapp'+new Date()+'.jpeg')
                                    }}
                                >
                                    Download
                                </Button>
                            </Box>
                        </Modal>
                    </React.Fragment>
                )
            }else{

                return(
                    <div className="selected-option-container">
                        <div className="content-selected">
                            <div className="bottom-menu-selected">
                                <div className="header-selected">
                                    <div className="selected-title">History</div>
                                    <div className="resultSortBtn">
                                        <div className="sortSelect">
                                            <Box sx={{backgroundColor:'#000000',borderRadius:10, height:40, width:200}}>
                                                <FormControl >
                                                    <FormHelperText>Order by: </FormHelperText>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={sortHistoryManager}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onChange={(e)=>{
                                                            setSortHistoryManager(e.target.value);
                                                            sortSwitcherUsersHistory(e.target.value)}}
                                                        >
                                                        <MenuItem value={0}className="emptyVal">Type of service
                                                        </MenuItem>
                                                        <MenuItem value={1} className="selectedVal">Property name</MenuItem>
                                                        <MenuItem value={2} className="selectedVal">Status</MenuItem>
                                                        <MenuItem value={3} className="selectedVal">Date</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                                <div className="body-selected">
                                <div className="finderTop">
                                    <Box sx={{backgroundColor:'#ffffff',borderRadius:10, height:40, width:340, position:'relative' }}>
                                        <TextField className="login-input" id="input-with-sx"  placeholder="Search" onChange={(e)=>{e.preventDefault(); filterFinishedList(e.target.value)}}/>
                                        <IconButton className='search-icon'color="primary" aria-label="upload picture" component="span" onClick={(e)=>{}}>
                                            <Search sx={{ color: '#000000', mr: 1, my: 0.5 }} />
                                        </IconButton>
                                    </Box>
                                </div>
                                    <div className="list-container">
                                        {
                                            finishedList?(
                                                <FinishedManagerList data={finishedFilteredList!==null?finishedFilteredList:finishedList}/>
                                            ):(
                                                <div>Cargando....</div>
                                            )
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        break;
        case 13:

                return(
                    <div className="selected-option-container">
                        <div className="content-selected">
                            <div className="bottom-menu-selected">
                                <div className="header-selected">
                                    <div className="selected-title">Requests</div>
                                    <div className="resultSortBtn">
                                        <div className="sortSelect">
                                            <Box sx={{backgroundColor:'#000000',borderRadius:10, height:40, width:200}}>
                                                <FormControl >
                                                    <FormHelperText>Order by: </FormHelperText>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={sortRequest}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onChange={(e)=>{
                                                            setSortRequest(e.target.value);
                                                            sortSwitcherRequest(e.target.value)}}
                                                        >
                                                        <MenuItem value={0}className="emptyVal">Type of request
                                                        </MenuItem>
                                                        <MenuItem value={1} className="selectedVal">Date</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                                <div className="body-selected">
                                <div className="finderTop">
                                    <Box sx={{backgroundColor:'#ffffff',borderRadius:10, height:40, width:340, position:'relative' }}>
                                        <TextField className="login-input" id="input-with-sx"  placeholder="Search" onChange={(e)=>{e.preventDefault(); filterRequestList(e.target.value)}}/>
                                        <IconButton className='search-icon'color="primary" aria-label="upload picture" component="span" onClick={(e)=>{}}>
                                            <Search sx={{ color: '#000000', mr: 1, my: 0.5 }} />
                                        </IconButton>
                                    </Box>
                                </div>
                                    <div className="list-container">
                                        {
                                            requestList?(
                                                <RequestManagerList data={requestFilteredList!==null?requestFilteredList:requestList}/>
                                            ):(
                                                <div>Cargando....</div>
                                            )
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            coiData&&
                            <Modal
                                open={coiMOdal}
                                onClose={(e)=>{e.preventDefault()}}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                            >
                            <Box sx={{width:400, height:400, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                                <div className="back-btn-modal">
                                    <div className="back-btn-tf" onClick={(e)=>{
                                        e.preventDefault();
                                        setCoiModal(false);
                                    }}>
                                        <BackIcon/>
                                    </div>
                                </div>
                                <form className="form">
                                    <div className="form-content">
                                        <div className="form-group">
                                            <TextField
                                                disabled
                                                id="outlined-basic"
                                                className='login-input'
                                                label="Roof access" 
                                                variant="outlined"
                                                value={coiData.ownerName!==undefined?coiData.ownerName:''}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                disabled
                                                id="outlined-basic"
                                                className='login-input'
                                                label="Property address" 
                                                variant="outlined"
                                                value={coiData.address!==undefined?coiData.address:''}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="form-group-text-area">
                                            <textarea
                                                className="text-area-detail"
                                                disabled 
                                                id="outlined-basic" 
                                                label="Nota" 
                                                variant="outlined"
                                                
                                                multiline="true"
                                                value={coiData.note}
                                            />
                                        </div>
                                       
                                    </div>
                                </form>
                                <Button
                                    className="cancelBtn" 
                                    variant="contained"
                                    onClick={()=>{
                                       updateRequestCOI(coiData);
                                    }}
                                >
                                    Upload file
                                </Button>
                            </Box>
                        </Modal>
                        }
                        
                    </div>
                )
        break;
        case 14:
            if(detailManagersView){
                return(
                    <ManagerAccountView />
                )
               
            }else{

                return(
                    <div className="selected-option-container">
                        <div className="content-selected">
                            <div className="bottom-menu-selected">
                                <div className="header-selected">
                                    <div className="selected-title">Managers list</div>
                                    <div className="resultSortBtn">
                                        <div className="sortSelect">
                                            <div className="btn-cancel">
                                                <Button 
                                                    className="cancelBtn" 
                                                    variant="contained"
                                                    onClick={()=>{
                                                        setCreateAdmin(true)
                                                    }}
                                                >
                                                    Create Manager
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="body-selected">
                                <div className="finderTop">
                                    <Box sx={{backgroundColor:'#ffffff',borderRadius:10, height:40, width:340, position:'relative' }}>
                                        <TextField className="login-input" id="input-with-sx"  placeholder="Search" onChange={(e)=>{e.preventDefault(); filterUsers(e.target.value)}}/>
                                        <IconButton className='search-icon'color="primary" aria-label="upload picture" component="span" onClick={(e)=>{}}>
                                            <Search sx={{ color: '#000000', mr: 1, my: 0.5 }} />
                                        </IconButton>
                                    </Box>
                                </div>
                                    <div className="list-container">
                                        {
                                            managersList?(
                                                <ManagerList data={managersFilteredList!==null?managersFilteredList:managersList}/>
                                            ):(
                                                <div>Cargando....</div>
                                            )
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal
                            open={createAdmin}
                            onClose={(e)=>{e.preventDefault(); setCreateAdmin(false)}}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                            >
                            <Box sx={{width:400, height:450, backgroundColor:'white', display:'flex', borderRadius:2, position:'relative'}}>
                                <div className="back-btn-modal">
                                    <div className="back-btn-tf" onClick={(e)=>{
                                        e.preventDefault();
                                        setCreateAdmin(false);
                                    }}>
                                        <BackIcon/>
                                    </div>
                                </div>
                                <form className="form">
                                    <div className="form-content">
                                        <div className="form-group">
                                            <TextField
                                                className="login-input" 
                                                id="outlined-basic" 
                                                label="Manager name" 
                                                variant="outlined"
                                                value={managerName}
                                                required
                                                error={managerError}
                                                helperText={managerError?('This field is required.'):('')}
                                                onChange={(e)=>{setManagerName(e.target.value);if(managerError)setManagerError(false)}}
                                            />
                                        </div>
                                        
                                        <div className="form-group">
                                            <TextField
                                                className="login-input" 
                                                id="outlined-basic" 
                                                label="Manager email" 
                                                variant="outlined"
                                                required
                                                value={managerMail}
                                                error={managerMailError}
                                                helperText={managerMailError?('This field is required.'):('')}
                                                onChange={(e)=>{setManagerMail(e.target.value);if(managerMailError)setManagerMailError(false)}}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField
                                                className="login-input" 
                                                id="outlined-basic" 
                                                label="Password" 
                                                variant="outlined"
                                                required
                                                value={managerPass}
                                                error={managerPassError}
                                                helperText={managerPassError?('This field is required.'):('')}
                                                onChange={(e)=>{setManagerPass(e.target.value);if(managerPassError)setManagerPassError(false)}}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <FormControl>
                                                <FormLabel id="demo-row-radio-buttons-group-label">Manager Type</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    value={managerType}
                                                    onChange={(e)=>{debugger;setManagerType(e.target.value)}}
                                                >
                                                    <FormControlLabel value="0" control={<Radio />} label="Super Manager" />
                                                    <FormControlLabel value="1" control={<Radio />} label="Manager" />
                                                  
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                </form>  
                                <Button
                                    className="cancelBtn" 
                                    variant="contained"
                                    onClick={()=>{
                                        if(managerPass==='')setManagerPassError(true);
                                        if(managerName==='')setManagerError(true);
                                        if(managerMail==='')setManagerMailError(true);
                                        if(managerPass===''|| managerMail==='' || managerName===''){
                                            return
                                        }else{

                                            saveManagerForm();
                                        }
                                    }}
                                >
                                    Confirm
                                </Button>
                            </Box>

                        </Modal>
                    </div>
                )
            }
        break;
        case 15:
            return(
                <React.Fragment>
                     <div className="selected-option-container">
                        <div className="content-selected">
                            <div className="header-selected">
                                <div className="selected-title">Sliders list</div>
                            </div>
                            <div className="body-selected">
                                {
                                    slidersList.length!==0&&
                                    <div className="current-sliders">
                                        <div className="main-b-sub-title">Current sliders</div>
                                        <div className="sliders-container">
                                        {

                                            slidersList.map((item,i)=>(
                                                <article key={'Slider-'+item._id} className="slider-preview" style={{ backgroundImage: item.bg!==''?'url('+item.bg+')':'none'}}>
                                                     <div className="delete-icon slider"
                                                        onClick={(e)=>{e.preventDefault();deleteSlider(item._id)}}
                                                    >
                                                        <DeleteIcon
                                                            sx={{color:"white", fontSize:22}}
                                                        ></DeleteIcon>
                                                    </div>
                                                    <div className="slider-cover"></div>
                                                    <div className="slider-title">{item.title}</div>
                                                    <div className="slider-paragraph">{item.paragraph}</div>
                                                    {
                                                        item.url!==''&&
                                                        <div className="slider-btn">
                                                            <a href={item.url} target="_blank">
                                                                Go
                                                            </a>
                                                            
                                                        </div>
                                                    }
                                                </article>
                                            ))
                                        }
                                       </div>
                                    </div>
                                }
                                
                                <div className="new-slider" onClick={()=>setNewSlider(true)}>+</div>
                                {
                                    newSlider &&
                                    <div className="new-slider-content">
                                        <div className="main-b-sub-title">Slider preview</div>
                                        <div className="slider-preview" style={{ backgroundImage: sliderBg!==''?'url('+sliderBg+')':'none'}}>
                                            <div className="slider-cover"></div>
                                            <div className="slider-title">{sliderTitle}</div>
                                            <div className="slider-paragraph">{sliderP}</div>
                                            {
                                                sliderUrl!==''&&
                                                <div className="slider-btn">
                                                    <a href={sliderUrl} target="_blank">
                                                        Go
                                                    </a>
                                                    
                                                </div>
                                            }
                                        </div>
                                        <div className="form slider-form">
                                            <div className="form-content">
                                                <div className="form-group">
                                                    <TextField
                                                        className="login-input"
                                                        id="outlined-basic" 
                                                        label="Title" 
                                                        variant="outlined"
                                                        onChange={(e)=>{setSliderTitle(e.target.value);}}
                                                        value={sliderTitle}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <TextField
                                                        className="login-input"
                                                        id="outlined-basic" 
                                                        label="Paragraph" 
                                                        variant="outlined"
                                                        onChange={(e)=>{setSliderP(e.target.value);}}
                                                        value={sliderP}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <TextField
                                                        className="login-input"
                                                        id="outlined-basic" 
                                                        label="Url" 
                                                        variant="outlined"
                                                        onChange={(e)=>{setSliderUrl(e.target.value);}}
                                                        value={sliderUrl}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <TextField
                                                        className="login-input"
                                                        id="outlined-basic" 
                                                        label="Background-img" 
                                                        variant="outlined"
                                                        onChange={(e)=>{setSliderBg(e.target.value);}}
                                                        value={sliderBg}
                                                    />
                                                </div>
                                            </div>   
                                        </div>
                                        <div className="butons-container">
                                            <div className="btn-cancel slider">
                                                <Button 
                                                    className="cancelBtn" 
                                                    variant="contained"
                                                    onClick={()=>{
                                                        setNewSlider(false)
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="btn-send">
                                                <Button 
                                                    className="loginBtn" 
                                                    variant="contained"
                                                    onClick={()=>{
                                                        createSlider()
                                                    }}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
            break;
    }
}
export default MenuSwitcher;