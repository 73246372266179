import React, {Component, useEffect}from "react";
import './Manager.css';
import Rating from '@mui/material/Rating';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from "react";
import { NavLink, useNavigate, Navigate } from 'react-router-dom';

import transactionsIcon from '../../assets/svg/transactions-icon.svg';
import aliancesIcon from '../../assets/svg/aliances-icon.svg';
import referedIcon from '../../assets/svg/refered-icon.svg';

import Global from '../Global';
import axios from 'axios';
import MenuSwitcher from "../menuSwitcher/MenuSwticher";
import {io} from 'socket.io-client';
import Navbar from "../navbar/Navbar";
import NavbarLogged from "../navbarLogged/NavbarLogged";
import { CircularProgress, Button } from "@mui/material";
import Swal from 'sweetalert2';
import tfLogoSvg from '../../assets/images/tf-logo.svg';
const socket=io(Global.ws);
const Manager = (props) => {
    const [user2, setUser2] = useState(null);
    const [css, setCss] = useState(18);
    const [goalMarkerTop, setGoalMarkerTop] = useState(10);
    const [goalMarkerOpacity, setGoalMarkerOpacity] = useState(0);
    const [storeMarkerTop, setStoreMarkerTop] = useState(10);
    const [storeMarkerOpacity, setStoreMarkerOpacity] = useState(0);
    const [switchOption, setSwitchOption] = useState(1);
    const [menu, setMenu] = useState(false);
    let url= Global.url;
    let ws= Global.ws;
    let history = useNavigate();

    useEffect(() => {
        veryfyUserSesion();
     
        return()=>{
            socket.off("update-notify")
        }
    },[]);

    const veryfyUserSesion = () =>{
        const sesionToken = localStorage.getItem('userSesion');
        if(sesionToken){
            axios.get(url+'auth-user', {
                headers:{
                    'x-acces-token':sesionToken
                }
            })
            .then(res => {
                if(res.data.status === 'Ok'){
                    debugger;
                    getUser(res.data.data.id);
                    props.getUser(res.data.data.id);
                }
            },
            error =>{
                history('/')
            })
        }else{
            history('/')
        }
    }
    const getUser = async (id) => {
        if(id){
            axios.get(url+'get-user/'+id)
            .then(res => {
                setUser2(res.data.data);
                if(res.data.data.manager)setSwitchOption(9);
                if(res.data.data.manager){

                    socket.on("update-notify",(data)=>{
                        switch (data.kind) {
                            case 'New service':
                                toast.info(data.kind+'-'+data.serviceType, {
                                    position: "bottom-right",
                                  
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                break;
                            case 'New message':
                                toast.info(data.kind+'-from-'+data.chat.user, {
                                    position: "bottom-right",
                                  
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            break;
                    
                            case 'New user':
                                toast.info(data.kind+'-'+data.name, {
                                    position: "bottom-right",
                                  
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            break;
                            case 'New request':
                                toast.info(data.kind+'-'+data.requestType, {
                                    position: "bottom-right",
                                  
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            break;
                            default:
                                break;
                        }
                       
                    });
                }
                if(res.data.data.superManager)setSwitchOption(9);
                if(res.data.data.superManager){

                    socket.on("update-notify",(data)=>{
                        switch (data.kind) {
                            case 'New service':
                                toast.info(data.kind+'-'+data.serviceType, {
                                    position: "bottom-right",
                                  
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                break;
                            case 'New message':
                                toast.info(data.kind+'-from-'+data.chat.user, {
                                    position: "bottom-right",
                                  
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            break;
                    
                            case 'New user':
                                toast.info(data.kind+'-'+data.name, {
                                    position: "bottom-right",
                                  
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            break;
                            case 'New request':
                                toast.info(data.kind+'-'+data.requestType, {
                                    position: "bottom-right",
                                  
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            break;
                            default:
                                break;
                        }
                       
                    });
                }
            })
        }else{
            setUser2(null);
        }
    }
    const menuController = (option, submenu) =>{
        if (switchOption!==option)setSwitchOption(option);
    }
    const logOut = () =>{
        //remove tokenSesion
        localStorage.removeItem('userSesion');
         Swal.fire({
            title: 'Closed session',
            text:'See you soon!',
            confirmButtonText: 'Ok',
        }).then(()=>{
            history('/');
            window.location.reload();
        })
    }

    if(user2===null){
        return(
            <CircularProgress></CircularProgress>
        )
    }
    return(
        <React.Fragment>
            <div className={user2.name?"container-navbar":"container-navbar disabled"}>
                <div className="navbar-container-logged" 
                    style={{opacity:1}} 
                >
                    <div className="navbarContent">
                    <div className='back-trade'>
                        {
                            !user2.manager && !user2.superManager&&
                          
                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    onClick={(e)=>{e.preventDefault();  history('/logged-booking');}}
                                >
                                    Book for free
                                </Button>
                            
                        }
                       </div>
                        <div className='login-header-container'>

                            <div className='logo-container'  onClick={()=>{debugger; history('/manager')}}>
                                <img className="trade-roofing-logo" src={tfLogoSvg}></img>
                            </div>
                            <div className='logo-text'>Service manager</div>
                        </div>
                        <div className='navbar-links-container'>
                            <div className='user-name-regular'
                                onClick={(e)=>{
                                    e.preventDefault();
                                    setMenu(!menu);
                                }}
                            >Welcome, <span className='user-name-bold'>{user2.name}</span></div>
                            {
                                menu?(
                                    <div className='nav-menu active'>
                                        <div className='nav-menu-item' onClick={(e)=>{e.preventDefault();
                                            logOut();
                                        }}>
                                            Log out
                                        </div>
                                    </div>
                                ):(
                                    <div className='nav-menu'>
                                        <div className='nav-menu-item' onClick={(e)=>{e.preventDefault();
                                            logOut();
                                        }}>
                                            Log out
                                        </div>
                                    </div>
                                )

                            
                            }
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="bottom-right"
                    hideProgressBar={true}
                    autoClose={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    limit={6}
                />
            </div>
        {
            user2.superManager&&
                <div className="profile-container">
                    <div className="top-container">
                        <div className="wrapper"></div>
                    </div>
                    <div className="bottom-container">
                        <div className="wrapper">
                            <div className="container-menu">
                                <div className={user2.name?"all-users":"all-users disabled"}>
                                    <div className={switchOption===9?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(9)}}>
                                        <div className="menu-label demi-bold normal-label ">Home manager</div>
                                    </div>
                                    <div className={switchOption===14?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(14)}}>
                                        <div className="menu-label demi-bold normal-label">Super Manager</div>
                                    </div>
                                    <div className={switchOption===10?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(10)}}>
                                        <div className="menu-label demi-bold normal-label">Customers</div>
                                    </div>
                                    <div className={switchOption===11?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(11)}}>
                                        <div className="menu-label demi-bold normal-label">Services</div>
                                    </div>
                                    <div className={switchOption===12?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(12)}}>
                                        <div className="menu-label demi-bold normal-label">History</div>
                                    </div>
                                    <div className={switchOption===13?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(13)}}>
                                        <div className="menu-label demi-bold normal-label">Requests</div>
                                    </div>
                                    <div className={switchOption===15?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(15)}}>
                                        <div className="menu-label demi-bold normal-label">Sliders</div>
                                    </div>
                                    <div className='back-trade'>
                                        <a
                                            href='https://traderoofing.com/'
                                        >
                                            {'< '}Go back to main page www.traderoofing.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="container-selected">
                                {
                                    <MenuSwitcher option={switchOption} user={user2} switchOption={switchOption} menuController={menuController}  verify={()=>veryfyUserSesion()}/>
                                }



                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                user2.manager&&
                <div className="profile-container">
                    <div className="top-container">
                        <div className="wrapper"></div>
                    </div>
                    <div className="bottom-container">
                        <div className="wrapper">
                            <div className="container-menu">
                                <div className={user2.name?"all-users":"all-users disabled"}>
                                    <div className={switchOption===9?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(9)}}>
                                        <div className="menu-label demi-bold normal-label ">Home manager</div>
                                    </div>
                                    <div className={switchOption===10?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(10)}}>
                                        <div className="menu-label demi-bold normal-label">Customers</div>
                                    </div>
                                    <div className={switchOption===11?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(11)}}>
                                        <div className="menu-label demi-bold normal-label">Services</div>
                                    </div>
                                    <div className={switchOption===12?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(12)}}>
                                        <div className="menu-label demi-bold normal-label">History</div>
                                    </div>
                                    <div className={switchOption===13?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(13)}}>
                                        <div className="menu-label demi-bold normal-label">Requests</div>
                                    </div>
                                    <div className={switchOption===15?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(15)}}>
                                        <div className="menu-label demi-bold normal-label">Sliders</div>
                                    </div>
                                    <div className='back-trade'>
                                        <a
                                            href='https://traderoofing.com/'
                                        >
                                            {'< '}Go back to main page www.traderoofing.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="container-selected">
                                {
                                    <MenuSwitcher option={switchOption} user={user2} switchOption={switchOption} menuController={menuController}  verify={()=>veryfyUserSesion()}/>
                                }



                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                !user2.manager&& !user2.superManager &&


                <div className="profile-container">
                    <div className="top-container">
                    <div className="wrapper"></div>
                    </div>
                    <div className="bottom-container">
                        <div className="wrapper">
                            <div className="container-menu">
                                <div className={user2.name?"all-users":"all-users disabled"}>
                                    <div className={switchOption===1?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(1)}}>
                                        <div className="menu-label demi-bold normal-label ">Home</div>
                                    </div>
                                    <div className={switchOption===2?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(2)}}>
                                        <div className="menu-label demi-bold normal-label">My Account{' &'} Properties</div>
                                    </div>
                                    <div className={switchOption===3?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(3)}}>
                                        <div className="menu-label demi-bold normal-label">Schedule</div>
                                    </div>
                                    <div className={switchOption===4?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(4)}}>
                                        <div className="menu-label demi-bold normal-label">My Services</div>
                                    </div>
                                    <div className={switchOption===5?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(5)}}>
                                        <div className="menu-label demi-bold normal-label">My Requests</div>
                                    </div>
                                    <div className={switchOption===6?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(6)}}>
                                        <div className="menu-label demi-bold normal-label">History</div>
                                    </div>
                                    <div className={switchOption===7?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(7)}}>
                                        <div className="menu-label demi-bold normal-label">How to?</div>
                                    </div>
                                    <div className={switchOption===8?("menu-item active"):("menu-item")} onClick={(e)=>{e.preventDefault(); menuController(8)}}>
                                        <div className="menu-label demi-bold normal-label">Contact us</div>
                                    </div>

                                    <div className='back-trade'>
                                        <a
                                            href='https://traderoofing.com/'
                                        >
                                            {'< '}Go back to main page www.traderoofing.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="container-selected">
                                {
                                    <MenuSwitcher option={switchOption} user={user2} switchOption={switchOption} menuController={menuController}  verify={()=>veryfyUserSesion()}/>
                                }



                            </div>
                        </div>
                    </div>
                </div>
            
            
            }
        </React.Fragment>
    )
}
export default Manager;