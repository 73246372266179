import './Activate.css';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Global from '../Global';
import Swal from 'sweetalert2';
import {useParams, useNavigate } from 'react-router-dom';
import bgMosaico from '../../assets/images/bg-mosaico.png';
const Activate=()=>{
    const params= useParams();
    const history=useNavigate();

    useEffect(()=>{
        activateAccount(params.id);
    },[])
    const url= Global.url;
    const activateAccount=async(id)=>{
        debugger
        await axios.put(url+'activate-user',{userId:id})
        .then(
            res=>{
                  Swal.fire({
                    title: 'Welcome',
                    icon:'success',
                    text:'Your account is active, log in to use our services',
                    confirmButtonText: 'Ok',
                }).then(()=>{
                    history('/login');
                })
            }
        )
        .catch(
            err=>{

            }
        )
    }
    return(
        <React.Fragment>
            <div className='login-container'>
                <div className='bg-container-mosaico'>
                    <img src={bgMosaico}></img>
                </div>
            </div>
        </React.Fragment>
    )
    
}
export default Activate;