import './FreeBook.css'
import { Autocomplete, Button, IconButton, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, {useEffect, useState} from "react";
import Navbar from "../navbar/Navbar";
import BackIcon from '../../assets/svg/backIcon'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";


import tfLogoSvg from '../../assets/images/tf-logo.svg';
import bgMosaico from '../../assets/images/bg-mosaico.png';
import ReCAPTCHA from 'react-google-recaptcha';

import axios from 'axios';
import Global from '../Global';
import Swal from 'sweetalert2';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';

const socket=io(Global.ws);

const FreeBook = ()=>{
    const history= useNavigate();
    const url= Global.url;
    const [name,setName] = useState('');
    const [nameError, setNameError]= useState(false);

    const [textDescription,setTextDescription] = useState('');

    const [mail,setMail] = useState('');
    const [mailError, setMailError]= useState(false);
    const [phone,setPhone] = useState('');
    const [phoneError, setPhoneError]= useState(false);

    const [step, setRegisterStep] = React.useState(0);
    const [captcha, setCaptcha] = useState(false);

    const [projectServiceError, setProjectServiceError] = useState(false);
    const [roofService, setRoofService] = React.useState('');
    const [roofServices, setRoofServices] = useState([]);

    useEffect(()=>{
        setRoofServices(Global.services);
        setRoofService(Global.services[0]);
    },[]);

    const saveForm =  async (e) =>{
        if(name===''){
            setNameError(true);
            debugger;
        }
        if(mail===''){
            setMailError(true);
        }
        if(phone===''){
            setPhoneError(true);
        }
        if(name==='' || mail==='' || phone===''){
            return
        }else{
            const formData={};
            formData.name=name;
            formData.mail=mail
            formData.phoneNumber=phone;
            formData.note=textDescription;
            formData.serviceType=roofService.label?roofService.label:roofService;
            await axios.post(url+'create-quote', formData)
                .then( res =>{
                    const response=res.data.data;
                    if(res){
                        
                        Swal.fire({
                            title: 'Stay updated!',
                            text:'We will contact you in less than 24 hours.',
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false,
                            showCloseButton: true}
                        ).then(()=>{
                            socket.emit("new-quote",(response));
                            history('/')
                        })
                    }else{
                        Swal.fire(
                            {
                                title: 'Ups',
                                text:'Something went wrong!',
                                confirmButtonText: 'Close',
                                allowOutsideClick: false,
                                showCloseButton: true
                            }
                        )
                       
                    }
                }, error =>{
                    Swal.fire(
                        {
                            title: 'Ups',
                            text:'Something went wrong!',
                            confirmButtonText: 'Close',
                            allowOutsideClick: false,
                            showCloseButton: true
                        }
                    )
                })
        }

         
    }


    const onChange=(value) =>{
        setCaptcha(value!==''?true:false);
    }
    return(
        <React.Fragment>
            
            <div className="container-navbar">
                <Navbar></Navbar>
            </div>
            <div className="free-booking-container">
                <div className='bg-container-mosaico'>
                    <img src={bgMosaico}></img>
                </div>
                <div className="booking-component">
                <div className="modal-register">
                {
                    step===0 &&
                    <div className="step step-1">
                        <div className="stepBtn">
                            <div className="back-header">
                            </div>
                        </div>

                        <div className="step-header"> Service form</div>
                        <div className="step-form">
                            <form className="form" onSubmit={saveForm}>
                                <div className="form-content">
                                    <div className="form-group">
                                        <TextField
                                            required
                                            id="outlined-basic"
                                            className='login-input'
                                            label="Full name" 
                                            variant="outlined"
                                            fullWidth
                                            value={name}
                                            error={nameError}
                                            helperText={nameError?('This field is required.'):('')}
                                            onChange={(e)=>{setName(e.target.value);if(nameError)setNameError(false)}}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            required
                                            id="outlined-basic"
                                            className='login-input'
                                            label="Email" 
                                            variant="outlined"
                                            value={mail}
                                            fullWidth
                                          
                                            error={mailError}
                                            helperText={mailError?('This field is required.'):('')}
                                            onChange={(e)=>{setMail(e.target.value);if(mailError)setMailError(false)}}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField 
                                            required
                                            id="outlined-basic"
                                            className='login-input'
                                            label="Phone number" 
                                            variant="outlined"
                                            fullWidth
                                            type='numeric'
                                            value={phone}
                                            
                                            error={phoneError}
                                            helperText={phoneError?('This field is required.'):('')}
                                            onChange={(e)=>{setPhone(e.target.value);if(phoneError)setPhoneError(false)}}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <Autocomplete
                                        className="autocomplete-full login-input" 
                                        options={roofServices}
                                        value={roofService}
                                    
                                        onChange={(event, newValue) => {
                                            console.log(newValue)
                                            setRoofService(newValue===null?'':newValue.label);
                                            if(projectServiceError)setProjectServiceError(false);
                                        }}
                                        renderInput={(params)=> <TextField 
                                            {...params} 
                                            label="Service" 
                                            className="login-input" 
                                            id="outlined-basic" 
                                            variant="outlined" 
                                            required 
                                            error={projectServiceError}
                                            helperText={projectServiceError?('This field is required.'):('')}
                                            >
                                            </TextField>
                                        }
                                    />
                                    
                                    
                                </div>
                                <div className='step-navigator'>
                                    <div className="step-marker">
                                        <div className="step-mark active"></div>
                                        <div className="step-mark"></div>
                                    </div>
                                    <Button 
                                        className="loginBtn" 
                                        variant="contained"
                                        onClick={()=>{
                                            if(name==='' || mail==='' || phone===''){
                                                debugger;
                                                return}
                                            setRegisterStep(step+1)

                                        }}
                                    >
                                        Next
                                    </Button>
                                </div>

                            </form>
                        </div>
                       
                    </div>
                }
                {
                    step===1 &&
                    <div className="step step-3">
                    <div className="stepBtn">
                        <div className="back-header">
                            <div 
                                className="back-btn" 
                                onClick={()=>{
                                    setRegisterStep(step-1);
                                }}>
                                <IconButton><BackIcon></BackIcon></IconButton>
                            </div>
                        </div>
                    </div>
                    <div className="step-header"> Service form</div>
                    <div className="step-form">
                        <form className="form" onSubmit={saveForm}>
                            <div className="form-content">
                                <div className="form-group area">
                                    <textarea className="text-area-tf" placeholder='Notes' maxLength={250} value={textDescription} onChange={(e)=>{e.preventDefault(); setTextDescription(e.target.value)}} />
                                </div>
                                <div className="form-group">
                                    <ReCAPTCHA
                                        sitekey="6LebDH0iAAAAALJeGD2aSx1YWFKL3Zh7Yo-gM8bG"
                                        onChange={onChange}
                                    />
                                </div>
                                
                               

                            </div>
                            <div className='step-navigator'>
                                <div className="step-marker">
                                    <div className="step-mark complete"></div>
                                    <div className="step-mark active"></div>
                                </div>
                                <Button 
                                    className="loginBtn" 
                                    variant="contained"
                                    disabled={!captcha}
                                    onClick={()=>{
                                        saveForm()
                                    }}
                                >
                                    Send
                                </Button>
                            </div>
                           
                        </form>
                    </div>  
                    </div>
                }
            </div>
                </div>

            </div>
        </React.Fragment>
    )
}
export default FreeBook;