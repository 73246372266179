import './Login.css';
import React, {Component} from 'react';
import axios from 'axios';
import Global from '../Global';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {NavLink, Navigate } from 'react-router-dom';


//images
import tfLogoSvg from '../../assets/images/tf-logo.svg';
import bgMosaico from '../../assets/images/bg-mosaico.png';

class Login extends Component{
    
    userMailRef = React.createRef();
    userPassRef = React.createRef();
    constructor(props){
        super(props);
        this.url = Global.url; 
        this.ws = Global.ws;
        this.socket = null;
        this.state ={
            user : {
                mail: '',
                pass: ''
            },
            status: null,
            redirect:false
        };
    }
    componentDidMount(){
        var elements = document.getElementsByTagName("INPUT");
        for (var i = 0; i < elements.length; i++) {
            elements[i].oninvalid = function(e) {
                e.target.setCustomValidity("");
                if (!e.target.validity.valid) {
                    e.target.setCustomValidity("Complete este campo.");
                }
            };
            elements[i].oninput = function(e) {
                e.target.setCustomValidity("");
            };
        }
        //this.veryfyUserSesion();
    }

    setSocket = (res) =>{
        this.socket.emit("set-unchecked-news",res.data.data);
        this.socket.on("get-unchecked-news", data => {
            this.setState({
                forms: data,
                status: 'success'
            })
        });
    }
    isLogged = () =>{

    }
    veryfyUserSesion = () =>{
        const sesionToken = localStorage.getItem('userSesion');
        if(sesionToken){
            axios.get(this.url+'auth-manager-user', {
                headers:{
                    'x-acces-token':sesionToken
                }
            })
            .then(res => {
                if(res.data.status === 'Ok'){
                    this.setState({
                        redirect:true
                    });
                }
            },
            error =>{
               console.log(error)
            })
        }
    }
    saveForm =  async (e) =>{
        e.preventDefault();
        var userData = {
            mail : this.userMailRef.current.value,
            pass : this.userPassRef.current.value
        }
        if(this.userMailRef.current.value===''){
            this.userMailRef.current.setCustomValidity("Complete este campo");
        }else if(this.userPassRef.current.value===''){
            this.userMailRef.current.setCustomValidity("Complete este campo");
        }
        this.setState({
           user : userData
        })
        await axios.post(this.url+'login', userData)
            .then( res =>{
                debugger;
                if(res){
                    if(res.data.status!=='Ok'){
                        Swal.fire({
                            title: 'Sorry',
                            text:'This account is not active, check your email to activate your account',
                            confirmButtonText: 'Ok',
                        }).then(()=>{
                           
                        })
                    }else{

                        Swal.fire({
                            title: 'Welcome',
                            text:'Remember to check your news!',
                            confirmButtonText: 'Ok',
                        }).then(()=>{
                            localStorage.setItem('userSesion', res.data.token);
                            this.setState({
                                redirect:true
                            });
                        })
                    }
                }else{
                    Swal.fire(
                        {
                            title: 'Error',
                            text:'Wrong email or password.',
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false,
                            showCloseButton: true
                        }
                    )
                    this.setState({
                        status:'failed'
                    })
                }
            }, error =>{
                 Swal.fire(
                    {
                        title: 'Error',
                        text:'Wrong email or password.',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        showCloseButton: true
                    }
                )
            })
        
    }
    render(){
       
        if (this.state.redirect) {
            return <Navigate to={'/manager'} />
        }
        return(
            <React.Fragment>
                <div className='login-container'>
                    <div className='bg-container-mosaico'>
                        <img src={bgMosaico}></img>
                    </div>
                    <div className="content-login">
                        <div className="containerTop">
                            <div className="form-content-container row">
                                <div className='login-header-container'>

                                    <div className='logo-container'>
                                        <img className="trade-roofing-logo" src={tfLogoSvg}></img>
                                    </div>
                                    <div className='logo-text'>Service manager</div>
                                </div>
                                <form className="form" 
                                    onSubmit={
                                        this.saveForm
                                        }>
                                    <div className="form-group">
                                        <TextField 
                                            required
                                            className='login-input'
                                            id="outlined-basic-email" 
                                            label="Email" 
                                            variant="outlined"
                                            fullWidth
                                            inputRef={this.userMailRef}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField 
                                            required
                                            id="outlined-basic-pass"
                                            className='login-input'
                                            label="Password" 
                                            variant="outlined"
                                            type="password"
                                            fullWidth
                                            inputRef={this.userPassRef}
                                        />
                                    </div>
                                    <div className="form-group column">
                                        <NavLink
                                            to='/recovery'
                                            className={isActive =>
                                                "nav-link" + (!isActive ? "unselected" : "")
                                            }
                                        >
                                            Forget password?
                                        </NavLink>
                                        <NavLink
                                            to='/register'
                                            className={isActive =>
                                                "nav-link" + (!isActive ? " unselected" : "")
                                            }
                                        >
                                            Register
                                        </NavLink>
                                    </div>
                                    <div className="form-group button-bottom">
                                        <Button 
                                            className="loginBtn" 
                                            variant="contained"
                                        >
                                            <input type="submit" value="Login">
                                                
                                            </input>
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Login;