
import React, {useState}from 'react';

const GoIcon = ({active}) => {

    return(
        <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.604973 11.9837L5.68398 7.00031L0.604974 2.01692L1.62078 0.0235638L8.73138 7.00031L1.62077 13.9771L0.604973 11.9837Z" fill="#BAA261"/>
        </svg>
        
        
    )
};

export default GoIcon;